/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import crypto from 'crypto-js';

import { auth } from '../../helpers';
import { login, setLoginError } from '../../services/Auth';

function Login({ location }) {
    const [username, setIdentification] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();

    const isAuthenticated = useSelector(auth.checkIfAuthenticated);
    const loginError = useSelector(auth.loginError);

    const handleLogin = (event) => {
        event.preventDefault();
        const formData = {
            // data: crypto.AES.encrypt(
            //     JSON.stringify({ username, password }),
            //     process.env.REACT_APP_CIPHER_SECRET
            // ).toString(),
            username,
            password 
        };

        dispatch(
            login(formData),
            () => {
                toastr.success('', `Login Successfully`);
                location.pathname('/dashboard');
            },
            (error) => {
                console.log('err during login', error);
            }
        );
    };

    if (isAuthenticated) {
        toastr.success('', `Login Successfully`);
        return <Redirect to="/dashboard" />;
    }

    if (loginError && loginError.isError) {
        toastr.error('', `${loginError.error.message}`);
        dispatch(setLoginError());
    }

    return (
        <div className="login-container">
            <h1 className="ui centered">Login</h1>
            <Form size="large">
                <Form.Field required>
                    <label>Username or Email Address </label>
                    <input
                        style={{ width: '300px' }}
                        onChange={(e) => setIdentification(e.target.value)}
                    />
                </Form.Field>

                <Form.Input
                    label="Password"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <Form.Field className="login-button">
                    <button
                        className="btn-normal btn-normal--medium btn-blue btn-transition bold"
                        primary="true"
                        type="submit"
                        onClick={handleLogin}
                    >
                        Login
                    </button>
                </Form.Field>
            </Form>
        </div>
    );
}

export default withRouter(Login);
