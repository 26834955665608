export function fetchingStats() {
    return { type: 'STATS_FETCHING' };
}

export function fetchStats(payload) {
    return { type: 'STATS_FETCHED', payload };
}

export function fetchingPriority() {
    return { type: 'PRIORITY_FETCHING' };
}

export function fetchPriority(payload) {
    return { type: 'PRIORITY_FETCHED', payload };
}

export function priorityLoading(payload) {
    return { type: 'PRIORITY_LOADING', payload };
}

export function sectorLoading(payload) {
    return { type: 'SECTOR_LOADING', payload };
}

export function ministryLoading(payload) {
    return { type: 'MINISTRY_LOADING', payload };
}

export function statsLoading(payload) {
    return { type: 'STATS_LOADING', payload };
}

export function yearOnYearLoading(payload) {
    return { type: 'YEAR_ON_YEAR_LOADING', payload };
}

export function statusLoading(payload) {
    return { type: 'STATUS_LOADING', payload };
}
