const rules = {
    npcAdmin: {
        static: [
            'users:list',
            'user:view',
            'action:edit-delete',
            'project-ministry:filter',
            'project:create',
            'user:create',
            'department:create-edit-delete',
            'departments:list',
            'department:view',
            'ministries:action',
            'ministries:list',
            'project-document-verification:create-edit-view',
            'project:ranking',
            'sector:list',
            'trash:restore:delete',
            'email:list:edit:delete',
            'upload:guideline',
        ],
        dynamic: {
            'identification:edit:delete': (canCurdable) => {
                return canCurdable;
            },
            'quarterly:action': (visible) => {
                return visible;
            },
        },
    },
    ministryAdmin: {
        static: [
            'users:list',
            'user:view',
            'project:create',
            'user:create',
            'department:create-edit-delete',
            'departments:list',
            'department:view',
            'trash:restore:delete',
        ],
        dynamic: {
            'action:edit-delete': (isActionable) => {
                return isActionable;
            },
            'identification:edit:delete': (canCurdable) => {
                return canCurdable;
            },
            'quarterly:action': (visible) => {
                return visible;
            },
        },
    },
    dataEntry: {
        static: ['project:create', 'trash:restore:delete'],
        dynamic: {
            'action:edit-delete': (isActionable) => {
                return isActionable;
            },
            'identification:edit:delete': (canCurdable) => {
                return canCurdable;
            },
            'quarterly:action': (visible) => {
                return visible;
            },
        },
    },
    dataApprover: {
        static: ['project:create', 'trash:restore:delete'],
        dynamic: {
            'action:edit-delete': (isActionable) => {
                return isActionable;
            },
            'identification:edit:delete': (canCurdable) => {
                return canCurdable;
            },
            'quarterly:action': (visible) => {
                return visible;
            },
        },
    },
    dataViewer: {
        static: ['project-ministry:filter'],
        dynamic: {
            'identification:edit:delete': (canCurdable) => {
                return canCurdable;
            },
        },
    },
};

export default rules;
