import Api from './api';
import ApiUrlConstant from '../helpers/constant/api-url';

class FeedbackApi {
    async sendFeedback(data) {
        try {
            const res = await Api.post(await ApiUrlConstant.FEEDBACK(), data);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async createProjectFeedback(params, data) {
        try {
            const res = await Api.post(await ApiUrlConstant.PROJECT_FEEDBACK_ACTION(params), data);
            return res.body;
        } catch (error) {
            return error;
        }
    }
}
export default new FeedbackApi();
