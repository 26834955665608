import Api from './api';
import ApiUrlConstant from '../helpers/constant/api-url';

class GuidelineApi {
    async uploadDocument(data, params) {
        try {
            const response = await Api.post(await ApiUrlConstant.UPLOAD_GUIDELINE(params), data);
            return response.body;
        } catch (err) {
            console.log(err);
        }
    }

    async getDocument(params) {
        try {
            const response = await Api.get(await ApiUrlConstant.GET_GUIDELINE(params));
            return response.body;
        } catch (err) {
            console.log(err);
            return err;
        }
    }
}

export default new GuidelineApi();
