import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Header, Select } from 'semantic-ui-react';
import { cloneDeep, pick } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { localization } from '../../../../localization';
import { fetched, validation } from '../../../../reducers/form/ModerationStatus/action';
import { handleSelectChange, handleSaveAndExit } from '../../../../actions';
import * as ProjectAction from '../../../../reducers/form/action';
import ProjectApi from '../../../../services/Gate/Project';

const ModerationStatus = ({ urlParams }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const selectedLanguage = useSelector((state) => state.localization.selectedLanguage);
  const lang = localization[selectedLanguage]['project_status'];

  const { moderationStatus } = useSelector((state) => state);
  const { projectInfo } = useSelector((state) => state.projectBasicInfo);

  useEffect(() => {
    if (projectInfo) {
      const data = projectInfo.moderation_status
        ? { moderation_status: projectInfo.moderation_status }
        : moderationStatus;
      dispatch(fetched({ ...data }));
    }
  }, [projectInfo]);

  const status =
    user.role === 'dataEntry'
      ? [
          { text: 'Save as draft', value: 'draft', key: 'draft' },
          { text: 'Send to verification', value: 'published', key: 'published' },
          { text: 'Submit', value: 'completed', key: 'completed' }
        ]
      : [
          { text: 'Save as draft', value: 'draft', key: 'draft' },
          { text: 'Send to verification', value: 'published', key: 'published' },
          { text: 'Submit', value: 'completed', key: 'completed' },
        ];

  const handleSaveAndContinue = (type) => {
    let data = cloneDeep(moderationStatus);
    delete data['step'];
    handleSaveAndExit(
      { moderation_status: moderationStatus.moderation_status },
      type,
      dispatch,
      14,
      urlParams
    );
  };

  const handleSelect = (name, value) => {
    if(user.role === 'npcAdmin' && projectInfo.hasPublished && projectInfo.moderation_status === 'completed'){
        toastr.error(
          '',
          `Assessment form was already approved for this project. Please re-do assessment to change its moderation status.`
        );
    } else {
      if (value === 'published' || value === 'completed') {
        const dataToValidate = cloneDeep(projectInfo);
        dataToValidate.stage = dataToValidate.sent_for_appraisal ? 'appraisal' : 'identification'
        const META_DATA = [
          'general_information',
          'project_specific',
          'progress',
          'project_readiness',
          'basis_of_project_selection',
          'project_prioritization_score',
          'project_implementation_modality',
          'project_financing_arrangement',
          'project_viability',
          'land_acquisition_status',
          'justification',
          'contact',
          'remarks',
          'stage'
        ];

        const metaObject = pick(dataToValidate, META_DATA);
        ProjectApi.validateProjectData({ projectId: projectInfo.id }, metaObject).then((result) => {
          if (result.data.errorForms.length > 0) {
            dispatch(validation({ ...moderationStatus, errorForms: result.data.errorForms }));
            toastr.error(
              '',
              'Some of the required fields are empty. Please fill up those fields to send for verification'
            );
          } else {
            dispatch(
              handleSelectChange(
                {
                  value,
                  name,
                  info: moderationStatus,
                },
                fetched
              )
            );
          }
        });
      } else {
        dispatch(
          handleSelectChange(
            {
              value,
              name,
              info: moderationStatus,
            },
            fetched
          )
        );
      }
    }

    return true;
  };

  return (
    <Form className="form-new">
      <div className="form-group-container">
        <Form.Group>
          <Form.Field
            label={lang.form_name}
            control={Select}
            width={8}
            name="moderation_status"
            options={status}
            required={true}
            value={moderationStatus.moderation_status}
            onChange={(event, { name, value }) => {
              handleSelect(name, value);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Header>{lang.note}:</Header>
          <ul className="notes-list">
            <li
              dangerouslySetInnerHTML={{
                __html: lang.draft_text,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: lang.needs_approval_text,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: lang.submitted_text,
              }}
            ></li>
          </ul>
        </Form.Group>
      </div>

      <div className="form-actions flex">
        <button
          type="submit"
          className="btn-rect btn-rect--small btn-blue"
          onClick={() => handleSaveAndContinue('continue')}
        >
          Save and continue
        </button>
        <button
          type="submit"
          className="btn-rect btn-rect--small btn-dark"
          onClick={() => handleSaveAndContinue('exit')}
        >
          Save and exit
        </button>
        <button
          type="button"
          className="btn-link"
          onClick={() => dispatch(ProjectAction.projectStep({ step: 13 }))}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default ModerationStatus;
