import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';

import { localization } from '../../../../localization';
import { ECONOMIC, SOCIAL, FINANCIAL } from '../../../../helpers/constant/ProjectViability';

import FileUpload from '../FileUpload/index';

import { handleInputChange, handleSaveAndExit } from '../../../../actions';
import { fetched, fetchedNullData } from '../../../../reducers/form/ProjectViability/action';
import * as ProjectAction from '../../../../reducers/form/action';
import LoaderNP from '../../../../components/Loader/Loader';

const ProjectViability = ({ urlParams }) => {
  const selectedLanguage = useSelector((state) => state.localization.selectedLanguage);
  const lang = localization[selectedLanguage].viability;
  const { projectViability } = useSelector((state) => state);
  const { projectInfo } = useSelector((state) => state.projectBasicInfo);
  const projectSaveToDbLoader = useSelector((state)=>state.project.projectSaveToDbLoader);
  const dispatch = useDispatch();

  const [projectError, setProjectError] = useState({
    economicIndicators_rateOfReturn_error: false,
    financialIndicators_IRREquity_error: false,
    financialIndicators_rateOfReturn_error: false,
    economicIndicators_BCRatio_error: false,
    financialIndicators_BCRatio_error: false,
    financialIndicators_payback_error: false,
    socialIndicators_costEffectiveness_error: false,
    socialIndicators_employmentGeneration_error: false,
    socialIndicators_BCRatio_error: false,
  });

  useEffect(() => {
    if (projectInfo.project_viability) {
      dispatch(
        fetched({
          ...projectInfo.project_viability,
        })
      );
    }
    if (!projectInfo.project_viability) {
      dispatch(fetchedNullData());
    }
  }, [projectInfo]);

  const handleSaveAndContinue = (type) => {
    let data = cloneDeep(projectViability);
    delete data['step'];
    let canSubmit = true;
    let requiredFields = [
      'rateOfReturn',
      'IRREquity',
      'BCRatio',
      'payback',
      'employmentGeneration',
      'costEffectiveness',
    ];
    let errors = {};
    for (let d in data) {
      for (let field of requiredFields) {
        if (data[d][field]) {
          let regexPercentage = /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/;
          let regexNumber = /^[+]?\d+(\.\d+)?$/;
          if (
            !regexPercentage.test(data[d][field]) &&
            (field === 'rateOfReturn' || field === 'IRREquity')
          ) {
            errors[`${d}_${field}_error`] = true;
            canSubmit = false;
          }
          if (
            !regexNumber.test(data[d][field]) &&
            (field !== 'rateOfReturn' || field !== 'IRREquity')
          ) {
            errors[`${d}_${field}_error`] = true;
            canSubmit = false;
          }
        }
      }
    }
    setProjectError(errors);
    if (canSubmit) {
      handleSaveAndExit({ project_viability: data }, type, dispatch, 10, urlParams);
    }
  };
  return projectSaveToDbLoader ? <LoaderNP text={true} /> : (
    <Form className="form-new">
      <div className="form-group-container">
        <p className="form-info">{lang.economic_indicators}</p>
        <Form.Group>
          <Form.Field
            width={8}
            className={
              projectError.economicIndicators_rateOfReturn_error ? 'error' : 'field-align-bottom'
            }
          >
            <label>{lang.economic_internal_rate}</label>
            {projectError.economicIndicators_rateOfReturn_error ? (
              <div class="ui pointing below prompt label" role="alert" aria-atomic="true">
                EIRR allow only a number!
              </div>
            ) : (
              ''
            )}
            <Input
              id="rateOfReturn"
              name="rateOfReturn"
              value={
                projectViability.economicIndicators &&
                projectViability.economicIndicators.rateOfReturn
              }
              onChange={(event) => {
                dispatch(
                  handleInputChange({ event, field: ECONOMIC, info: projectViability }, fetched)
                );
                setProjectError({ ...projectError, economicIndicators_rateOfReturn_error: false });
              }}
              label="%"
              labelPosition="right"
            />
          </Form.Field>
          <Form.Field
            control={Input}
            label={lang.npv}
            width={8}
            className="field-align-bottom"
            id="NPV"
            name="NPV"
            value={projectViability.economicIndicators && projectViability.economicIndicators.NPV}
            placeholder="eg: 6785 (6%)"
            onChange={(event) => {
              dispatch(
                handleInputChange({ event, field: ECONOMIC, info: projectViability }, fetched)
              );
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Field
            width={8}
            control={Input}
            label={lang.ratio}
            id="BCRatio"
            name="BCRatio"
            className={
              projectError.economicIndicators_BCRatio_error ? 'error' : 'field-align-bottom'
            }
            value={
              projectViability.economicIndicators && projectViability.economicIndicators.BCRatio
            }
            placeholder="eg: 45.78"
            onChange={(event) => {
              dispatch(
                handleInputChange({ event, field: ECONOMIC, info: projectViability }, fetched)
              );
              setProjectError({ ...projectError, economicIndicators_BCRatio_error: false });
            }}
          />
          <Form.Field
            control={Input}
            label={lang.other}
            width={8}
            className="field-align-bottom"
            id="other"
            name="other"
            type="text"
            value={projectViability.economicIndicators && projectViability.economicIndicators.other}
            onChange={(event) => {
              dispatch(
                handleInputChange({ event, field: ECONOMIC, info: projectViability }, fetched)
              );
            }}
          />
        </Form.Group>
      </div>

      <div className="form-group-container">
        <p className="form-info">{lang.financial_indicators}</p>
        <Form.Group>
          <Form.Field
            width={4}
            className={
              projectError.financialIndicators_rateOfReturn_error ? 'error' : 'field-align-bottom'
            }
          >
            <label>{lang.financial_internal_rate}</label>
            {projectError.financialIndicators_rateOfReturn_error ? (
              <div class="ui pointing below prompt label" role="alert" aria-atomic="true">
                FIRR allow only a number!
              </div>
            ) : (
              ''
            )}
            <Input
              label="%"
              labelPosition="right"
              id="rateOfReturn"
              name="rateOfReturn"
              value={
                projectViability.financialIndicators &&
                projectViability.financialIndicators.rateOfReturn
              }
              onChange={(event) => {
                dispatch(
                  handleInputChange({ event, field: FINANCIAL, info: projectViability }, fetched)
                );
                setProjectError({ ...projectError, financialIndicators_rateOfReturn_error: false });
              }}
            />
          </Form.Field>
          <Form.Field
            control={Input}
            label={lang.npv}
            width={4}
            className="field-align-bottom"
            id="NPV"
            name="NPV"
            value={projectViability.financialIndicators && projectViability.financialIndicators.NPV}
            placeholder="eg: 6785 (6%)"
            onChange={(event) => {
              dispatch(
                handleInputChange({ event, field: FINANCIAL, info: projectViability }, fetched)
              );
            }}
          />
          <Form.Field
            control={Input}
            label={lang.ratio}
            width={4}
            className={
              projectError.financialIndicators_BCRatio_error ? 'error' : 'field-align-bottom'
            }
            name="BCRatio"
            value={
              projectViability.financialIndicators && projectViability.financialIndicators.BCRatio
            }
            placeholder="eg: 45.78"
            onChange={(event) => {
              dispatch(
                handleInputChange({ event, field: FINANCIAL, info: projectViability }, fetched)
              );
              setProjectError({ ...projectError, financialIndicators_BCRatio_error: false });
            }}
          />
          <Form.Field
            width={4}
            className={
              projectError.financialIndicators_IRREquity_error ? 'error' : 'field-align-bottom'
            }
          >
            <label>{lang.irr}</label>
            {projectError.financialIndicators_IRREquity_error ? (
              <div class="ui pointing below prompt label" role="alert" aria-atomic="true">
                IRR allow only numbers!
              </div>
            ) : (
              ''
            )}
            <Input
              label="%"
              labelPosition="right"
              id="IRREquity"
              name="IRREquity"
              value={
                projectViability.financialIndicators &&
                projectViability.financialIndicators.IRREquity
              }
              onChange={(event) => {
                dispatch(
                  handleInputChange({ event, field: FINANCIAL, info: projectViability }, fetched)
                );
                setProjectError({ ...projectError, financialIndicators_IRREquity_error: false });
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field
            control={Input}
            label={lang.payback}
            width={4}
            className="field-align-bottom"
            id="payback"
            name="payback"
            value={
              projectViability.financialIndicators && projectViability.financialIndicators.payback
            }
            onChange={(event) => {
              dispatch(
                handleInputChange({ event, field: FINANCIAL, info: projectViability }, fetched)
              );
              setProjectError({ ...projectError, financialIndicators_payback_error: false });
            }}
            className={
              projectError.financialIndicators_payback_error ? 'error' : 'field-align-bottom'
            }
          />
          <Form.Field
            control={Input}
            label={lang.other}
            width={4}
            className="field-align-bottom"
            id="other"
            name="other"
            value={
              projectViability.financialIndicators && projectViability.financialIndicators.other
            }
            onChange={(event) => {
              dispatch(
                handleInputChange({ event, field: FINANCIAL, info: projectViability }, fetched)
              );
            }}
          />
        </Form.Group>
      </div>

      <div className="form-group-container">
        <p className="form-info">{lang.social_sector}</p>
        <Form.Group>
          <Form.Field
            width={4}
            control={Input}
            label={lang.cost_effectiveness}
            id="costEffectiveness"
            name="costEffectiveness"
            value={
              projectViability.socialIndicators &&
              projectViability.socialIndicators.costEffectiveness
            }
            onChange={(event) => {
              dispatch(
                handleInputChange({ event, field: SOCIAL, info: projectViability }, fetched)
              );
              setProjectError({ ...projectError, socialIndicators_costEffectiveness_error: false });
            }}
            className={
              projectError.socialIndicators_costEffectiveness_error ? 'error' : 'field-align-bottom'
            }
          />
          <Form.Field
            width={4}
            control={Input}
            label={lang.employment_generation}
            id="employmentGeneration"
            name="employmentGeneration"
            value={
              projectViability.socialIndicators &&
              projectViability.socialIndicators.employmentGeneration
            }
            onChange={(event) => {
              dispatch(
                handleInputChange({ event, field: SOCIAL, info: projectViability }, fetched)
              );
              setProjectError({
                ...projectError,
                socialIndicators_employmentGeneration_error: false,
              });
            }}
            className={
              projectError.socialIndicators_employmentGeneration_error
                ? 'error'
                : 'field-align-bottom'
            }
          />
          <Form.Field
            width={4}
            control={Input}
            label={lang.ratio}
            name="BCRatio"
            value={projectViability.socialIndicators && projectViability.socialIndicators.BCRatio}
            placeholder="eg: 45.78"
            onChange={(event) => {
              dispatch(
                handleInputChange({ event, field: SOCIAL, info: projectViability }, fetched)
              );
              setProjectError({ ...projectError, socialIndicators_BCRatio_error: false });
            }}
            className={projectError.socialIndicators_BCRatio_error ? 'error' : 'field-align-bottom'}
          />
          {urlParams.stage === 'identification' && projectInfo.project_status === 'new' && (
            <Form.Field
              width={4}
              control={Input}
              label={lang.other}
              id="other"
              name="other"
              value={projectViability.socialIndicators && projectViability.socialIndicators.other}
              onChange={(event) => {
                dispatch(
                  handleInputChange({ event, field: SOCIAL, info: projectViability }, fetched)
                );
              }}
            />
          )}
        </Form.Group>
      </div>
      <div className="form-group-container">
        <FileUpload
          formName="viability"
          label="upload_social_output"
          fileData={projectViability.socialIndicatorOutputFile}
          name="socialIndicatorOutputFile"
          form={projectViability}
          fetched={fetched}
          projectId={urlParams.projectId}
        />
      </div>

      <div className="form-actions flex">
        <button
          className="btn-rect btn-rect--small btn-blue"
          onClick={() => handleSaveAndContinue('continue')}
        >
          Save and continue
        </button>
        <button
          className="btn-rect btn-rect--small btn-dark"
          onClick={() => handleSaveAndContinue('exit')}
        >
          Save and exit
        </button>
        <button
          type="button"
          className="btn-link"
          onClick={() => dispatch(ProjectAction.projectStep({ step: 8 }))}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default ProjectViability;
