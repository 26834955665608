/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { auth } from '../../helpers';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = useSelector(auth.checkIfAuthenticated);

    return (
        <Route
            {...rest}
            render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to="/" />)}
        />
    );
};

export default PrivateRoute;
