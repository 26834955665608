import Api from './api';
import ApiUrlConstant from '../helpers/constant/api-url';

class EmailApi {
    async listAllEmails() {
        try {
            const res = await Api.get(await ApiUrlConstant.EMAIL());
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getEmail(params) {
        try {
            const res = await Api.get(await ApiUrlConstant.EMAIL_ACTION(params));
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async updateEmail(params, data) {
        try {
            const res = await Api.patch(await ApiUrlConstant.EMAIL_ACTION(params), data);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async createEmail(data) {
        try {
            const res = await Api.post(await ApiUrlConstant.EMAIL(), data);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async deleteEmail(params) {
        try {
            const res = await Api.destroy(await ApiUrlConstant.EMAIL_ACTION(params));
            return res.body;
        } catch (error) {
            return error;
        }
    }
}

export default new EmailApi();
