const initialState = {
    step: 1,
    projectInfo: {},
    loading: false,
    imageLoadingError: {},
    projectList: [],
    errorInfo: {},
    programList: [],
    departmentList: [],
    provinceFetching: true,
    provinceFetched: false,
    provinceError: null,
    provinces: [],
    mapProjectList: [],
    mapLoading: false,
    imageButtonDisabled: false,
    successInfo: null,
    stage: '',
    projectSaveToDbLoader: false,
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case 'GET_PROJECT_INFO':
            return {
                ...state,
                projectInfo: payload.data.project,
                programList: payload.data.programList,
                departmentList: payload.data.departmentList,
            };
        case 'PROJECT_UPDATE_SUCCESS':
            return {
                ...state,
                successInfo: payload,
            };
        case 'PROJECT_ERROR':
            return {
                ...state,
                errorInfo: payload,
            };
        case 'PROJECT_STEP':
            return {
                ...state,
                step: payload.step,
            };

        case 'PROJECT_LIST':
            return {
                ...state,
                projectList: payload.data.projects,
            };

        case 'LOADING':
            return {
                ...state,
                loading: !state.loading,
            };

        case 'IMAGE_LOADING':
            return {
                ...state,
                imageLoadingError: {
                    [payload.label]: { [payload.index]: payload.value },
                },
            };
        case 'DISABLE_IMAGE_BUTTON':
            return {
                ...state,
                imageButtonDisabled: payload.disabled,
            };
        case 'PROVINCE_FETCHING':
            return {
                ...state,
                provinceError: null,
                provinceFetching: true,
                provinceFetched: false,
            };

        case 'PROVINCE_FETCHED':
            return {
                ...state,
                provinceError: null,
                provinces: payload.data,
                provinceFetching: false,
                provinceFetched: true,
            };
        case 'PROVINCE_ERROR':
            return {
                ...state,
                provinceError: payload,
                provinceFetching: false,
                provinceFetched: true,
            };

        case 'MAP_LOADING':
            return {
                ...state,
                mapLoading: !state.mapLoading,
            };

        case 'MAP_PROJECT_LIST':
            return {
                ...state,
                mapProjectList: payload.data.projects,
            };

        case 'STAGE_UPDATE':
            return {
                ...state,
                stage: payload,
            };

        case 'PROJECT_SAVE_TO_DB_LOADER':
            return {
                ...state,
                projectSaveToDbLoader: payload,
            };

        default:
            return state;
    }
}
