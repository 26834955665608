import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Header, Form, Input, TextArea } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';

import FeedbackService from '../services/Feedback';

import { auth } from '../helpers';

import { localization } from '../localization';

const FeedbackForm = () => {
    const language = useSelector(auth.getSelectedLanguage);
    const lang = localization[language].feedback;

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleSendFeedback = () => {
        let sendFeedback = true;
        const regexEmail = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/;
        if (email.trim() === '') {
            setEmailError(true);
            sendFeedback = false;
        }
        if (message.trim() === '') {
            setMessageError(true);
            sendFeedback = false;
        }
        if (email.trim !== '') {
            if (!regexEmail.test(email.trim())) {
                setEmailError(true);
                sendFeedback = false;
            }
        }

        if (sendFeedback) {
            setIsDisabled(true);
            try {
                FeedbackService.sendFeedback({ email, message })
                    .then((response) => {
                        if (response.success) {
                            setMessage('');
                            setEmail('');
                            setIsDisabled(false);
                            toastr.success('', `${response.message}`);
                        } else {
                            setIsDisabled(true);
                            throw new Error(response.error.message);
                        }
                    })
                    .catch((err) => {
                        toastr.error('', `${err.message}`);
                    });
            } catch (e) {
                toastr.error('', `${e.message}`);
            }
        }
    };
    return (
        <Grid columns="equal">
            <Grid.Column className="form-container flex">
                <Header size="huge">{lang.feedback}</Header>
                <div className="inner-contents">
                    <p className="feedback-info">{lang.feedback_info}</p>
                    <Form>
                        <Form.Group grouped>
                            <Form.Field
                                control={Input}
                                required
                                label={lang.email_id}
                                type="email"
                                placeholder={lang.enter_email}
                                value={email}
                                name="email"
                                width={8}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setEmailError(false);
                                }}
                                error={
                                    emailError
                                        ? {
                                              content: 'Enter valid email',
                                              pointing: 'below',
                                          }
                                        : null
                                }
                            />
                            <Form.Field width={8} required>
                                <label>{lang.remarks}</label>
                                <TextArea
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                        setMessageError(false);
                                    }}
                                    value={message}
                                    placeholder={lang.enter_feedback}
                                    required
                                    rows={8}
                                    className={messageError ? 'error' : 'success'}
                                />
                            </Form.Field>
                            <Form.Field width={6}>
                                <button
                                    type="button"
                                    className="btn-transition btn-normal btn-normal--medium btn-normal--br4 btn-blue btn-shadow--blue"
                                    onClick={handleSendFeedback}
                                    disabled={isDisabled}
                                    primary
                                >
                                    Submit
                                </button>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </div>
            </Grid.Column>
        </Grid>
    );
};

export default FeedbackForm;
