import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useUrlSearchParams } from 'use-url-search-params';
import { Grid } from 'semantic-ui-react';
// import queryString from 'query-string';

import Form from './FormType';
import FormSidebar from './FormSidebar';

import Can from '../../components/Can/can';
import LoaderNP from '../../components/Loader/Loader';
import PageNotFound from '../PageNotFound';

import * as ProjectAction from '../../reducers/form/BasicInfo/action';
import ProjectApi from '../../services/Gate/Project';
import * as StepAction from '../../reducers/form/action';

const Gate = ({ history }) => {
    const dispatch = useDispatch();

    const [params] = useUrlSearchParams();

    const { role } = useSelector((state) => state.auth.user);
    const step = useSelector((state) => state.project.step);
    const stage = useSelector((state) => state.project.stage);
    const { projectInfo } = useSelector((state) => state.projectBasicInfo);

    const [isLoading, setLoading] = useState(true);

    // const [projectStatus, setProjectStatus] = useState(null);
    // const [projectId, setProjectId] = useState(null);

    useEffect(() => {
        dispatch(StepAction.projectStep({ step: 1 }));
    }, [params.projectId]);

    useEffect(() => {
        fetchProjectData(params);
    }, [step, stage]);

    // useEffect(() => {
    //   let qs = queryString.parse(location.search);

    //   if (qs.stage) {
    //     projectInfo.project_status === 'ongoing'
    //       ? setProjectStatus('ongoing')
    //       : setProjectStatus(qs.stage);
    //   }
    //   // if (qs.projectId) {
    //   //   setProjectId(qs.projectId);
    //   // }
    // });

    const fetchProjectData = (data) => {
        ProjectApi.getProjectData(data)
            .then((response) => {
                if (response.success) {
                    setLoading(false);
                    dispatch(ProjectAction.getProjectInfo(response));
                } else {
                    setLoading(false);
                    dispatch(ProjectAction.projectInfoError(response.error));
                    throw new Error(response.error.message);
                }
            })
            .catch((err) => {
                toastr.error('', `${err.message}`);
                dispatch(ProjectAction.projectInfoError(err));
            });
    };

    return isLoading ? (
        <LoaderNP />
    ) : (
        <Can
            role={role}
            perform="identification:edit:delete"
            data={
                projectInfo.project_status === 'closed' && role !== 'npcAdmin'
                    ? false
                    : projectInfo.canCurdable
            }
            yes={() => (
                <>
                    <Grid columns="equal">
                        <Grid.Column className="form-container">
                            <div className="header large proj-cont">
                                <div>{projectInfo.project_name_in_english}</div>
                                {params.projectId ? (
                                    <div className="view-project-sec">
                                        <button
                                            className="btn-transition btn-normal btn-normal--medium btn-normal--br4 btn-blue btn-shadow--blue view-proj"
                                            onClick={() =>
                                                history.push(`/project/${params.projectId}`)
                                            }
                                            style={{ marginLeft: '20px' }}
                                            type="button"
                                        >
                                            View Project
                                        </button>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="project-detail">
                                <div className="project-detail-container">
                                    <div className="flex justify-between align-center project-detail-info">
                                        <div className="info-status project-metadata bold">
                                            {/* {projectStatus ? (
                        <span className={`project-status dark`}>{projectStatus}</span>
                      ) : (
                        ''
                      )} */}
                                            {projectInfo.project_status && (
                                                <span className="project-status dark">
                                                    {projectInfo.project_status === 'ongoing'
                                                        ? 'ongoing'
                                                        : params.stage}
                                                </span>
                                            )}
                                        </div>
                                        <div className="flex align-center">
                                            <div className="info-status project-metadata bold">
                                                <span
                                                    className={`project-status
                        ${
                            projectInfo &&
                            projectInfo.moderationStatus &&
                            projectInfo.moderationStatus.toLowerCase()
                        }`}
                                                >
                                                    {projectInfo.moderationStatus}
                                                </span>
                                            </div>

                                            <div className="info-status project-metadata bold">
                                                {projectInfo.hasPublished && (
                                                    <div className="icon-done">
                                                        <i className="material-icons">done</i>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Form */}
                                    <Form />
                                    {/* Form end */}
                                </div>

                                {/* Sidebar */}
                                <FormSidebar />
                                {/* Sidebar end */}
                            </div>
                        </Grid.Column>
                    </Grid>
                </>
            )}
            no={() => (
                <PageNotFound
                    message={
                        projectInfo.project_status === 'closed'
                            ? `The project have been closed.Please contact to NPC.`
                            : `The project have been approved for appraisal.You can't access this interface now.`
                    }
                />
            )}
        />
    );
};

export default Gate;
