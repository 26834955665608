import React, { useLayoutEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

const PieChart = ({ data, innerRadius, colors }) => {
    data = data.filter((d) => d.name);
    data.sort((a, b) => b.count - a.count);
    const pieChartDiv = useRef(null);
    // const colors = [
    // 	'#9399ff',
    // 	'#a9fffd',
    // 	'#f688bb',
    // 	'#cfd186',
    // 	'#8ac6d1',
    // 	'#fd5e53'
    // ];

    useLayoutEffect(() => {
        am4core.useTheme(am4themesAnimated);

        let chart = am4core.create(pieChartDiv.current, am4charts.PieChart);
        // chart.exporting.menu = new am4core.ExportMenu();

        const pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = 'count';
        pieSeries.dataFields.category = 'name';

        chart.innerRadius = innerRadius ? am4core.percent(20) : 0;
        chart.radius = 25;

        // Put a thick white border around each Slice
        // pieSeries.slices.template.stroke = am4core.color('#fff');
        // pieSeries.slices.template.strokeWidth = 1;
        // pieSeries.slices.template.strokeOpacity = 1;
        // change the cursor on hover to make it apparent the object can be interacted with
        pieSeries.slices.template.cursorOverStyle = [
            {
                property: 'cursor',
                value: 'pointer',
            },
        ];

        // pieSeries.alignLabels = false;
        // pieSeries.labels.template.bent = true;
        // pieSeries.labels.template.radius = am4core.percent(40);
        // pieSeries.alignLabels = false;
        pieSeries.labels.template.fontSize = 10;
        pieSeries.labels.template.radius = 1;
        pieSeries.labels.template.disabled = true;
        // pieSeries.labels.template.text =
        // 	"{name}: {value.percent.formatNumber('#.00')}%({value})";
        pieSeries.slices.template.tooltipText = `{name}: {value.percent.formatNumber('#.00')}% ({value})`;

        pieSeries.slices.template.states.getKey('hover').properties.scale = 1;
        pieSeries.slices.template.states.getKey('active').properties.shiftRadius = 0;
        // pieSeries.labels.template.disabled = true;
        // pieSeries.ticks.template.disabled = true;

        // Create a base filter effect (as if it's not there) for the hover to return to
        // let shadow = pieSeries.slices.template.filters.push(
        // 	new am4core.DropShadowFilter()
        // );
        // shadow.opacity = 0;

        // Create hover state
        // const hoverState = pieSeries.slices.template.states.getKey('hover'); // normally we have to create the hover state, in this case it already exists

        // Slightly shift the shadow and make it more prominent on hover
        // let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
        // hoverShadow.opacity = 0.7;
        // hoverShadow.blur = 5;

        pieSeries.colors.list = colors.map((color) => {
            return am4core.color(color);
        });

        chart.data = data;

        return () => {
            chart.dispose();

            chart = null;
        };
    }, [data, innerRadius]);
    return (
        <>
            <div ref={pieChartDiv} style={{ width: '70px', height: '60px' }} />
        </>
    );
};

export default PieChart;
