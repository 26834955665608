class ApiUrlConstant {
    async PROJECT(val) {
        if (val) {
            return `gates${val}`;
        }
        return `gates`;
    }

    async PROJECT_GET(params) {
        return `gates/${params.projectId}?stage=${params.stage}`;
    }

    async PROJECT_UPDATE(params) {
        return `gates/${params.projectId}?stage=${params.stage}`;
    }

    async SECTOR_GET() {
        return `sector`;
    }

    async LOCATION_GET() {
        return `locations`;
    }

    async UPLOAD_PROJECT_FILES(params) {
        return `gate/${params.projectId}/uploads`;
    }

    async VALIDATE_PROJECT_DATA(params) {
        return `gate/${params.projectId}/validate`;
    }

    async FETCH_PROJECT_BY_MINISTRY(params) {
        return `ministries/${params.ministryId}/gates`;
    }

    async ASSESSMENT() {
        return `assessments`;
    }

    async VIEW_GATE_PROJECT(params) {
        return `gates/view/${params.projectId}`;
    }

    async PROJECT_ACTION(params) {
        return `gates/${params.id}`;
    }

    async LIST_GATE_ASSESSMENTS(params, stage) {
        return `gates/${params.id}/assessments?stage=${stage}`;
    }

    async DOWNLOAD_GATE_FILES(params) {
        return `download/gates/files/${params}`;
    }

    async TRASH(val) {
        if (val) {
            return `trash/gates/${val.id}`;
        }
        return `trash/gates`;
    }

    async BULK_TRASH() {
        return `bulk/trash/gates`;
    }

    async EXPORT_PROJECT(params) {
        if (params) {
            return `gates/export/projects${params}`;
        }
        return `gates/export/projects`;
    }

    async DASHBOARD_STATS(params) {
        return `gates/dashboard/stats${params}`;
    }

    async DASHBOARD_SECTOR(params) {
        return `gates/dashboard/project/sector${params}`;
    }

    async DASHBOARD_PRIORITY(params) {
        return `gates/dashboard/project/priority${params}`;
    }

    async DASHBOARD_MINISTRY(params) {
        return `gates/dashboard/project/ministry${params}`;
    }

    async DASHBOARD_YEAR_ON_YEAR(params) {
        return `gates/dashboard/project/yearonyear${params}`;
    }

    async DASHBOARD_STATUS(params) {
        return `gates/dashboard/project/status${params}`;
    }

    async PROJECT_MAP(params) {
        if (params) {
            return `gates/projects/locations/map${params}`;
        }
        return `gates/projects/locations/map`;
    }

    async PARENT_PROJECT() {
        return `gates/project/parent`;
    }

    async PARENT_PROJECT_UPDATE(params) {
        return `gates/project/parent/${params.id}`;
    }

    async EXPORT_PARENT_PROJECT(params) {
        if (params) {
            return `gates/export/parent${params}`;
        }
        return `gates/export/parent`;
    }

    async GATE_MINISTRY_LIST(val) {
        if (val) {
            return `gates/fetch/ministries${val}`;
        }
        return `gates/fetch/ministries`;
    }

    async GATE_SECTOR_LIST() {
        return `gates/fetch/sectors`;
    }

    async PROJECT_RANKING(val) {
        return `gates/dashboard/project/matrix${val}`;
    }

    async QUARTERLY_ASSESSMENT() {
        return `quarterly`;
    }

    async LIST_QUARTERLY_ASSESSMENT(params, stage, fiscalYear) {
        if (fiscalYear) {
            return `gates/${params.id}/quarterly?stage=${stage}&fiscalYear=${fiscalYear}`;
        }

        return `gates/${params.id}/quarterly?stage=${stage}`;
    }

    async PROJECT_CREATOR_UPDATE(params) {
        return `creators/gates/${params.projectId}`;
    }

    async QUARTERLY_ASSESSMENT_ACTION(params) {
        if (params.projectId) {
            return `gates/${params.projectId}/quarterly/${params.assessmentId}`;
        }
        return `quarterly/${params.assessmentId}`;
    }

    async PROJECT_STATUS_UPDATE(params) {
        return `status/gates/${params.projectId}`;
    }

    async CLOSED_PROJECT(val) {
        if (val) {
            return `gates/project/closed${val}`;
        }
        return `gates/project/closed`;
    }

    async GATE_FILE_FROM_CLOUD(params) {
        return `gcs/gates/download/${params.projectId}?fileName=${params.fileName}`;
    }
}
export default new ApiUrlConstant();
