import React, { useLayoutEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

const PieChartLegend = forwardRef(({ data, colors, title }, ref) => {
    // data = data.filter(d => d.name);
    // data.sort((a, b) => b.count - a.count);

    const pieChartDiv = useRef(null);
    let chartData;

    useImperativeHandle(ref, () => ({
        exportPdf() {
            chartData.exporting.export('jpg');
        },
    }));

    useLayoutEffect(() => {
        am4core.useTheme(am4themesAnimated);

        let chart = am4core.create(pieChartDiv.current, am4charts.PieChart);
        chartData = chart;
        const pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = 'count';
        pieSeries.dataFields.category = 'name';

        chart.innerRadius = am4core.percent(30);

        // Put a thick white border around each Slice
        pieSeries.slices.template.stroke = am4core.color('#fff');
        pieSeries.slices.template.strokeWidth = 1;
        pieSeries.slices.template.strokeOpacity = 1;
        // change the cursor on hover to make it apparent the object can be interacted with
        pieSeries.slices.template.cursorOverStyle = [
            {
                property: 'cursor',
                value: 'pointer',
            },
        ];

        // pieSeries.alignLabels = false;
        pieSeries.labels.template.bent = true;
        pieSeries.labels.template.radius = 3;
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;

        pieSeries.slices.template.tooltipText = `{name}: {value.percent.formatNumber('#.00')}% ({value})`;

        // Create a base filter effect (as if it's not there) for the hover to return to
        const shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter());
        shadow.opacity = 0;

        // Create hover state
        const hoverState = pieSeries.slices.template.states.getKey('hover'); // normally we have to create the hover state, in this case it already exists

        // Slightly shift the shadow and make it more prominent on hover
        const hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
        hoverShadow.opacity = 0.7;
        hoverShadow.blur = 5;

        // Add a legend
        chart.legend = new am4charts.Legend();
        // chart.legend.position = 'right';
        // chart.legend.scrollable = true;
        // chart.legend.maxHeight = 200;
        chart.legend.labels.template.truncate = false;
        chart.legend.labels.template.fontSize = 14;
        chart.legend.valueLabels.template.fontSize = 14;
        chart.legend.valueLabels.template.text = `{value.percent.formatNumber('#.00')}% ({value})`;

        pieSeries.colors.list = colors.map((color) => {
            return am4core.color(color);
        });

        chart.exporting.filePrefix = title;
        chart.data = data;
        return () => {
            chart.dispose();

            chart = null;
        };
    }, [data]);
    return (
        <>
            <div ref={pieChartDiv} className="pie-chart-legend-wrapper" />
        </>
    );
});

export default PieChartLegend;
