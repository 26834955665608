import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Input } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';

import { localization } from '../../../../localization';
import { auth } from '../../../../helpers';
import fiscalYear from '../data/fiscalYear';
import FileUpload from '../FileUpload';
import CurrencyField from '../CurrencyField';
import {
  handleInputChange,
  handleSelectChange,
  addRepeaterField,
  removeRepeaterField,
  handleSaveAndExit,
  repeaterFieldOptimise,
} from '../../../../actions';
import { fetched, fetchedNullData } from '../../../../reducers/form/Progress/action';
import * as ProjectAction from '../../../../reducers/form/action';
import currentYear from '../../../../helpers/constant/CurrentYearGate';
import LoaderNP from '../../../../components/Loader/Loader';

const Progress = ({ urlParams }) => {
  const language = useSelector(auth.getSelectedLanguage);

  const lang = localization[language].progress;
  const { progress } = useSelector((state) => state);
  const dispatch = useDispatch();

  const { projectInfo } = useSelector((state) => state.projectBasicInfo);
  const projectSaveToDbLoader = useSelector((state)=>state.project.projectSaveToDbLoader);

  useEffect(() => {
    if (projectInfo.progress) {
      dispatch(
        fetched({
          ...projectInfo.progress,
        })
      );
    }
    if (!projectInfo.progress) {
      dispatch(fetchedNullData());
    }
  }, [projectInfo]);

  const [projectError, setProjectError] = useState({
    financialProgress_error: false,
    fiscalYear_error: false,
    comingEstimatedAnnualBudget_error: false,
    expenditureCurrentFYear_error: {},
    physicalProgress_error: false,
    financialProgressAmt_error: false,
  });

  const handleSaveAndContinue = (type) => {
    let data = cloneDeep(progress);
    delete data['step'];
    const requiredFields = ['financialProgress', 'fiscalYear', 'comingEstimatedAnnualBudget'];
    const regexCheckNumberFields = [
      'allocatedBudget',
      'expenditureBudget',
      'allocatedBudgetExchangeRate',
      'expenditureBudgetExchangeRate',
      'currentAllocatedBudget',
      'currentExpenditureBudget',
      'currentAllocatedBudgetExchangeRate',
      'currentExpenditureBudgetExchangeRate',
      'comingEstimatedAnnualBudget',
      'comingEstimatedAnnualBudgetExchangeRate',
      'projectedBudgetRequirement',
      'projectedBudgetRequirementExchangeRate',
      'physicalProgress',
      'financialProgressAmt',
      'financialProgress',
    ];
    const regexCheckWordFields = [
      'allocatedBudgetWord',
      'expenditureBudgetWord',
      'currentAllocatedBudgetWord',
      'currentExpenditureBudgetWord',
      'comingEstimatedAnnualBudgetWord',
      'projectedBudgetRequirementWord',
    ];
    const errors = {};
    let canSubmit = true;

    for (let d in data) {
      if (
        requiredFields.includes(d) &&
        (data[d] === '' ||
          data[d].length === 0 ||
          (typeof data[d] === 'object' && Object.keys(data[d]).length >= 1))
      ) {
        if (typeof data[d] === 'object') {
          for (let obj in data[d]) {
            if (
              requiredFields.includes(obj) &&
              (data[d][obj] === '' || data[d][obj].length === 0)
            ) {
              errors[`${obj}_error`] = true;
              canSubmit = false;
            }
          }
        } else {
          if (urlParams.stage === 'appraisal') {
            errors[`${d}_error`] = true;
            canSubmit = false;
          }
        }
      }
      if (d === 'expenditureComingFYear' || d === 'expenditureCurrentFYear') {
        errors[`${d}_error`] = {};
        for (let expCFYear in data[d]) {
          let regexNumber = /^[+]?\d+(\.\d+)?$/;
          let regexWord = /^[^[0-9!@*,_\.~`$#%\^\(\)-+='""{}><\?\\\/||\+]*$/;

          if (
            data[d][expCFYear] !== '' &&
            regexCheckNumberFields.includes(expCFYear) &&
            !regexNumber.test(data[d][expCFYear])
          ) {
            errors[`${d}_error`][`${expCFYear}`] = true;
            // errors[`${expCFYear}_error`] = true;
            canSubmit = false;
          }
          if (
            data[d][expCFYear] !== '' &&
            regexCheckWordFields.includes(expCFYear) &&
            !regexWord.test(data[d][expCFYear])
          ) {
            errors[`${d}_error`][`${expCFYear}`] = true;
            // errors[`${expCFYear}_error`] = true;
            canSubmit = false;
          }
          if (
            requiredFields.includes(expCFYear) &&
            data[d][expCFYear] &&
            (data[d][expCFYear] === '' || data[d][expCFYear].length === 0)
          ) {
            errors[`${d}_error`][`${expCFYear}`] = true;
            canSubmit = false;
          }
        }
      }
      if (d === 'projectedBudget' || d === 'expenditureTillDate') {
        errors[`${d}_error`] = {};
        for (let i = 0; i < data[d].length; i++) {
          for (let arrayFields in data[d][i]) {
            if (
              regexCheckNumberFields.includes(arrayFields) &&
              (data[d][i][arrayFields] !== '' || data[d][i][arrayFields].length !== 0)
            ) {
              let regexNumber = /^[+]?\d+(\.\d+)?$/;
              if (!regexNumber.test(data[d][i][arrayFields])) {
                errors[`${d}_error`][`${arrayFields}_${i}_error`] = true;
                // errors[`${arrayFields}_${i}_error`] = true;
                canSubmit = false;
              }
            }
            if (
              regexCheckWordFields.includes(arrayFields) &&
              (data[d][i][arrayFields] !== '' || data[d][i][arrayFields].length !== 0)
            ) {
              let regexWord = /^[^[0-9!@*,_\.~`$#%\^\(\)-+='""{}><\?\\\/||\+]*$/;
              if (!regexWord.test(data[d][i][arrayFields])) {
                errors[`${d}_error`][`${arrayFields}_${i}_error`] = true;
                // errors[`${arrayFields}_${i}_error`] = true;
                canSubmit = false;
              }
            }
          }
        }
      }
      if (regexCheckNumberFields.includes(d) && (data[d] !== '' || data[d].length !== 0)) {
        let regexPercentage = /(^100([.]0{1,2})?)$|(^\d{1,2}([.]\d{1,2})?)$/;
        let regexNumber = /^[+]?\d+(\.\d+)?$/;
        if (!regexPercentage.test(data[d]) && d !== 'financialProgressAmt') {
          errors[`${d}_error`] = true;
          canSubmit = false;
        }
        if (!regexNumber.test(data[d]) && d === 'financialProgressAmt') {
          errors[`${d}_error`] = true;
          canSubmit = false;
        }
      }
    }
    setProjectError(errors);
    if (canSubmit) {
      data.stage = urlParams.stage;
      let repeaterFields = {
        expenditureTillDate: [
          {
            fiscalYear: currentYear(),
            allocatedBudget: '',
            expenditureBudget: '',
            allocatedBudgetWord: '',
            expenditureBudgetWord: '',
            allocatedBudgetCurrency: 'npr',
            expenditureBudgetCurrency: 'npr',
            allocatedBudgetExchangeRate: '',
            expenditureBudgetExchangeRate: '',
          },
        ],
        projectedBudget: [
          {
            budgetStatusFiscalYear: currentYear(),
            fiscalYear: currentYear(),
            projectedBudgetRequirement: '',
            projectedBudgetRequirementWord: '',
            projectedBudgetRequirementCurrency: 'npr',
            projectedBudgetRequirementExchangeRate: '',
          },
        ],
      };
      const keys = Object.keys(repeaterFields);
      keys.map((item) => {
        let newProgress = repeaterFieldOptimise(data, item, repeaterFields[item]);
        data[item] = [...newProgress];
      });
      handleSaveAndExit({ progress: data }, type, dispatch, 4, urlParams);
    }
  };

  return projectSaveToDbLoader ? <LoaderNP text={true} /> : (
    <Form className="form-new">
      {urlParams.stage !== 'identification' && (
        <>
          {/* Financial Progress section */}
          <div className="form-group-container">
            <Form.Group>
              <Form.Field
                required
                control={Input}
                label={lang.financial_progress}
                placeholder="Financial progress till date in %"
                width={8}
                className={projectError.financialProgress_error ? 'error' : 'field-align-bottom'} 
                name="financialProgress"
                value={progress.financialProgress}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      {
                        event,
                        info: progress,
                      },
                      fetched
                    )
                  );
                  setProjectError({ ...projectError, financialProgress_error: false });
                }}
              />
              <Form.Field
                control={Input}
                label={lang.financial_progress_in_amount}
                placeholder="Financial progress in amount"
                width={8}
                className={projectError.financialProgressAmt_error ? 'error' : 'field-align-bottom'}
                name="financialProgressAmt"
                value={progress.financialProgressAmt}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      {
                        event,
                        info: progress,
                      },
                      fetched
                    )
                  );
                  setProjectError({ ...projectError, financialProgressAmt_error: false });
                }}
              />
            </Form.Group>
            <FileUpload
              formName="progress"
              label="upload_document_financial"
              fileData={progress.financialProgressFile}
              name="financialProgressFile"
              form={progress}
              fetched={fetched}
              projectId={urlParams.projectId}
            />
          </div>
          {/* Expenditure of last 2 fiscal year section */}
          <div className="form-group-container">
            <p className="form-info">{lang.expenditure_of_last_2_FY}</p>
            {progress.expenditureTillDate.map((value, index) => {
              return (
                <div className={index === 0 ? 'field-repeat--first' : 'field-repeat'} key={index}>
                  <Form.Group  className="flex-wrap fourth-child">
                    <Form.Field
                      control={Select}
                      options={fiscalYear}
                      placeholder={lang.fiscal_year}
                      width={4}
                      name="fiscalYear"
                      label={lang.fiscal_year}
                      onChange={(event, { value, name }) => {
                        dispatch(
                          handleSelectChange(
                            {
                              value,
                              name,
                              info: progress,
                              field: 'expenditureTillDate',
                              index: index,
                            },
                            fetched
                          )
                        );
                      }}
                      value={value.fiscalYear || ''}
                    />
                    <CurrencyField
                      costLabel={lang.allocated_budget}
                      costName="allocatedBudget"
                      exchangeRateLabel="Exchange rate"
                      exchangeRateName="allocatedBudgetExchangeRate"
                      costInWordsLabel={lang.allocated_budget_word}
                      costInWordsName="allocatedBudgetWord"
                      formInfo={progress}
                      dispatch={dispatch}
                      fetched={fetched}
                      field="expenditureTillDate"
                      index={index}
                      error={projectError.expenditureTillDate_error}
                    />
                  </Form.Group>
                  <Form.Group>
                    <CurrencyField
                      costLabel={lang.total_expenditure}
                      costName="expenditureBudget"
                      exchangeRateLabel="Exchange rate"
                      exchangeRateName="expenditureBudgetExchangeRate"
                      costInWordsLabel={lang.total_expenditure_word}
                      costInWordsName="expenditureBudgetWord"
                      formInfo={progress}
                      dispatch={dispatch}
                      fetched={fetched}
                      field="expenditureTillDate"
                      index={index}
                      error={projectError.expenditureTillDate_error}
                    />
                  </Form.Group>
                  {index > 0 && (
                    <div
                      className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                      onClick={(event) => {
                        dispatch(
                          removeRepeaterField(
                            {
                              event,
                              info: progress,
                              field: 'expenditureTillDate',
                              index: index,
                            },
                            fetched
                          )
                        );
                      }}
                    >
                      <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                      Delete
                    </div>
                  )}
                </div>
              );
            })}
            <Form.Group className="field-repeat-btn">
              <Form.Field>
                <button
                  className="btn-text"
                  onClick={(event) => {
                    dispatch(
                      addRepeaterField(
                        {
                          event,
                          info: progress,
                          field: 'expenditureTillDate',
                        },
                        fetched
                      )
                    );
                  }}
                >
                  + {lang.add_more}
                </button>
              </Form.Field>
            </Form.Group>
            <FileUpload
              formName="progress"
              label="upload_document_2FY"
              fileData={progress.expenditureTillDateFile}
              name="expenditureTillDateFile"
              form={progress}
              fetched={fetched}
              projectId={urlParams.projectId}
            />
          </div>
        </>
      )}
      {/*  Expenditure of current fiscal year section */}
      <div className="form-group-container">
        <p className="form-info">{lang.expenditure_of_the_current_FY}</p>
        <Form.Group className="flex-wrap fourth-child">
          <Form.Field
            control={Select}
            options={fiscalYear}
            placeholder={lang.fiscal_year}
            width={4}
            name="fiscalYear"
            label={lang.fiscal_year}
            onChange={(event, { value, name }) => {
              dispatch(
                handleSelectChange(
                  {
                    value,
                    name,
                    info: progress,
                    field: 'expenditureCurrentFYear',
                  },
                  fetched
                )
              );
            }}
            search
            value={progress.expenditureCurrentFYear && progress.expenditureCurrentFYear.fiscalYear}
          />
          <CurrencyField
            costLabel={lang.allocated_budget}
            costName="currentAllocatedBudget"
            exchangeRateLabel="Exchange rate"
            exchangeRateName="currentAllocatedBudgetExchangeRate"
            costInWordsLabel={lang.allocated_budget_word}
            costInWordsName="currentAllocatedBudgetWord"
            formInfo={progress}
            dispatch={dispatch}
            fetched={fetched}
            field="expenditureCurrentFYear"
            error={projectError.expenditureCurrentFYear_error}
          />
        </Form.Group>
        <Form.Group>
          <CurrencyField
            costLabel={lang.total_expenditure}
            costName="currentExpenditureBudget"
            exchangeRateLabel="Exchange rate"
            exchangeRateName="currentExpenditureBudgetExchangeRate"
            costInWordsLabel={lang.total_expenditure_word}
            costInWordsName="currentExpenditureBudgetWord"
            formInfo={progress}
            dispatch={dispatch}
            fetched={fetched}
            field="expenditureCurrentFYear"
            error={projectError.expenditureCurrentFYear_error}
          />
        </Form.Group>
        <FileUpload
          formName="progress"
          label="upload_document_justification"
          fileData={progress.expenditureCurrentFYearFile}
          name="expenditureCurrentFYearFile"
          form={progress}
          fetched={fetched}
          projectId={urlParams.projectId}
        />
      </div>
      {/* Expenditure Budgeted Requirement for coming fiscal year section */}
      <div className="form-group-container">
        <p className="form-info">{lang.expenditure_budgeted_requirement_for_coming_fiscal_year}</p>
        <Form.Group className="flex-wrap fourth-child">
          <Form.Field
            required
            control={Select}
            options={fiscalYear}
            placeholder={lang.fiscal_year}
            width={4}
            name="fiscalYear"
            label={lang.coming_fiscal_year}
            className={projectError.fiscalYear_error ? 'error' : 'field-align-bottom'}
            onChange={(event, { value, name }) => {
              dispatch(
                handleSelectChange(
                  {
                    value,
                    name,
                    info: progress,
                    field: 'expenditureComingFYear',
                  },
                  fetched
                )
              );
              setProjectError({ ...projectError, comingFiscalYear_error: false });
            }}
            value={progress.expenditureComingFYear && progress.expenditureComingFYear.fiscalYear}
          />
          <CurrencyField
            costLabel={lang.estimated_annual_budget_requirement}
            costName="comingEstimatedAnnualBudget"
            exchangeRateLabel="Exchange rate"
            exchangeRateName="comingEstimatedAnnualBudgetExchangeRate"
            costInWordsLabel={lang.estimated_annual_budget_requirement_word}
            costInWordsName="comingEstimatedAnnualBudgetWord"
            formInfo={progress}
            dispatch={dispatch}
            fetched={fetched}
            field="expenditureComingFYear"
            required={true}
            error={projectError.expenditureComingFYear_error}
          />
        </Form.Group>
        <FileUpload
          formName="progress"
          label="upload_document_total_expenditure"
          fileData={progress.expenditureComingFYearFile}
          name="expenditureComingFYearFile"
          form={progress}
          fetched={fetched}
          projectId={urlParams.projectId}
        />
      </div>
      {/* Expenditure Budgeted Requirement for following 2 fiscal year section */}
      <div className="form-group-container">
        <p className="form-info">{lang.projected_budget_requirement_2FY}</p>
        {progress.projectedBudget.map((value, index) => {
          return (
            <div className={index === 0 ? 'field-repeat--first' : 'field-repeat'} key={index}>
              <Form.Group className="flex-wrap fourth-child">
                <Form.Field
                  control={Select}
                  options={fiscalYear}
                  placeholder={lang.fiscal_year}
                  width={4}
                  name="fiscalYear"
                  label={lang.fiscal_year}
                  className="field-align-bottom"
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleSelectChange(
                        {
                          value,
                          name,
                          info: progress,
                          field: 'projectedBudget',
                          index: index,
                        },
                        fetched
                      )
                    );
                  }}
                  value={value.fiscalYear || ''}
                />
                <CurrencyField
                  costLabel={lang.projected_budget_requirement}
                  costName="projectedBudgetRequirement"
                  exchangeRateLabel="Exchange rate"
                  exchangeRateName="projectedBudgetRequirementExchangeRate"
                  costInWordsLabel={lang.projected_budget_requirement_word}
                  costInWordsName="projectedBudgetRequirementWord"
                  formInfo={progress}
                  dispatch={dispatch}
                  fetched={fetched}
                  field="projectedBudget"
                  index={index}
                  error={projectError.projectedBudget_error}
                />
              </Form.Group>
              {index > 0 && (
                <div
                  className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                  onClick={(event) => {
                    dispatch(
                      removeRepeaterField(
                        {
                          event,
                          info: progress,
                          field: 'projectedBudget',
                          index: index,
                        },
                        fetched
                      )
                    );
                  }}
                >
                  <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                  Delete
                </div>
              )}
            </div>
          );
        })}
        <Form.Group className="field-repeat-btn">
          <Form.Field>
            <button
              className="btn-text"
              onClick={(event) => {
                dispatch(
                  addRepeaterField(
                    {
                      event,
                      info: progress,
                      field: 'projectedBudget',
                    },
                    fetched
                  )
                );
              }}
            >
              + {lang.add_more}
            </button>
          </Form.Field>
        </Form.Group>
        <FileUpload
          formName="progress"
          label="upload_document_budget_justification"
          fileData={progress.projectedBudgetFile}
          name="projectedBudgetFile"
          form={progress}
          fetched={fetched}
          projectId={urlParams.projectId}
        />
      </div>
      {urlParams.stage !== 'identification' && (
        <>
          {/*  Expenditure of current fiscal year section */}
          <div className="form-group-container">
            <Form.Group className="flex-wrap fourth-child">
              <Form.Field
                control={Input}
                name="physicalProgress"
                placeholder="In percentage Eg:35"
                width={8}
                className={projectError.physicalProgress_error ? 'error' : 'field-align-bottom'}
                label={lang.physical_progress}
                value={progress.physicalProgress}
                onChange={(event) => {
                  dispatch(
                    handleInputChange(
                      {
                        event,
                        info: progress,
                      },
                      fetched
                    )
                  );
                  setProjectError({ ...projectError, physicalProgress_error: false });
                }}
              />
            </Form.Group>
            <FileUpload
              formName="progress"
              label="upload_document_physical_document"
              fileData={progress.physicalProgressFile}
              name="physicalProgressFile"
              form={progress}
              fetched={fetched}
              projectId={urlParams.projectId}
            />
          </div>
        </>
      )}

      <div className="form-actions flex">
        <button
          className="btn-rect btn-rect--small btn-blue"
          onClick={() => handleSaveAndContinue('continue')}
        >
          Save and continue
        </button>
        <button
          className="btn-rect btn-rect--small btn-dark"
          onClick={() => handleSaveAndContinue('exit')}
        >
          Save and exit
        </button>
        <button
          type="button"
          className="btn-link"
          onClick={() => dispatch(ProjectAction.projectStep({ step: 2 }))}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default Progress;
