import fiscalYear from '../../pages/Gate/FormType/data/fiscalYear.json';
import DateConvert from '../../components/DateConverter/DateConverter';

function calculateCurrentFiscalYear() {
    let currentYear;
    fiscalYear.forEach((year) => {
        const currentDate = new Date();
        const currentYearBool = year.value.includes(
            DateConvert({ englishDate: currentDate }).en.year
        );
        if (currentYearBool) {
            currentYear = year;
        }
    });
    return currentYear.value;
}

export default calculateCurrentFiscalYear;
