import Api from './api';
import ApiUrlConstant from '../helpers/constant/api-url';
import GateUrlConstant from '../helpers/constant/GateApiURL';

class MinistryApi {
    async listAllMinistries(val) {
        try {
            const res = await Api.get(
                await ApiUrlConstant.MINISTRY_LIST(),
                val.download ? 'arraybuffer' : null,
                val
            );
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async listAllGateMinistries(val) {
        try {
            const res = await Api.get(
                await GateUrlConstant.GATE_MINISTRY_LIST(),
                val.download ? 'arraybuffer' : null,
                val
            );
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getMinistry(params) {
        try {
            const res = await Api.get(await ApiUrlConstant.MINISTRY_ACTION(params));
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async updateMinistry(params, data) {
        try {
            const res = await Api.patch(await ApiUrlConstant.MINISTRY_ACTION(params), data);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async createMinistry(data) {
        try {
            const res = await Api.post(await ApiUrlConstant.CREATE_MINISTRY(), data);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async deleteMinistry(params) {
        try {
            const res = await Api.destroy(await ApiUrlConstant.MINISTRY_ACTION(params));
            return res.body;
        } catch (error) {
            return error;
        }
    }
}

export default new MinistryApi();
