import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Select, Input } from 'semantic-ui-react';

import Editor from '../CkEditor/index';
import { localization } from '../../../../localization';
import FileUpload from '../FileUpload/index';
import DatePicker from '../DatePicker/index';
import * as ProjectAction from '../../../../reducers/form/action';
import {
  handleInputChange,
  addRepeaterField,
  handleSelectChange,
  handleEditorChange,
  handleRepeaterChange,
  removeRepeaterField,
  handleSaveAndExit,
  repeaterFieldOptimise,
} from '../../../../actions';
import { fetched, fetchedNullData } from '../../../../reducers/form/LandAcquisition/action';
import LoaderNP from '../../../../components/Loader/Loader';

import { cloneDeep } from 'lodash';

const landTypeOptions = [
  { text: 'Government(Forest)', value: 'governent(forest)', key: 'governent(forest)' },
  { text: 'Government(Other)', value: 'government(other)', key: 'government(other)' },
  { text: 'Guthi', value: 'guthi', key: 'guthi' },
  { text: 'Private', value: 'private', key: 'private' },
  { text: 'Other', value: 'other', key: 'other' },
];

const LandAcquisition = ({ urlParams }) => {
  const selectedLanguage = useSelector((state) => state.localization.selectedLanguage);
  const projectSaveToDbLoader = useSelector((state)=>state.project.projectSaveToDbLoader);
  const lang = localization[selectedLanguage].land_acquisition_status;
  const { landAcquisition } = useSelector((state) => state);
  const { projectInfo } = useSelector((state) => state.projectBasicInfo);
  const dispatch = useDispatch();

  const [projectError, setProjectError] = useState({
    noOfAffectedAreas_error: false,
    noOfRelocated_error: false,
    noOfRemainingRelocated_error: false,
    landAcquisitionStatus_error: [
      {
        requiredLand_error: false,
        totalAcquiredLand_error: false,
        remainingAcquiredLand_error: false,
        estimatedDateToAcquireLand_error: false,
      },
    ],
  });

  useEffect(() => {
    if (projectInfo.land_acquisition_status) {
      dispatch(
        fetched({
          ...projectInfo.land_acquisition_status,
        })
      );
    }
    if (!projectInfo.land_acquisition_status) {
      dispatch(fetchedNullData());
    }
  }, [projectInfo]);

  const handleSaveAndContinue = (type) => {
    let data = cloneDeep(landAcquisition);
    delete data['step'];
    const requiredFields = [
      'noOfAffectedAreas',
      'noOfRelocated',
      'noOfRemainingRelocated',
      'requiredLand',
      'totalAcquiredLand',
      'remainingAcquiredLand',
    ];
    const errors = {};
    let canSubmit = true;

    for (let d in data) {
      if (requiredFields.includes(d) && (data[d] !== '' || data[d].length !== 0)) {
        let regexNumber = /^[+]?\d+(\.\d+)?$/;
        if (!regexNumber.test(data[d])) {
          errors[`${d}_error`] = true;
          canSubmit = false;
        }
      }
      if (d === 'landAcquisitionStatus') {
        for (let i = 0; i < data[d].length; i++) {
          for (let landData in data[d][i]) {
            if (
              requiredFields.includes(landData) &&
              (data[d][i][landData] !== '' || data[d][i][landData].length !== 0)
            ) {
              let regexNumber = /^[+]?\d+(\.\d+)?$/;
              if (!regexNumber.test(data[d][i][landData])) {
                errors[`${landData}_${i}_error`] = true;
                canSubmit = false;
              }
            }
          }
        }
      }
    }
    setProjectError(errors);
    if (canSubmit) {
      let step = urlParams.stage === 'appraisal' ? 11 : 12;
      let newAcquisition = repeaterFieldOptimise(data, 'landAcquisitionStatus', [
        {
          landType: '',
          requiredLand: '',
          totalAcquiredLand: '',
          remainingAcquiredLand: '',
          estimatedDateToAcquireLand: '',
          other: '',
        },
      ]);
      data.landAcquisitionStatus = [...newAcquisition];
      handleSaveAndExit({ land_acquisition_status: data }, type, dispatch, step, urlParams);
    }
  };
  return projectSaveToDbLoader ? <LoaderNP text={true} /> : (
    <Form className="form-new">
      <div className="form-group-container">
        {landAcquisition.landAcquisitionStatus.map((value, index) => {
          return (
            <div
              className={index === 0 ? 'field-repeat--first' : 'field-repeat'}
              key={`land${index}`}
            >
              <Form.Group>
                <Form.Field
                  control={Select}
                  label={lang.land_type}
                  name="landType"
                  width={4}
                  options={landTypeOptions}
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleSelectChange(
                        {
                          value,
                          name,
                          info: landAcquisition,
                          field: 'landAcquisitionStatus',
                          index: index,
                        },
                        fetched
                      )
                    );
                  }}
                  placeholder="Select land type"
                  value={value.landType || ''}
                />
                {value.landType && value.landType === 'other' && (
                  <Form.Field
                    control={Input}
                    width={4}
                    name="other"
                    label={lang.other}
                    className="field-align-bottom"
                    onChange={(event) => {
                      dispatch(
                        handleRepeaterChange(
                          {
                            event,
                            info: landAcquisition,
                            field: 'landAcquisitionStatus',
                            index: index,
                          },
                          fetched
                        )
                      );
                    }}
                    value={value.other}
                  />
                )}
                <Form.Field
                  control={Input}
                  label={lang.required_land}
                  width={4}
                  name="requiredLand"
                  onChange={(event) => {
                    dispatch(
                      handleRepeaterChange(
                        {
                          event,
                          info: landAcquisition,
                          field: 'landAcquisitionStatus',
                          index: index,
                        },
                        fetched
                      )
                    );
                    setProjectError({ ...projectError, [`requiredLand_${index}_error`]: false });
                  }}
                  value={value.requiredLand || ''}
                  className="field-align-bottom"
                  error={
                    projectError[`requiredLand_${index}_error`]
                      ? {
                          content: 'Required land only a number!',
                          pointing: 'below',
                        }
                      : null
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label={lang.total_acquired_land}
                  width={4}
                  name="totalAcquiredLand"
                  onChange={(event) => {
                    dispatch(
                      handleRepeaterChange(
                        {
                          event,
                          info: landAcquisition,
                          field: 'landAcquisitionStatus',
                          index: index,
                        },
                        fetched
                      )
                    );
                    setProjectError({
                      ...projectError,
                      [`totalAcquiredLand_${index}_error`]: false,
                    });
                  }}
                  value={value.totalAcquiredLand || ''}
                  className="field-align-bottom"
                  error={
                    projectError[`totalAcquiredLand_${index}_error`]
                      ? {
                          content: 'Total Required land can  only be a number!',
                          pointing: 'below',
                        }
                      : null
                  }
                />
                <Form.Field
                  control={Input}
                  label={lang.remaining_acquired_land}
                  width={4}
                  name="remainingAcquiredLand"
                  onChange={(event) => {
                    dispatch(
                      handleRepeaterChange(
                        {
                          event,
                          info: landAcquisition,
                          field: 'landAcquisitionStatus',
                          index: index,
                        },
                        fetched
                      )
                    );
                    setProjectError({
                      ...projectError,
                      [`remainingAcquiredLand_${index}_error`]: false,
                    });
                  }}
                  value={value.remainingAcquiredLand || ''}
                  className="field-align-bottom"
                  error={
                    projectError[`remainingAcquiredLand_${index}_error`]
                      ? {
                          content: 'Remaining Acquired land can  only be a number!',
                          pointing: 'below',
                        }
                      : null
                  }
                />
                <DatePicker
                  lang={lang}
                  label="estimated"
                  field="landAcquisitionStatus"
                  formName={landAcquisition}
                  fetched={fetched}
                  name="estimatedDateToAcquireLand"
                  index={index}
                />
              </Form.Group>
              {index > 0 && (
                <div
                  className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                  onClick={(event) => {
                    dispatch(
                      removeRepeaterField(
                        {
                          event,
                          info: landAcquisition,
                          field: 'landAcquisitionStatus',
                          index: index,
                        },
                        fetched
                      )
                    );
                  }}
                >
                  <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                  Delete
                </div>
              )}
            </div>
          );
        })}
        <Form.Group className="field-repeat-btn">
          <Form.Field>
            <button
              className="btn-text"
              onClick={(event) => {
                dispatch(
                  addRepeaterField(
                    {
                      event,
                      info: landAcquisition,
                      field: 'landAcquisitionStatus',
                    },
                    fetched
                  )
                );
              }}
            >
              + Add more
            </button>
          </Form.Field>
        </Form.Group>
      </div>
      <div className="form-group-container">
        <Form.Group>
          <Form.Field width={16}>
            <label>{lang.please_mention_issues}</label>
            <Editor
              formData={landAcquisition.remainingAcquisitionIssues}
              formInfo={landAcquisition}
              field={false}
              name="remainingAcquisitionIssues"
              handleEditorChange={handleEditorChange}
              fetched={fetched}
            />
          </Form.Field>
        </Form.Group>
        <FileUpload
          formName="land_acquisition_status"
          label="upload_issues_in_remaining"
          fileData={landAcquisition.remainingLandAcquisitionFile}
          name="remainingLandAcquisitionFile"
          form={landAcquisition}
          fetched={fetched}
          projectId={urlParams.projectId}
        />
      </div>

      <div className="form-group-container">
        <p className="form-info">{lang.resettlement_plan}</p>
        <Form.Group>
          <Form.Field
            control={Input}
            label={lang.no_of_affected}
            width={4}
            id="noOfAffectedAreas"
            name="noOfAffectedAreas"
            value={landAcquisition.noOfAffectedAreas}
            onChange={(event) => {
              dispatch(handleInputChange({ event, info: landAcquisition }, fetched));
              setProjectError({ ...projectError, noOfAffectedAreas_error: false });
            }}
            className="field-align-bottom"
            error={
              projectError.noOfAffectedAreas_error
                ? {
                    content: 'No of affected areas can  only be a number!',
                    pointing: 'below',
                  }
                : null
            }
          />
          <Form.Field
            control={Input}
            label={lang.no_of_HHs}
            width={4}
            id="noOfRelocated"
            name="noOfRelocated"
            value={landAcquisition.noOfRelocated}
            onChange={(event) => {
              dispatch(handleInputChange({ event, info: landAcquisition }, fetched));
              setProjectError({ ...projectError, noOfRelocated_error: false });
            }}
            className={projectError.noOfRelocated_error ? 'error' : 'field-align-bottom'}
          />
          <Form.Field
            control={Input}
            label={lang.no_of_remaining}
            width={4}
            id="noOfRemainingRelocated"
            name="noOfRemainingRelocated"
            value={landAcquisition.noOfRemainingRelocated}
            onChange={(event) => {
              dispatch(handleInputChange({ event, info: landAcquisition }, fetched));
              setProjectError({ ...projectError, noOfRemainingRelocated_error: false });
            }}
            className="field-align-bottom"
            error={
              projectError.noOfRemainingRelocated_error
                ? {
                    content: 'No of remaining relocated areas can  only be a number!',
                    pointing: 'below',
                  }
                : null
            }
          />
        </Form.Group>
        <FileUpload
          formName="land_acquisition_status"
          label="upload_resettlement"
          fileData={landAcquisition.resettlementPlanFile}
          name="resettlementPlanFile"
          form={landAcquisition}
          fetched={fetched}
          projectId={urlParams.projectId}
        />
      </div>

      <div className="form-actions flex">
        <button
          className="btn-rect btn-rect--small btn-blue"
          onClick={() => handleSaveAndContinue('continue')}
        >
          Save and continue
        </button>
        <button
          className="btn-rect btn-rect--small btn-dark"
          onClick={() => handleSaveAndContinue('exit')}
        >
          Save and exit
        </button>
        <button
          className="btn-link"
          onClick={() => dispatch(ProjectAction.projectStep({ step: 9 }))}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default LandAcquisition;
