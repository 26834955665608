const initialState = {
  step: 1,
  projectOnBoarding: {
    project_name_in_english: '',
    project_name_in_nepali: '',
    part_of_program: '',
    program_id: null,
    project_budget_code: '',
    ministry_id: null,
    department_id: null,
    gate: null,
  },
  projectType: '',
  projectTypeOther: '',
  projectPriority: '',
  projectPriorityOther: '',
  districts: [],
  municipalities: [],
  categorization: '',
  sectors: [],
  subSector: [],
};
export default function (state = initialState, { type, payload }) {
  switch (type) {
    case 'ERROR':
      return {};
    case 'PROJECT_STEP':
      return {
        ...state,
        step: payload.step,
      };
    case 'GENERAL_INFO_FETCHED':
      state = payload;
      return state;

    case 'NULL_GENERAL_INFO_FETCHED':
      let projectOnBoarding = payload.projectOnBoarding;
      state = {...initialState,projectOnBoarding}
      return state;
    default:
      return state;
  }
}
