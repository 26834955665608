import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Grid, Divider } from 'semantic-ui-react';
import { get } from 'lodash';

import { auth } from '../../../helpers';
import { localization } from '../../../localization';

const ContactSection = ({ gate }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['contact_view'];
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const [projectData, setProjectData] = useState();

  useEffect(() => {
    setProjectData(projectInfo[gate]);
  }, [gate, projectInfo]);

  return (
    <div name="contact">
      <div className="project-section" id="contact">
        <div className="header medium">{lang.section_name}</div>
        <Tab.Pane>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.project_head}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.name} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectData, 'contact.projectHead.fullName')}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.designation} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectData, 'contact.projectHead.designation')}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.contact_number} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectData, 'contact.projectHead.phoneNumber')}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.email} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectData, 'contact.projectHead.email')}</p>
              </Grid.Column>
            </Grid.Row>

            <Divider />

            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.focal_person_of_project}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.name} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectData, 'contact.projectFocalPerson.fullNameFocal')}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.designation} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectData, 'contact.projectFocalPerson.designationFocal')}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.contact_number} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectData, 'contact.projectFocalPerson.phoneNumberFocal')}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.email} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">{get(projectData, 'contact.projectFocalPerson.emailFocal')}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      </div>
    </div>
  );
};

export default ContactSection;
