import React from 'react';
import { withRouter } from 'react-router-dom';
import PageNotFound from './PageNotFound';

function PNF({ message }) {
    return (
        <>
            <PageNotFound message={message} />
        </>
    );
}

export default withRouter(PNF);
