const initialState = {
    departmentFetching: true,
    departmentFetched: false,
    departmentError: null,
    departments: {},
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'DEPARTMENT_FETCHING':
            state = {
                ...state,
                ...{
                    departmentError: null,
                    departmentFetching: true,
                    departmentFetched: false,
                },
            };
            break;

        case 'DEPARTMENT_FETCHED':
            state = {
                ...state,
                ...{
                    departmentError: null,
                    departments: payload.data,
                    departmentFetching: false,
                    departmentFetched: true,
                },
            };
            break;
        case 'DEPARTMENT_ERROR':
            state = {
                ...state,
                ...{
                    departmentError: payload,
                    departmentFetching: false,
                    departmentFetched: true,
                },
            };
            break;

        default:
            break;
    }
    return state;
};

export default reducer;
