export function fetching() {
    return { type: 'PROJECT_FETCHING' };
}

export function getProjectInfo(payload) {
    return { type: 'GET_PROJECT_INFO', payload };
}

export function projectList(payload) {
    return { type: 'PROJECT_LIST', payload };
}

export function success(payload) {
    return { type: 'PROJECT_UPDATE_SUCCESS', payload };
}

export function error(payload) {
    return { type: 'PROJECT_ERROR', payload };
}

export function projectStep(payload) {
    return { type: 'PROJECT_STEP', payload };
}

export function checkLoading(payload) {
    return { type: 'LOADING', payload };
}

export function imageLoading(payload) {
    return { type: 'IMAGE_LOADING', payload };
}

export function disableImageButton(payload) {
    return { type: 'DISABLE_IMAGE_BUTTON', payload };
}

export function fetchingProvince() {
    return { type: 'PROVINCE_FETCHING' };
}

export function fetchProvince(payload) {
    return { type: 'PROVINCE_FETCHED', payload };
}

export function errorProvince(payload) {
    return { type: 'PROVINCE_ERROR', payload };
}

export function checkLoadingMap(payload) {
    return { type: 'MAP_LOADING', payload };
}

export function mapProjectList(payload) {
    return { type: 'MAP_PROJECT_LIST', payload };
}

export function stageUpdate(payload) {
    return { type: 'STAGE_UPDATE', payload };
}

export function projectSaveToDbLoader(payload) {
    return { type: 'PROJECT_SAVE_TO_DB_LOADER', payload };
}
