import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

import { localization } from '../../localization';

const LoaderNP = ({ text = false }) => {
    const language = useSelector((state) => state.localization.selectedLanguage);
    const lang = localization[language].loader;

    return (
        <Dimmer inverted active>
            <Loader inverted>{text ? lang.saving : lang.loading}</Loader>
        </Dimmer>
    );
};

export default LoaderNP;
