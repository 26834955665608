import React, { useState } from 'react';
import {Form,  Radio } from 'semantic-ui-react';
// import Editor from '../../../components/CkEditor/Editor';

const ExampleRadio = () => {
  const [value, setValue] = useState('');
  const handleChange = (e, { value }) => {
    setValue(value);

  };
  return (
    <>
      {/* Form group start */}
      <Form.Group>
        <Form.Field width={8}>
          <Form.Field label="Radio buttons" />

          {/* Form group inline start */}
          <Form.Group inline>
            <Form.Field
              label="Yes"
              control={Radio}
              name="isPartOfProgram"
              value="Yes"
              
              checked={value === 'Yes'}
              onChange={handleChange}
            />
            <Form.Field
              label="No"
              control={Radio}
              name="isPartOfProgram"
              value="No"
              
              checked={value === 'No'}
              onChange={handleChange}
            />
          </Form.Group>
          {/* Form group inline end */}
        </Form.Field>

        <Form.Field width={8}>
          <Form.Field label="Radio buttons" />

          {/* Form group inline start */}
          <Form.Group inline>
            <Form.Field label="Yes" control={Radio} name="isPartOfProgram" value="Yes"  />
            <Form.Field label="No" control={Radio} name="isPartOfProgram" value="No"  />
          </Form.Group>
          {/* Form group inline end */}
        </Form.Field>
      </Form.Group>
      {/* Form group end */}

      {/* Form group start */}
      <Form.Group>
        <Form.Field width={4}>
          <Form.Field label="Radio buttons" />

          {/* Form group inline start */}
          <Form.Group inline>
            <Form.Field label="Yes" control={Radio} name="isPartOfProgram" value="Yes" />
            <Form.Field label="No" control={Radio} name="isPartOfProgram" value="No" />
          </Form.Group>
          {/* Form group inline end */}
        </Form.Field>

        <Form.Field width={4}>
          <Form.Field label="Radio buttons" />

          {/* Form group inline start */}
          <Form.Group inline>
            <Form.Field label="Yes" control={Radio} name="isPartOfProgram" value="Yes"/>
            <Form.Field label="No" control={Radio} name="isPartOfProgram" value="No"/>
          </Form.Group>
          {/* Form group inline end */}
        </Form.Field>

        <Form.Field width={4}>
          <Form.Field label="Radio buttons" />

          {/* Form group inline start */}
          <Form.Group inline>
            <Form.Field label="Yes" control={Radio} name="isPartOfProgram" value="Yes"/>
            <Form.Field label="No" control={Radio} name="isPartOfProgram" value="No"/>
          </Form.Group>
          {/* Form group inline end */}
        </Form.Field>

        <Form.Field width={4}>
          <Form.Field label="Radio buttons" />

          {/* Form group inline start */}
          <Form.Group inline>
            <Form.Field label="Yes" control={Radio} name="isPartOfProgram" value="Yes" />
            <Form.Field label="No" control={Radio} name="isPartOfProgram" value="No" />
          </Form.Group>
          {/* Form group inline end */}
        </Form.Field>
      </Form.Group>
      {/* Form group end */}
    </>
  );
};

export default ExampleRadio;
