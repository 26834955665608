import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { lists } from '../../../reducers/form/Assessment/action';
import { getFileFromCloud } from '../../../actions/index';

import ProjectApi from '../../../services/Gate/Project';

const AssessmentSection = ({ projectId, gate }) => {
    const dispatch = useDispatch();

    const [expand, setExpand] = useState(false);

    const handleExpand = () => {
        setExpand(!expand);
    };

    const { assessments } = useSelector((state) => state.assessment);

    const listAllAssessment = () => {
        const stage =
            gate === 'project_identification'
                ? 'identification'
                : gate === 'project_appraisal'
                ? 'appraisal'
                : '';

        ProjectApi.projectGateAssessment({ id: projectId }, stage)
            .then((response) => {
                dispatch(lists(response));
            })
            .catch((err) => {
                toastr.error('', `${err.message}`);
            });
    };

    useEffect(() => {
        if (projectId && gate) {
            listAllAssessment();
        }
    }, [projectId, gate]);

    const downloadFile = (fileName) => {
        getFileFromCloud(projectId, fileName);
    };

    return (
        assessments.length > 0 && (
            <div className="assessment no-print">
                <div
                    className={`assessment__heading flex justify-between align-center ${
                        expand ? 'expand' : ''
                    }`}
                    onClick={() => handleExpand()}
                >
                    <p className="heading-title body-2">
                        <button className="btn-text text-bold">
                            {expand ? 'Hide' : 'See'} assessment
                        </button>
                    </p>
                    <p className="heading-date label-1">
                        Last assessed on{' '}
                        <span>{assessments && assessments[0] && assessments[0].assessedDate}</span>
                    </p>
                </div>

                {expand && (
                    <>
                        {assessments.map((assessment, index) => {
                            return (
                                <div className="assessment__items" key={index}>
                                    <div className="assessment__item">
                                        <p className="text-bold label-1 assessed-on">
                                            {assessment.action === 'revision'
                                                ? 'Assessed for revision on'
                                                : assessment.action === 'reject'
                                                ? 'Rejected on'
                                                : assessment.appraisal_id
                                                ? 'Approved on'
                                                : assessment.identification_id
                                                ? 'Sent to appraisal on'
                                                : ''}{' '}
                                            <span>{assessment.assessedDate}</span>
                                        </p>
                                        <Grid columns={2} padded>
                                            <Grid.Row>
                                                <Grid.Column width={6}>
                                                    <p className="label">
                                                        Is the proposed project aligned with the
                                                        government development strategy?
                                                    </p>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <p className="project-info">
                                                        {assessment.development_strategy}
                                                    </p>
                                                    <p>{assessment.development_strategy_remarks}</p>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={6}>
                                                    <p className="label">
                                                        Does it have the intended developmental
                                                        impacts?
                                                    </p>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <p className="project-info">
                                                        {assessment.developmental_impacts}
                                                    </p>
                                                    <p>
                                                        {assessment.developmental_impacts_remarks}
                                                    </p>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={6}>
                                                    <p className="label">
                                                        Is the project economically viable?
                                                    </p>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <p className="project-info">
                                                        {assessment.economically_viable}
                                                    </p>
                                                    <p>{assessment.economically_viable_remarks}</p>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={6}>
                                                    <p className="label">
                                                        Is the proposed project financially
                                                        affordable?
                                                    </p>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <p className="project-info">
                                                        {assessment.financially_affordable}
                                                    </p>
                                                    <p>
                                                        {assessment.financially_affordable_remarks}
                                                    </p>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={6}>
                                                    <p className="label">
                                                        Is there institutional capacity to execute
                                                        the project?
                                                    </p>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <p className="project-info">
                                                        {assessment.institutional_capacity}
                                                    </p>
                                                    <p>
                                                        {assessment.institutional_capacity_remarks}
                                                    </p>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={6}>
                                                    <p className="label">
                                                        Is the chosen technology appropriate?
                                                    </p>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <p className="project-info">
                                                        {assessment.technology_appropriate}
                                                    </p>
                                                    <p>
                                                        {assessment.technology_appropriate_remarks}
                                                    </p>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={6}>
                                                    <p className="label">Note</p>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <p
                                                        className="project-info"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                assessment.note &&
                                                                assessment.note.text,
                                                        }}
                                                    ></p>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row width={6}>
                                                <Grid.Column width={6}>
                                                    <p className="label">Uploaded Document</p>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <div className="project-info file-download">
                                                        {assessment.note &&
                                                            assessment.note.documentFile &&
                                                            assessment.note.documentFile.map(
                                                                (item, index) => {
                                                                    return (
                                                                        Object.keys(item).length !==
                                                                            0 && (
                                                                            <>
                                                                                <p key={index}>
                                                                                    {item.originalName
                                                                                        ? item.originalName
                                                                                        : item.fileName}
                                                                                    {item.fileName
                                                                                        .length >
                                                                                        0 && (
                                                                                        <>
                                                                                            {item.fileSize ? (
                                                                                                <span>
                                                                                                    (
                                                                                                    {
                                                                                                        item.fileSize
                                                                                                    }

                                                                                                    )
                                                                                                </span>
                                                                                            ) : (
                                                                                                ''
                                                                                            )}
                                                                                            <button
                                                                                                type="submit"
                                                                                                onClick={() =>
                                                                                                    downloadFile(
                                                                                                        item.cloudFileName
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                (Download)
                                                                                            </button>
                                                                                        </>
                                                                                    )}
                                                                                </p>
                                                                                <p>
                                                                                    {
                                                                                        item.fileDescription
                                                                                    }
                                                                                </p>
                                                                            </>
                                                                        )
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={6}>
                                                    <p className="label">Other</p>
                                                </Grid.Column>
                                                <Grid.Column width={10}>
                                                    <p
                                                        className="project-info"
                                                        dangerouslySetInnerHTML={{
                                                            __html: assessment.other,
                                                        }}
                                                    ></p>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </div>
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
        )
    );
};

export default AssessmentSection;
