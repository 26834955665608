/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import { setCurrentUser } from '../services/Auth';
import store from '../store';

import PrivateRoute from '../components/Router/PrivateRoute';
import Login from '../pages/auth/Login';
import DashBoard from '../pages/Dashboard';
import Header from '../layouts/Header';
import FeedbackForm from '../pages/Feedback';
import Faq from '../pages/Faq';
import ProgramList from '../pages/Program';
import UserList from '../pages/User';
import User from '../pages/User/User';
import DepartmentList from '../pages/Department';
import Department from '../pages/Department/Department';
import Profile from '../pages/User/Profile';
import UserSetting from '../pages/User/UserSetting';
import Map from '../pages/ProjectMap';
import PageNotFound from '../pages/PageNotFound';
import HomePage from '../pages/HomePage';
import GateExample from '../pages/Gate/Example';
import Gate from '../pages/Gate';
import BasicAddProject from '../pages/Gate/BasicInfo';
import GateDashBoard from '../pages/Gate/Dashboard';
import GateViewPage from '../pages/Gate/GateViewPages/GateViewPage';
import GateTrash from '../pages/Gate/Trash';
import GateMinistryList from '../pages/Gate/Ministry';
import GateMinistry from '../pages/Gate/Ministry/Ministry';
import GateSector from '../pages/Gate/Sector';
import ParentProject from '../pages/Gate/ParentProject';
import NewIdentificationRanking from '../pages/Gate/Ranking/NewIdentificationRanking';
import NewAppraisalRanking from '../pages/Gate/Ranking/NewAppraisalRanking';
import OngoingGateRanking from '../pages/Gate/Ranking/OngoingGateRanking';
import QuarterlyAssessment from '../pages/Gate/QuarterlyAssessment';
import Config from '../pages/Gate/Configuration';
import EmailList from '../pages/Gate/Configuration/EmailList';
import ClosedProjectList from '../pages/Gate/ClosedProject';
import UploadGuideline from '../pages/Gate/UploadGuideline';
import Footer from '../layouts/Footer';

window.language = localStorage.getItem('language') || 'ne';

const cookieToken = Cookies.get('npbmisToken');
if (localStorage.npbmisToken && cookieToken) {
    const parsedToken = JSON.parse(localStorage.npbmisToken);
    const { token } = parsedToken;
    const { timestamp } = parsedToken;
    const today = new Date();

    const previousDate = new Date(timestamp);
    previousDate.setDate(previousDate.getDate() + 7);

    const fullDateToday = today.getTime();
    const fullPreviousDate = previousDate.getTime();

    if (fullDateToday <= fullPreviousDate) {
        const decoded = jwtDecode(token);
        store.dispatch(setCurrentUser(decoded));
    } else {
        localStorage.removeItem('npbmisToken');
        Cookies.remove('npbmisToken');
    }
}

class Routes extends Component {
    render() {
        return (
            <>
                <div>
                    <ReduxToastr
                        timeOut={4000}
                        newestOnTop={false}
                        preventDuplicates
                        position="bottom-left"
                        transitionIn="fadeIn"
                        transitionOut="fadeOut"
                        progressBar={false}
                        closeOnToastrClick
                    />
                </div>
                <BrowserRouter>
                    <Header />
                    <Switch>
                        {this.props.auth.isAuthenticated ? (
                            <Route exact path="/" component={DashBoard}>
                                {' '}
                                <Redirect from="/" to="/dashboard" />
                            </Route>
                        ) : (
                            <Route exact path="/" component={HomePage} />
                        )}
                        <Route exact path="/login" component={Login} />
                        <PrivateRoute exact path="/faq" component={Faq} />
                        <PrivateRoute exact path="/programs" component={ProgramList} />
                        <PrivateRoute exact path="/users" component={UserList} />
                        <PrivateRoute exact path="/add/users" component={User} />
                        <PrivateRoute exact path="/departments" component={DepartmentList} />
                        <PrivateRoute exact path="/add/departments" component={Department} />
                        <PrivateRoute exact path="/feedback" component={FeedbackForm} />
                        <PrivateRoute exact path="/profile" component={Profile} />
                        <PrivateRoute exact path="/setting" component={UserSetting} />
                        <PrivateRoute exact path="/projects/map" component={Map} />

                        {/*
                         *   gate routes
                         */}

                        <PrivateRoute exact path="/project" component={BasicAddProject} />
                        <PrivateRoute exact path="/add/project" component={Gate} />
                        <PrivateRoute exact path="/gate/example" component={GateExample} />
                        <PrivateRoute exact path="/dashboard" component={GateDashBoard} />
                        <PrivateRoute exact path="/project/:projectId" component={GateViewPage} />
                        <PrivateRoute exact path="/trash" component={GateTrash} />
                        <PrivateRoute exact path="/ministries" component={GateMinistryList} />
                        <PrivateRoute exact path="/add/ministry" component={GateMinistry} />
                        <PrivateRoute exact path="/sectors" component={GateSector} />
                        <PrivateRoute exact path="/gate/parent" component={ParentProject} />
                        <PrivateRoute exact path="/config" component={Config} />
                        <PrivateRoute exact path="/emails" component={EmailList} />
                        <PrivateRoute exact path="/closed-projects" component={ClosedProjectList} />
                        <PrivateRoute exact path="/upload-guidelines" component={UploadGuideline} />
                        <PrivateRoute
                            exact
                            path="/new-identification-ranking"
                            component={NewIdentificationRanking}
                        />
                        <PrivateRoute
                            exact
                            path="/new-appraisal-ranking"
                            component={NewAppraisalRanking}
                        />
                        <PrivateRoute
                            exact
                            path="/ongoing-project-ranking"
                            component={OngoingGateRanking}
                        />
                        <PrivateRoute
                            exact
                            path="/assessment/:projectId"
                            component={QuarterlyAssessment}
                        />

                        <PrivateRoute component={PageNotFound} />
                    </Switch>
                    <Footer />
                </BrowserRouter>
            </>
        );
    }
}

const mapStateToProps = (state) => ({ auth: state.auth });
export default connect(mapStateToProps)(Routes);
