import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { cloneDeep } from 'lodash';
import { Input } from 'semantic-ui-react';
import ScrollArea from 'react-scrollbar';

import * as MinistryAction from '../../../reducers/ministry/action';
import MinsitryApi from '../../../services/Ministry';
import * as DepartmentAction from '../../../reducers/department/action';
import DepartmentApi from '../../../services/Department';
import ProgramApi from '../../../services/Program';
import ProjectApi from '../../../services/Gate/Project';

import FilterSection from './FilterSection';
import FilterMultiSection from './FilterMultiSection';

const Filter = (props) => {
    const dispatch = useDispatch();
    let wrapperRef = useRef();
    const { user, language, filterValues } = props;
    const [data, setData] = useState({
        ministries: {},
        departments: [],
        programs: [],
        sectors: [],
        provinces: [],
    });

    const [showOptions, setShowOptions] = useState({
        showMinistryOption: false,
        showDepartmentOption: false,
        showProgramOption: false,
        showProjectStatusOption: false,
        showProjectStageOption: false,
        showProjectTypeOption: false,
        showProjectPriorityOption: false,
        showProjectRankingOption: false,
        showSectorsOption: false,
        showSubSectorsOption: false,
        showYearOnYearOption: false,
        showModerationStatusOption: false,
        showProjectDateOption: false,
        showAIEOption: false,
        // showProvinceOption: false,
        showDistrictOption: false,
        showProjectReadinessOption: false,
        collapseAll: false,
    });
    const [showSidebar, setShowSidebar] = useState(false);

    const listMinistries = () => {
        dispatch(MinistryAction.fetchingMinstries());
        MinsitryApi.listAllMinistries(`?isDashboard=true&`)
            .then((response) => {
                if (response.success) {
                    const list = [];
                    for (let m of response.data.ministries.lists) {
                        let ministry = { label: m.name, value: m.id };
                        list.push(ministry);
                    }
                    setData((data) => {
                        listDepartments({ ministryId: user.ministry.id });
                        return { ...data, ministries: list };
                    });
                    dispatch(MinistryAction.fetchMinistries(response));
                } else {
                    dispatch(MinistryAction.errorMinistries(response));
                }
            })
            .catch((err) => dispatch(MinistryAction.errorMinistries(err)));
    };
    const listDepartments = (param) => {
        dispatch(DepartmentAction.fetchingDepartment());
        DepartmentApi.listDepartments(param)
            .then((response) => {
                if (response.success) {
                    setData((data) => {
                        fetchProgramList();
                        return { ...data, departments: response.data.department };
                    });
                    dispatch(DepartmentAction.fetchDepartment(response));
                } else {
                    dispatch(DepartmentAction.errorDepartment(response));
                }
            })
            .catch((err) => dispatch(DepartmentAction.errorDepartment(err)));
    };
    const fetchProgramList = () => {
        ProgramApi.listPrograms({ ministryId: user.ministry.id })
            .then((response) => {
                setData((data) => {
                    fetchSectors();
                    return { ...data, programs: response };
                });
            })
            .catch((err) => {
                toastr.error('', `${err.message}`);
            });
    };

    const fetchSectors = () => {
        ProjectApi.getAllsectors()
            .then((response) => {
                if (response.success) {
                    setData((data) => {
                        fetchLocation();
                        return { ...data, sectors: response.data.sectorList };
                    });
                }
            })
            .catch((err) => toastr.error('', err.message));
    };

    const fetchLocation = () => {
        ProjectApi.getAllLocation()
            .then((response) => {
                if (response.success) {
                    const provinces = [];

                    for (let p of response.data.provinces) {
                        const districts = [];
                        for (let d of response.data.districts) {
                            if (d.parent_id === p.id)
                                districts.push({
                                    label: d.name,
                                    value: d.id,
                                    parent_id: d.parent_id,
                                });
                        }
                        provinces.push({
                            label: p.name,
                            value: p.id,
                            districts,
                        });
                    }

                    setData((data) => {
                        return { ...data, provinces: provinces };
                    });
                }
            })
            .catch((err) => toastr.error('', err.message));
    };

    useEffect(() => {
        if (['npcAdmin', 'dataViewer'].includes(user.role)) {
            listMinistries();
        } else {
            listDepartments({ ministryId: user.ministry.id });
        }
    }, [user]);

    const programList = () => {
        let list = data.programs;
        if (data.programs && filterValues.ministry_id[0].value !== 0) {
            let ministry = [...filterValues.ministry_id].map((m) => m.value);
            let filter = cloneDeep(data.programs).filter((p) => ministry.includes(p.ministry_id));
            list = cloneDeep(filter);
        }
        return list;
    };

    const departments = () => {
        const list = [];
        if (data.departments.length > 0 && filterValues.ministry_id[0].value !== 0) {
            let ministry = [...filterValues.ministry_id].map((m) => m.value);
            let dpt = data.departments.filter((d) => ministry.includes(d.ministry_id));

            for (let d of dpt) {
                let check = list.find((a) => d.title === a.label);
                if (!check)
                    list.push({
                        label: d.title,
                        value: d.id,
                        ministry_id: d.ministry_id,
                    });
            }
        }

        return list;
    };

    const subSectors = () => {
        let list = [];

        if (filterValues.sector[0].value !== 0) {
            for (let sector of filterValues.sector) {
                for (let subsector of sector.subSector) {
                    subsector.sector = sector.label;
                }
                list = [...list, ...sector.subSector];
            }
        }
        return list;
    };

    const districts = () => {
        let list = [];

        // if (filterValues.province[0].value !== 0) {
        // let provinces = [...filterValues.province].map((m) => m.value);

        // let filter = filterValues.province.filter((p) => provinces.includes(p.value));

        for (let province of data.provinces) {
            for (let district of province.districts) {
                district.province = province.value;
            }
            list = [...list, ...province.districts];
        }
        // }
        return list;
    };
    const handleOutsideClick = (event) => {
        if (wrapperRef && !wrapperRef.current.contains(event.target)) {
            setShowSidebar(false);
        }
    };
    const escFunction = (event) => {
        if (event.keyCode === 27) {
            setShowSidebar(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick, false);
        document.addEventListener('keydown', escFunction, false);
        // returned function will be called on component unmount
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick, false);
            document.removeEventListener('keydown', escFunction, false);
        };
    }, []);

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const toggleFilterOption = (filter) => {
        setShowOptions({
            ...showOptions,
            [filter]: !showOptions[filter],
            collapseAll: true,
        });
    };

    const collapseAll = () => {
        setShowOptions({
            showMinistryOption: !showOptions.collapseAll,
            showDepartmentOption: !showOptions.collapseAll,
            showProgramOption: !showOptions.collapseAll,
            showProjectStatusOption: !showOptions.collapseAll,
            showProjectStageOption: !showOptions.collapseAll,
            showProjectTypeOption: !showOptions.collapseAll,
            showProjectPriorityOption: !showOptions.collapseAll,
            showProjectRankingOption: !showOptions.collapseAll,
            showSectorsOption: !showOptions.collapseAll,
            showSubSectorsOption: !showOptions.collapseAll,
            showYearOnYearOption: !showOptions.collapseAll,
            showModerationStatusOption: !showOptions.collapseAll,
            showProjectDateOption: !showOptions.collapseAll,
            showAIEOption: !showOptions.collapseAll,
            showProvinceOption: !showOptions.collapseAll,
            showDistrictOption: !showOptions.collapseAll,
            showProjectReadinessOption: !showOptions.collapseAll,
            collapseAll: !showOptions.collapseAll,
        });
    };

    const readinessChildComp = (title, filterLabel, selectedVal, options) => {
        return (
            <div className="options-multiple">
                <p className="option-title">{title}</p>
                <label className="radio-btn filter-label-inline">
                    <input
                        type="radio"
                        onChange={(event) => {
                            props.handleChange(filterLabel, 'Yes');
                        }}
                        checked={selectedVal === 'Yes'}
                        name={title}
                    />
                    {options.yes}
                    <span className="checkmark"></span>
                </label>
                <label className="radio-btn filter-label-inline">
                    <input
                        type="radio"
                        onChange={(event) => {
                            props.handleChange(filterLabel, 'No');
                        }}
                        checked={selectedVal === 'No'}
                        name={title}
                    />
                    {options.no}
                    <span className="checkmark"></span>
                </label>
                <label className="radio-btn filter-label-inline">
                    <input
                        type="radio"
                        onChange={(event) => {
                            props.handleChange(filterLabel, 'Not Required');
                        }}
                        checked={selectedVal === 'Not Required'}
                        name={title}
                    />
                    {options.nor}
                    <span className="checkmark"></span>
                </label>
            </div>
        );
    };

    const status = [
        { label: 'Save as draft', value: 'draft' },
        { label: 'Send to verification', value: 'published' },
        { label: 'Submit', value: 'completed' },
    ];

    const yearOnYearProject = [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
    ];

    const projectStatusOptions = [
        { label: 'New', value: 'new' },
        { label: 'Ongoing', value: 'ongoing' },
    ];

    const projectStageOptions = [
        { label: 'Identification', value: 'identification' },
        { label: 'Appraisal', value: 'appraisal' },
    ];

    const projectTypeOptions = [
        { label: 'Service Oriented', value: 'serviceOriented' },
        { label: 'Construction', value: 'construction' },
        { label: 'Production', value: 'production' },
        { label: 'Trade', value: 'trade' },
        { label: 'Others', value: 'others' },
    ];

    const priorityProject = [
        { label: 'National Pride Project', value: 'nationalPrideProject' },
        { label: 'Priority 1', value: 'priority1' },
        { label: 'Priority 2', value: 'priority2' },
        { label: 'Game Changer', value: 'gameChanger' },
        { label: 'Others', value: 'other' },
    ];

    const projectRankingOptions = [
        { label: 'A', value: 'A' },
        { label: 'A-', value: 'A-' },
        { label: 'B', value: 'B' },
        { label: 'B-', value: 'B-' },
        { label: 'C', value: 'C' },
        { label: 'C-', value: 'C-' },
        { label: 'D', value: 'D' },
        { label: 'D-', value: 'D-' },
        { label: 'E', value: 'E' },
    ];
    const ids = [...filterValues.ranking].map((d) => d.value);

    const checkForShowOptions = () => {
        return !showOptions.showMinistryOption &&
            !showOptions.showDepartmentOption &&
            !showOptions.showProgramOption &&
            !showOptions.showProjectStatusOption &&
            !showOptions.showProjectStageOption &&
            !showOptions.showProjectTypeOption &&
            !showOptions.showProjectPriorityOption &&
            !showOptions.showProjectRankingOption &&
            !showOptions.showSectorsOption &&
            !showOptions.showSubSectorsOption &&
            !showOptions.showYearOnYearOption &&
            !showOptions.showModerationStatusOption &&
            !showOptions.showProjectDateOption &&
            !showOptions.showAIEOption &&
            !showOptions.showProjectDateOption &&
            !showOptions.showDistrictOption &&
            !showOptions.showProjectReadinessOption
            ? true
            : false;
    };

    return (
        <div ref={(el) => (wrapperRef.current = el)}>
            <div className={`sidebar-filter ${showSidebar ? '' : 'hidden'}`}>
                <div className="close-button" onClick={toggleSidebar}>
                    <i className="material-icons close">close</i>
                </div>
                <div className="filter-container">
                    <div
                        className={`filter-action
                ${
                    filterValues.program_id[0].value !== 0 ||
                    (['npcAdmin', 'dataViewer'].includes(user.role) &&
                        filterValues.ministry_id[0].value !== 0) ||
                    filterValues.department[0].value !== 0 ||
                    filterValues.projectStatus.value !== 0 ||
                    filterValues.projectType[0].value !== 0 ||
                    filterValues.priorityProjectOptions[0].value !== 0 ||
                    filterValues.sector[0].value !== 0 ||
                    filterValues.subSector[0].value !== 0 ||
                    filterValues.department[0].value !== 0 ||
                    // filterValues.province[0].value !== 0 ||
                    filterValues.district[0].value !== 0 ||
                    filterValues.ranking[0].value !== 0 ||
                    filterValues.isYearlyFunding.value !== 0 ||
                    filterValues.moderationStatus.value !== 0 ||
                    (filterValues.projectCostFrom && filterValues.projectCostTo) ||
                    filterValues.projectStartDateFrom ||
                    filterValues.projectStartDateTo ||
                    filterValues.feasibilityStudy ||
                    filterValues.dpr ||
                    filterValues.eia ||
                    filterValues.iee ||
                    filterValues.monitorEvaluation ||
                    filterValues.riskManagement ||
                    filterValues.logFrame ||
                    filterValues.deskStudy ||
                    filterValues.projectConcept ||
                    filterValues.projectProposal ||
                    filterValues.masterProcurement ||
                    filterValues.annualProcurement ||
                    filterValues.annualProcurementComing ||
                    filterValues.implementationPlan ||
                    filterValues.procurementAward ||
                    filterValues.landAcquisition
                        ? 'visibility-visible'
                        : 'visibility-hidden'
                }
            `}
                    >
                        <button
                            className="btn-rect btn-blue"
                            onClick={() => {
                                props.searchProject()();
                                toggleSidebar();
                                props.handleApplyFilterStatus();
                            }}
                        >
                            {language.apply_filter}
                        </button>
                        <button className="btn-text" onClick={props.clearFilter}>
                            {language.clear_filter}
                        </button>
                    </div>
                    <div className="header">
                        <p className="title">{language.filter}</p>
                        <p className="collapse" onClick={collapseAll}>
                            {/* {language.collapse_all_filter} */}
                            {checkForShowOptions()
                                ? language.expand_all
                                : language.collapse_all_filter}
                            <i className="material-icons ml-2 md-bold md-blue">{`${
                                checkForShowOptions() ? 'add' : 'remove'
                            }`}</i>
                        </p>
                    </div>
                    {/* Filter Section for Ministries */}
                    {['dataViewer', 'npcAdmin'].indexOf(user.role) !== -1 && (
                        <FilterMultiSection
                            title={language.line_ministry}
                            toggleFilterOption={() => toggleFilterOption('showMinistryOption')}
                            showOption={showOptions.showMinistryOption}
                            placeholder="Type a ministry name ..."
                            data={data.ministries}
                            handleSelect={props.handleSelect}
                            selectedVal={filterValues.ministry_id}
                            filterLabel={'ministry_id'}
                            showSearch={true}
                            applied={props.applied}
                        />
                    )}
                    {/* Filter Section for Implementing Agency */}
                    {filterValues.ministry_id && filterValues.ministry_id[0].value !== 0 && (
                        <FilterMultiSection
                            title={language.implementing_agency}
                            toggleFilterOption={() => toggleFilterOption('showDepartmentOption')}
                            showOption={showOptions.showDepartmentOption}
                            placeholder="Type a department name ..."
                            data={departments()}
                            handleSelect={props.handleSelect}
                            selectedVal={filterValues.department}
                            filterLabel={'department'}
                            showSearch={true}
                            applied={props.applied}
                        />
                    )}
                    {/* Filter Section for Programs */}
                    <FilterMultiSection
                        title={language.program}
                        toggleFilterOption={() => toggleFilterOption('showProgramOption')}
                        showOption={showOptions.showProgramOption}
                        placeholder="Type a program title..."
                        data={programList()}
                        handleSelect={props.handleSelect}
                        selectedVal={filterValues.program_id}
                        filterLabel={'program_id'}
                        showSearch={true}
                        applied={props.applied}
                    />
                    {/* Filter Section for Project Status */}
                    <FilterSection
                        title={language.project_status}
                        toggleFilterOption={() => toggleFilterOption('showProjectStatusOption')}
                        showOption={showOptions.showProjectStatusOption}
                        placeholder="Type a project status ..."
                        data={projectStatusOptions}
                        handleSelect={props.handleChange}
                        selectedVal={filterValues.projectStatus}
                        filterLabel={'projectStatus'}
                        showSearch={false}
                    />
                    {/* Filter Section for Project Stage */}
                    {filterValues.projectStatus && filterValues.projectStatus.value === 'new' && (
                        <FilterSection
                            title={language.project_stage}
                            toggleFilterOption={() => toggleFilterOption('showProjectStageOption')}
                            showOption={showOptions.showProjectStageOption}
                            placeholder="Type a project stage ..."
                            data={projectStageOptions}
                            handleSelect={props.handleChange}
                            selectedVal={filterValues.projectStage}
                            filterLabel={'projectStage'}
                            showSearch={false}
                        />
                    )}
                    {/* Filter Section for Project type */}
                    <FilterMultiSection
                        title={language.project_type}
                        toggleFilterOption={() => toggleFilterOption('showProjectTypeOption')}
                        showOption={showOptions.showProjectTypeOption}
                        placeholder="Type a project-type title ..."
                        data={projectTypeOptions}
                        handleSelect={props.handleSelect}
                        selectedVal={filterValues.projectType}
                        filterLabel={'projectType'}
                        showSearch={false}
                        applied={props.applied}
                    />
                    {/* Filter Section for Project Priority */}
                    <FilterMultiSection
                        title={language.project_priority}
                        toggleFilterOption={() => toggleFilterOption('showProjectPriorityOption')}
                        showOption={showOptions.showProjectPriorityOption}
                        placeholder="Type a project priority ..."
                        data={priorityProject}
                        handleSelect={props.handleSelect}
                        selectedVal={filterValues.priorityProjectOptions}
                        filterLabel={'priorityProjectOptions'}
                        showSearch={false}
                        applied={props.applied}
                    />
                    {/* Filter Section for Project Ranking */}
                    <div className="filter-section">
                        <div
                            className="header"
                            onClick={() => toggleFilterOption('showProjectRankingOption')}
                        >
                            <p className="title">{language.project_ranking}</p>
                            <div className="collapse">
                                <i className="material-icons md-bold md-blue">{`${
                                    showOptions.showProjectRankingOption ? 'remove' : 'add'
                                }`}</i>
                            </div>
                        </div>
                        <div
                            className={`contents ${
                                showOptions.showProjectRankingOption ? '' : 'hide'
                            }`}
                        >
                            <div className="options">
                                <div className="filter-ranking">
                                    {projectRankingOptions.map((r) => (
                                        <div key={r.label} className={`bold ${r.label}`}>
                                            <label>
                                                {r.value}
                                                <input
                                                    type="checkbox"
                                                    onChange={(event) => {
                                                        props.handleSelect('ranking', r);
                                                    }}
                                                    checked={ids.includes(r.value)}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Filter section for project readiness */}
                    <div className="filter-section">
                        <div
                            className="header"
                            onClick={() => toggleFilterOption('showProjectReadinessOption')}
                        >
                            <p className="title">{language.project_readiness}</p>
                            <div className="collapse">
                                <i className="material-icons md-bold md-blue">{`${
                                    showOptions.showProjectReadinessOption ? 'remove' : 'add'
                                }`}</i>
                            </div>
                        </div>
                        <div
                            className={`contents ${
                                showOptions.showProjectReadinessOption ? '' : 'hide'
                            }`}
                        >
                            <div className="options">
                                <ScrollArea speed={0.8} horizontal={false}>
                                    {readinessChildComp(
                                        language.feasibility_study,
                                        'feasibilityStudy',
                                        filterValues.feasibilityStudy,
                                        {
                                            yes: language.yes,
                                            no: language.on_progress,
                                            nor: language.not_required,
                                        }
                                    )}
                                    {readinessChildComp(
                                        language.detailed_project_report,
                                        'dpr',
                                        filterValues.dpr,
                                        {
                                            yes: language.yes,
                                            no: language.on_progress,
                                            nor: language.not_required,
                                        }
                                    )}
                                    {readinessChildComp(language.eia, 'eia', filterValues.eia, {
                                        yes: language.yes,
                                        no: language.on_progress,
                                        nor: language.not_required,
                                    })}
                                    {readinessChildComp(language.iee, 'iee', filterValues.iee, {
                                        yes: language.yes,
                                        no: language.on_progress,
                                        nor: language.not_required,
                                    })}
                                    {readinessChildComp(
                                        language.monitor_evaluation_plan,
                                        'monitorEvaluation',
                                        filterValues.monitorEvaluation,
                                        {
                                            yes: language.yes,
                                            no: language.on_progress,
                                            nor: language.not_required,
                                        }
                                    )}
                                    {readinessChildComp(
                                        language.risk_management_and_mitigation_plan,
                                        'riskManagement',
                                        filterValues.riskManagement,
                                        {
                                            yes: language.yes,
                                            no: language.on_progress,
                                            nor: language.not_required,
                                        }
                                    )}
                                    {readinessChildComp(
                                        language.development_of_logical_frameWork,
                                        'logFrame',
                                        filterValues.logFrame,
                                        {
                                            yes: language.yes,
                                            no: language.on_progress,
                                            nor: language.not_required,
                                        }
                                    )}
                                    {readinessChildComp(
                                        language.desk_study,
                                        'deskStudy',
                                        filterValues.deskStudy,
                                        {
                                            yes: language.yes,
                                            no: language.on_progress,
                                            nor: language.not_required,
                                        }
                                    )}
                                    {readinessChildComp(
                                        language.project_concept_note,
                                        'projectConcept',
                                        filterValues.projectConcept,
                                        {
                                            yes: language.yes,
                                            no: language.on_progress,
                                            nor: language.not_required,
                                        }
                                    )}
                                    {readinessChildComp(
                                        language.project_proposal,
                                        'projectProposal',
                                        filterValues.projectProposal,
                                        {
                                            yes: language.yes,
                                            no: language.on_progress,
                                            nor: language.not_required,
                                        }
                                    )}
                                    {readinessChildComp(
                                        language.master_procurement_plan,
                                        'masterProcurement',
                                        filterValues.masterProcurement,
                                        {
                                            yes: language.yes,
                                            no: language.on_progress,
                                            nor: language.not_required,
                                        }
                                    )}
                                    {readinessChildComp(
                                        language.annual_procurement_plan_for_this_FY,
                                        'annualProcurement',
                                        filterValues.annualProcurement,
                                        {
                                            yes: language.yes,
                                            no: language.on_progress,
                                            nor: language.not_required,
                                        }
                                    )}
                                    {readinessChildComp(
                                        language.annual_procurement_plan_for_this_coming_FY,
                                        'annualProcurementComing',
                                        filterValues.annualProcurementComing,
                                        {
                                            yes: language.yes,
                                            no: language.on_progress,
                                            nor: language.not_required,
                                        }
                                    )}
                                    {readinessChildComp(
                                        language.project_implementation_plan,
                                        'implementationPlan',
                                        filterValues.implementationPlan,
                                        {
                                            yes: language.yes,
                                            no: language.on_progress,
                                            nor: language.not_required,
                                        }
                                    )}
                                    {readinessChildComp(
                                        language.procurement_and_contract_award,
                                        'procurementAward',
                                        filterValues.procurementAward,
                                        {
                                            yes: language.yes,
                                            no: language.on_progress,
                                            nor: language.not_required,
                                        }
                                    )}
                                    {readinessChildComp(
                                        language.completion_of_the_legal_process_of_land_acquisition,
                                        'landAcquisition',
                                        filterValues.landAcquisition,
                                        {
                                            yes: language.yes,
                                            no: language.on_progress,
                                            nor: language.not_required,
                                        }
                                    )}
                                </ScrollArea>
                            </div>
                        </div>
                    </div>

                    {/* Filter Section for sector */}
                    <FilterMultiSection
                        label="Sectors"
                        title={language.sector}
                        toggleFilterOption={() => toggleFilterOption('showSectorsOption')}
                        showOption={showOptions.showSectorsOption}
                        placeholder="Type a Sector ..."
                        data={data.sectors}
                        handleSelect={props.handleSelect}
                        selectedVal={filterValues.sector}
                        filterLabel={'sector'}
                        showSearch={false}
                        applied={props.applied}
                    />
                    {/* Filter Section for subsector */}
                    {subSectors().length > 0 && (
                        <FilterMultiSection
                            label="Sub Sectors"
                            title={language.sub_sector}
                            toggleFilterOption={() => toggleFilterOption('showSubSectorsOption')}
                            showOption={showOptions.showSubSectorsOption}
                            placeholder="Type a Sub Sector ..."
                            data={subSectors()}
                            handleSelect={props.handleSelect}
                            selectedVal={filterValues.subSector}
                            filterLabel={'subSector'}
                            showSearch={false}
                            applied={props.applied}
                        />
                    )}
                    {/* Filter Section for Province */}
                    {/* <FilterMultiSection
            label="Provinces"
            title={language.province}
            toggleFilterOption={() => toggleFilterOption('showProvinceOption')}
            showOption={showOptions.showProvinceOption}
            placeholder="Type a Province name ..."
            data={data.provinces}
            handleSelect={props.handleSelect}
            selectedVal={filterValues.province}
            filterLabel={'province'}
            showSearch={false}
            applied={props.applied}
          /> */}
                    {/* Filter Section for districts */}
                    {districts().length > 0 && (
                        <FilterMultiSection
                            label="Districts"
                            title={language.district}
                            toggleFilterOption={() => toggleFilterOption('showDistrictOption')}
                            showOption={showOptions.showDistrictOption}
                            placeholder="Type a district name ..."
                            data={districts()}
                            handleSelect={props.handleSelect}
                            selectedVal={filterValues.district}
                            filterLabel={'district'}
                            showSearch={true}
                            applied={props.applied}
                        />
                    )}
                    {/* Filter Section for Year-on-year */}
                    <FilterSection
                        title={language.is_yearly_funding}
                        toggleFilterOption={() => toggleFilterOption('showYearOnYearOption')}
                        showOption={showOptions.showYearOnYearOption}
                        placeholder="Type a year-on-year ..."
                        data={yearOnYearProject}
                        handleSelect={props.handleChange}
                        selectedVal={filterValues.isYearlyFunding}
                        filterLabel={'isYearlyFunding'}
                    />
                    {/* Filter Section for Moderation status */}
                    <FilterSection
                        title={language.status}
                        toggleFilterOption={() => toggleFilterOption('showModerationStatusOption')}
                        showOption={showOptions.showModerationStatusOption}
                        placeholder="Type a moderation status ..."
                        data={status}
                        handleSelect={props.handleChange}
                        selectedVal={filterValues.moderationStatus}
                        filterLabel={'moderationStatus'}
                    />
                    <div className="filter-section">
                        <div
                            className="header"
                            onClick={() => toggleFilterOption('showProjectDateOption')}
                        >
                            <p className="title">{language.project_date}</p>
                            <div className="collapse">
                                <i className="material-icons md-bold md-blue">{`${
                                    showOptions.showProjectDateOption ? 'remove' : 'add'
                                }`}</i>
                            </div>
                        </div>
                        <div
                            className={`contents ${
                                showOptions.showProjectDateOption ? '' : 'hide'
                            }`}
                        >
                            <div className="options">
                                <div className="option-form">
                                    <label>From</label>
                                    <Input
                                        type="date"
                                        id="projectStartDateAD"
                                        onChange={(e) =>
                                            props.handleDateChange(
                                                'projectStartDateFrom',
                                                e.target.value
                                            )
                                        }
                                        value={filterValues.projectStartDateFrom}
                                    />
                                </div>
                                <div className="option-form">
                                    <label>To</label>
                                    <Input
                                        type="date"
                                        id="projectEndDateAD"
                                        onChange={(e) =>
                                            props.handleDateChange(
                                                'projectStartDateTo',
                                                e.target.value
                                            )
                                        }
                                        value={filterValues.projectStartDateTo}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="filter-section">
                        <div className="header" onClick={() => toggleFilterOption('showAIEOption')}>
                            <p className="title">{language.original_cost_estimate}</p>
                            <div className="collapse">
                                <i className="material-icons md-bold md-blue">{`${
                                    showOptions.showAIEOption ? 'remove' : 'add'
                                }`}</i>
                            </div>
                        </div>
                        <div className={`contents ${showOptions.showAIEOption ? '' : 'hide'}`}>
                            <div className="option-form">
                                <label>From</label>
                                <Input
                                    placeholder="From: Project cost"
                                    type="text"
                                    id="projectStartDateAD"
                                    onChange={(e) =>
                                        props.handleDateChange('projectCostFrom', e.target.value)
                                    }
                                    value={filterValues.projectCostFrom || ''}
                                    width={4}
                                />
                            </div>
                            <div className="option-form">
                                <label>To</label>
                                <Input
                                    placeholder="To: Project cost"
                                    type="text"
                                    id="projectEndDateAD"
                                    onChange={(e) =>
                                        props.handleDateChange('projectCostTo', e.target.value)
                                    }
                                    value={filterValues.projectCostTo || ''}
                                    width={4}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button
                className="btn-normal btn-blue btn-shadow--blue btn-transition btn-filter"
                onClick={toggleSidebar}
            >
                <i className="material-icons md-18 md-bold mr-6">filter_list</i>
                {language.filter}
            </button>
        </div>
    );
};

export default Filter;
