import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import ProgramApi from '../../services/Program';

import { localization } from '../../localization';

const AddModal = ({
    isModelOpen,
    onCloseModel,
    setModalOpen,
    fetchProgram,
    setData,
    ministryId,
}) => {
    const selectedLanguage = useSelector((state) => state.localization.selectedLanguage);
    const lang = localization[selectedLanguage].project_boarding_page;
    const [programError, setProgramError] = useState(false);
    const [program, setProgram] = useState({
        name: '',
        program_budget_code: '',
        program_sub_budget_code: '',
    });

    const createProgram = (e) => {
        e.preventDefault();
        if (!program.name && program.name.trim() === '') {
            setProgramError(true);
            return;
        }

        if (program.name && ministryId) {
            ProgramApi.createProgram(
                {
                    name: program.name.trim().replace(/\s{2,}/g, ' '),
                    program_budget_code: program.program_budget_code.trim().replace(/\s{2,}/g, ' '),
                    program_sub_budget_code: program.program_sub_budget_code
                        .trim()
                        .replace(/\s{2,}/g, ' '),
                },
                { ministryId }
            )
                .then((response) => {
                    if (response.success) {
                        setData({
                            selected: {
                                label: response.data.program.name,
                                value: response.data.program.id,
                            },
                            programBudgetCode: response.data.program.program_budget_code,
                            programId: response.data.program.id,
                        });
                        fetchProgram({ ministryId });
                        setModalOpen(!isModelOpen);
                    } else {
                        toastr.error('', response.message);
                        setProgramError(false);
                        setProgram({
                            name: '',
                            program_budget_code: '',
                            program_sub_budget_code: '',
                        });
                        setModalOpen(!isModelOpen);
                    }
                })
                .catch((err) => {
                    toastr.error('', `${err.message}`);
                });
        } else {
            toastr.error('', `Please select ministry`);
            setProgramError(false);
            setProgram({
                name: '',
                program_budget_code: '',
                program_sub_budget_code: '',
            });
            setModalOpen(!isModelOpen);
        }
    };

    return (
        <Modal dimmer="blurring" open={isModelOpen} onClose={onCloseModel} size="mini">
            <Modal.Header>{lang.create_new_program}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group grouped>
                        <Form.Field
                            label={lang.enter_program_name}
                            control={Input}
                            onChange={(e) => setProgram({ ...program, name: e.target.value })}
                            required
                            className={programError ? 'error' : ''}
                        />
                        <Form.Field
                            label={lang.enter_program_budget_code}
                            control={Input}
                            onChange={(e) =>
                                setProgram({ ...program, program_budget_code: e.target.value })
                            }
                        />
                        <Form.Field
                            label={lang.enter_program_sub_budget_code}
                            control={Input}
                            onChange={(e) =>
                                setProgram({ ...program, program_sub_budget_code: e.target.value })
                            }
                        />
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={onCloseModel}>
                    {lang.cancel}
                </Button>
                <Button positive content={lang.create} onClick={createProgram} />
            </Modal.Actions>
        </Modal>
    );
};

export default AddModal;
