import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Grid } from 'semantic-ui-react';
import { get } from 'lodash';

import { auth } from '../../../helpers';
import { getFileFromCloud } from '../../../actions/index';

import { localization } from '../../../localization';

const ProjectPrioritizationSection = ({ gate }) => {
    const language = useSelector(auth.getSelectedLanguage);
    const lang = localization[language]['prioritization_score_view'];
    const projectInfo = useSelector((state) => state.project.projectInfo);
    const [projectData, setProjectData] = useState();
    const [isDownloading, setIsDownloading] = useState({});

    const downloadUI = (fileName) => {
        return (
            <label className="btn-outline btn-outline--blue">
                {isDownloading[fileName] ? (
                    <i className="hourglass outline icon loading"></i>
                ) : (
                    <i className="material-icons md-12 mr-2 icon-image-preview">cloud_download</i>
                )}
                (Download)
            </label>
        );
    };

    useEffect(() => {
        setProjectData(projectInfo[gate]);
    }, [gate, projectInfo]);

    const downloadFile = async (fileName) => {
        setIsDownloading({ ...isDownloading, [fileName]: true });
        await getFileFromCloud(projectData.gate_id, fileName);
        setIsDownloading({ ...isDownloading, [fileName]: false });
    };

    return (
        <div className="project-section" id="project-prioritization" name="project-prioritization">
            <div className="header medium">{lang.section_name}</div>
            <Tab.Pane>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <p>{lang.contribution_in_achievement_of_economic} :</p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="project-info">
                                {get(projectData, 'project_prioritization_score.economicGrowth')}
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <p>{lang.contribution_in_achievement_of_sdg} :</p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="project-info">
                                {get(projectData, 'project_prioritization_score.achievementOfSDG')}
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <p>{lang.level_of_participation} :</p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="project-info">
                                {get(
                                    projectData,
                                    'project_prioritization_score.participationLevel'
                                )}
                            </p>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={5}>
                            <p>{lang.contribution_to_sectoral} :</p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="project-info">
                                {get(
                                    projectData,
                                    'project_prioritization_score.sectoralInclusiveness'
                                )}
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <p>{lang.contribution_to_social} :</p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="project-info">
                                {get(
                                    projectData,
                                    'project_prioritization_score.socialInclusiveness'
                                )}
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <p>{lang.contribution_to_sectoral_goal} :</p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="project-info">
                                {get(projectData, 'project_prioritization_score.sectoralGoal')}
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <p>{lang.contribution_in_achievement_of_sectoral_sdg} :</p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="project-info">
                                {get(
                                    projectData,
                                    'project_prioritization_score.achievementOfSectoralSDG'
                                )}
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <p>{lang.project_prioritization_excel_sheet} :</p>
                        </Grid.Column>
                        <Grid.Column>
                            <div className="project-info file-download">
                                {projectData &&
                                    projectData.project_prioritization_score &&
                                    projectData.project_prioritization_score.projectPrioritizationFile.map(
                                        (item, index) => {
                                            return (
                                                Object.keys(item).length !== 0 &&
                                                !item.isDeleted && (
                                                    <p key={index}>
                                                        {item.originalName
                                                            ? item.originalName
                                                            : item.fileName}
                                                        {item.fileName.length > 0 && (
                                                            <>
                                                                {item.fileSize ? (
                                                                    <span>({item.fileSize})</span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                <button
                                                                    className="btn-download"
                                                                    type="button"
                                                                    onClick={() =>
                                                                        downloadFile(
                                                                            item.cloudFileName
                                                                        )
                                                                    }
                                                                >
                                                                    {downloadUI(item.cloudFileName)}
                                                                </button>
                                                            </>
                                                        )}
                                                    </p>
                                                )
                                            );
                                        }
                                    )}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <p>{lang.file_description} :</p>
                        </Grid.Column>
                        <Grid.Column>
                            <div className="project-info file-download">
                                {projectData &&
                                    projectData.project_prioritization_score &&
                                    projectData.project_prioritization_score.projectPrioritizationFile.map(
                                        (item, index) => {
                                            return (
                                                Object.keys(item).length !== 0 &&
                                                !item.isDeleted && (
                                                    <p key={index}>{item.fileDescription}</p>
                                                )
                                            );
                                        }
                                    )}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <p>{lang.prioritization_score} :</p>
                        </Grid.Column>
                        <Grid.Column>
                            <p className="project-info">
                                {get(projectData, 'project_prioritization_score.totalPercentage')} %
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Tab.Pane>
        </div>
    );
};

export default ProjectPrioritizationSection;
