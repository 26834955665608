import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Search } from 'semantic-ui-react';
import { filter, escapeRegExp, debounce } from 'lodash';
import { toastr } from 'react-redux-toastr';

// import { auth } from '../../../helpers';
// import { localization } from '../../../localization';
import UserApi from '../../../services/User';

import ProjectApi from '../../../services/Gate/Project';

const AuthorSection = ({ projectId, getProject }) => {
  // const language = useSelector(auth.getSelectedLanguage);
  // const lang = localization[language]['author_view'];
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const [userList, setUserList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('');
  const [results, setResults] = useState([]);
  const [authorId, setAuthorId] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (projectId) {
      getUserList();
    }
  }, [projectId, projectInfo]);

  const getUserList = () => {
    UserApi.listUsersByMinistry({ ministryId: projectInfo.ministry_id })
      .then((response) => {
        if (response.success) {
          let users = response.data.users.map((user) => {
            return {
              title: user.username,
              id: user.id,
            };
          });
          setResults(users);
          setValue(projectInfo.user.username);
          setUserList(users);
        } else {
          toastr.error('', `${response.error.message}`);
          return true;
        }
      })
      .catch((err) => {
        console.log('----error------', err);
      });
  };

  const handleResultSelect = (e, { result }) => {
    setError(false);
    setValue(result.title);
    setAuthorId(result.id);
  };

  const handleSearchChange = (e, { value }) => {
    let resultArr = [];
    setError(false);
    setValue(value);
    setIsLoading(true);
    setTimeout(() => {
      if (value.length < 1) {
        return setValue(''), setResults([]), setIsLoading(false);
      }
      const re = new RegExp(escapeRegExp(value), 'i');
      const isMatch = (result) => re.test(result.title);
      setIsLoading(false);
      resultArr = filter(userList, isMatch);
      setResults(filter(userList, isMatch));
      let id =
        resultArr.length === 1
          ? resultArr[0].id
          : resultArr.length > 1 &&
            resultArr.filter((result) => value.trim() === result.title.trim()).length === 1 &&
            resultArr.filter((result) => value.trim() === result.title.trim())[0].id;
      setAuthorId(id);
    }, 300);
  };

  const changeAuthor = () => {
    let validUser = userList.filter(
      (user) => user.id === authorId && value.trim() === user.title.trim()
    );
    let canSubmit = true;
    if (value.trim() === '') {
      canSubmit = false;
      setError(true);
    }
    if (validUser.length < 1) {
      canSubmit = false;
      setError(true);
    }
    if (canSubmit) {
      let data = { creator_id: authorId };
      ProjectApi.updateCreatorOfProject(data, {
        projectId,
      })
        .then((response) => {
          if (response.success) {
            getProject();
            setError(false);
            setAuthorId();
            toastr.success('', `${response.message}`);
          } else {
            toastr.error('', `${response.error.message}`);
          }
        })
        .catch((err) => console.log('---error--', err));
    }
  };

  return (
    <div name="contact">
      <div className="project-section" id="contact">
        {/* <div className="header medium">{lang.section_name}</div> */}
        {/* <Tab.Pane> */}
        <div>
          <div className={error ? 'error' : ''}>
            <Search
              loading={isLoading}
              onResultSelect={handleResultSelect}
              onSearchChange={debounce(handleSearchChange, 500, {
                leading: true,
              })}
              results={results}
              value={value}
              // {...this.props}
              className={error ? 'error' : ''}
            />
          </div>
          <div className="accordion-button">
            <button
              type="submit"
              onClick={changeAuthor}
              className="btn-rect btn-rect--small btn-blue"
            >
              Change Author
            </button>
          </div>
        </div>
        {/* </Tab.Pane> */}
      </div>
    </div>
  );
};

export default AuthorSection;
