import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Radio } from "semantic-ui-react";
import { toastr } from "react-redux-toastr";
import { cloneDeep } from "lodash";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import FileUpload from "../FormType/FileUpload/index";

import {
  handleEditorChange,
  handleInputChange,
  handleRadioChange
} from "../../../actions";
import { fetched , lists } from '../../../reducers/form/Assessment/action';

import ProjectApi from '../../../services/Gate/Project';
// import { localization } from '../../../localization';

const Assessment = ({ gate, projectId, getProject}) => {
  const dispatch = useDispatch();

  const language = useSelector((state) => state.localization.selectedLanguage);

  let { assessment } = useSelector((state) => state);

  const [ disabled, setDisabled ] = useState(false);

  const [errors, setError] = useState({
    development_strategy_error: false,
    developmental_impacts_error: false,
    economically_viable_error: false,
    financially_affordable_error: false,
    institutional_capacity_error: false,
    technology_appropriate_error: false,
    note_error: false,
    action_error: false
  });

  useEffect(() => {

  }, []);


  const handleCreate = (e, action) => {
    e.preventDefault();
    assessment.action = action;

    const data = cloneDeep(assessment);

    let canSubmit = true;
    const errors = {};

    const requiredField = [
      'development_strategy',
      'developmental_impacts',
      'economically_viable',
      'financially_affordable',
      'institutional_capacity',
      'technology_appropriate',
      'action'
    ];

    for (let key in data) {
      if (requiredField.includes(key) && data[key] === '') {
        canSubmit = false;
        errors[`${key}_error`] = true;
        setDisabled(false);
      }

      if(key === 'note'){
        if(data.note.text.trim() === ''){
          canSubmit = false;
          errors[`${key}_error`] = true;
          setDisabled(false);
        }
      }
    }

    setError(errors);

    assessment.projectId = projectId;
    assessment.stage =
      gate === 'project_identification'
        ? 'identification'
        : gate === 'project_appraisal'
        ? 'appraisal'
        : '';

    if (canSubmit) {
      const obj = {...assessment,
        development_strategy: "",
        development_strategy_remarks: "",
        developmental_impacts: "",
        developmental_impacts_remarks: "",
        economically_viable: "",
        economically_viable_remarks: "",
        financially_affordable: "",
        financially_affordable_remarks: "",
        institutional_capacity: "",
        institutional_capacity_remarks: "",
        technology_appropriate: "",
        technology_appropriate_remarks: "",
        other: "",
        action: "",
        note : {
          text: "",
          documentFile: [{ fileName: '', fileDescription: '', fileSize: "", originalName: "" }]
        }};

      ProjectApi.assessment(assessment)
        .then((response) => {
          if (response.success) {
            getProject();
            dispatch(fetched(obj));
            dispatch(lists(response));
            toastr.success('', `Assessment sent successfully!`);
            setDisabled(false);
          } else {
            setDisabled(false);
            throw new Error(response.error.message);
          }
        })
        .catch((err) => {
          setDisabled(false);
          toastr.error('', `${err.message}`);
        });
    }
  };

  return (
    <>
      <Form className="form-new no-print" id="assessment">
        <div className="form-group-container form-group-assessment">
          <h3 className="form-heading">Assessment</h3>
          <Form.Group>
            <Form.Field width={8}>
              <Form.Field
                className={errors.development_strategy_error ? 'error' : ''}
                required
                label="Is the proposed project aligned with the government development strategy?"
              />
              <Form.Group inline>
                <Form.Field
                  label="Yes"
                  control={Radio}
                  name="development_strategy"
                  value="Yes"
                  checked={assessment.development_strategy === 'Yes'}
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: assessment,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                    setError({...errors , development_strategy_error: false })
                  }}
                />
                <Form.Field
                  label="No"
                  control={Radio}
                  name="development_strategy"
                  value="No"
                  checked={assessment.development_strategy === 'No'}
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: assessment,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                    setError({...errors , development_strategy_error: false })
                  }}
                />
              </Form.Group>
            </Form.Field>
            <Form.Field
              control={Input}
              name="development_strategy_remarks"
              width={8}
              className="field-align-bottom"
              label="Remarks"
              value={assessment.development_strategy_remarks}
              onChange={(event) => {
                dispatch(
                  handleInputChange(
                    {
                      event,
                      info: assessment,
                    },
                    fetched
                  )
                );
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={8}>
              <Form.Field
                className={errors.developmental_impacts_error ? 'error' : ''}
                required
                label="Does it have the intended developmental impacts?" />
              <Form.Group inline>
                <Form.Field
                  label="Yes"
                  control={Radio}
                  name="developmental_impacts"
                  value="Yes"
                  checked={assessment.developmental_impacts === 'Yes'}
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: assessment,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                    setError({...errors, developmental_impacts_error:false});
                  }}
                />
                <Form.Field
                  label="No"
                  control={Radio}
                  name="developmental_impacts"
                  value="No"
                  checked={assessment.developmental_impacts === 'No'}
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: assessment,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                    setError({...errors, developmental_impacts_error:false});
                  }}
                />
              </Form.Group>
            </Form.Field>
            <Form.Field
              control={Input}
              name="developmental_impacts_remarks"
              width={8}
              className="field-align-bottom"
              label="Remarks"
              value={assessment.developmental_impacts_remarks}
              onChange={(event) => {
                dispatch(
                  handleInputChange(
                    {
                      event,
                      info: assessment,
                    },
                    fetched
                  )
                );
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={8}>
              <Form.Field
                className={errors.economically_viable_error ? 'error' : ''}
                required
                label="Is the project economically viable?" />
              <Form.Group inline>
                <Form.Field
                  label="Yes"
                  control={Radio}
                  name="economically_viable"
                  value="Yes"
                  checked={assessment.economically_viable === 'Yes'}
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: assessment,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                    setError({...errors, economically_viable_error: false });
                  }}
                />
                <Form.Field
                  label="No"
                  control={Radio}
                  name="economically_viable"
                  value="No"
                  checked={assessment.economically_viable === 'No'}
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: assessment,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                    setError({...errors, economically_viable_error: false });
                  }}
                />
              </Form.Group>
            </Form.Field>
            <Form.Field
              control={Input}
              name="economically_viable_remarks"
              width={8}
              className="field-align-bottom"
              label="Remarks"
              value={assessment.economically_viable_remarks}
              onChange={(event) => {
                dispatch(
                  handleInputChange(
                    {
                      event,
                      info: assessment,
                    },
                    fetched
                  )
                );
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={8}>
              <Form.Field className={errors.financially_affordable_error ? 'error' : ''}
                          required label="Is the proposed project financially affordable?" />
              <Form.Group inline>
                <Form.Field
                  label="Yes"
                  control={Radio}
                  name="financially_affordable"
                  value="Yes"
                  checked={assessment.financially_affordable === 'Yes'}
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: assessment,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                    setError({...errors, financially_affordable_error: false});
                  }}

                />
                <Form.Field
                  label="No"
                  control={Radio}
                  name="financially_affordable"
                  value="No"
                  checked={assessment.financially_affordable === 'No'}
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: assessment,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                    setError({...errors, financially_affordable_error: false});
                  }}
                />
              </Form.Group>
            </Form.Field>
            <Form.Field
              control={Input}
              name="financially_affordable_remarks"
              width={8}
              className="field-align-bottom"
              label="Remarks"
              value={assessment.financially_affordable_remarks}
              onChange={(event) => {
                dispatch(
                  handleInputChange(
                    {
                      event,
                      info: assessment,
                    },
                    fetched
                  )
                );
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={8}>
              <Form.Field className={errors.institutional_capacity_error ? 'error' : ''}
                          required label="Is there institutional capacity to execute the project?" />
              <Form.Group inline>
                <Form.Field
                  label="Yes"
                  control={Radio}
                  name="institutional_capacity"
                  value="Yes"
                  checked={assessment.institutional_capacity === 'Yes'}
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: assessment,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                    setError({...errors, institutional_capacity_error: false});
                  }}
                />
                <Form.Field
                  label="No"
                  control={Radio}
                  name="institutional_capacity"
                  value="No"
                  checked={assessment.institutional_capacity === 'No'}
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: assessment,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                    setError({...errors, institutional_capacity_error: false});
                  }}
                />
              </Form.Group>
            </Form.Field>
            <Form.Field
              control={Input}
              name="institutional_capacity_remarks"
              width={8}
              className="field-align-bottom"
              label="Remarks"
              value={assessment.institutional_capacity_remarks}
              onChange={(event) => {
                dispatch(
                  handleInputChange(
                    {
                      event,
                      info: assessment,
                    },
                    fetched
                  )
                );
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={8}>
              <Form.Field className={errors.technology_appropriate_error ? 'error' : ''}
                          required label="Is the chosen technology appropriate?" />
              <Form.Group inline>
                <Form.Field
                  label="Yes"
                  control={Radio}
                  name="technology_appropriate"
                  value="Yes"
                  checked={assessment.technology_appropriate === 'Yes'}
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: assessment,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                    setError({...errors, technology_appropriate_error:false });
                  }}
                />
                <Form.Field
                  label="No"
                  control={Radio}
                  name="technology_appropriate"
                  value="No"
                  checked={assessment.technology_appropriate === 'No'}
                  onChange={(event, { value, name }) => {
                    dispatch(
                      handleRadioChange(
                        {
                          info: assessment,
                          value,
                          name,
                        },
                        fetched
                      )
                    );
                    setError({...errors, technology_appropriate_error:false });
                  }}
                />
              </Form.Group>
            </Form.Field>
            <Form.Field
              control={Input}
              name="technology_appropriate_remarks"
              width={8}
              className="field-align-bottom"
              label="Remarks"
              value={assessment.technology_appropriate_remarks}
              onChange={(event) => {
                dispatch(
                  handleInputChange(
                    {
                      event,
                      info: assessment,
                    },
                    fetched
                  )
                );
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Field width={8}>
              <label>Other</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={assessment.other}
                    onBlur={(event, editor) => {
                      const fieldData = editor.getData();
                      dispatch(handleEditorChange({ name: 'other', formInfo: assessment, field: false, fieldData}, fetched));
                    }}
                    config={{
                      toolbar: [
                        'Heading',
                        'bold',
                        'italic',
                        'bulletedList',
                        'numberedList',
                        'blockQuote',
                        'link',
                        'insertTable',
                      ],
                    }}
                  />
            </Form.Field>
            <Form.Field className={errors.note_error ? 'error' : ''} width={8}
                        required>
              <label>Note</label>
              <CKEditor
                editor={ClassicEditor}
                data={assessment.note.text}

                onBlur={(event, editor) => {
                  const fieldData = editor.getData();
                  dispatch(handleEditorChange({ name: 'text', formInfo: assessment, field: 'note', fieldData}, fetched));
                  setError({...errors, note_error: false });
                }}
                config={{
                  toolbar: [
                    'Heading',
                    'bold',
                    'italic',
                    'bulletedList',
                    'numberedList',
                    'blockQuote',
                    'link',
                    'insertTable',
                  ],
                }}
              />
            </Form.Field>
          </Form.Group>

          <FileUpload
            formName="assessment"
            label="upload_documents"
            field="note"
            fileData={assessment.note.documentFile}
            name="documentFile"
            form={assessment}
            fetched={fetched}
            projectId={projectId}
          />

          <div className="form-actions flex justify-between">
            <div>
              <button className="btn-rect btn-rect--small btn-blue" onClick={(e) => {
                  setDisabled(true);
                  handleCreate(e,'approved')
                }}
                disabled={disabled}
              >
                Approved
              </button>
              <button className="btn-rect btn-rect--small btn-dark" onClick={(e) => {
                  setDisabled(true);
                  handleCreate(e, 'revision')
                }}
                disabled={disabled}
              >
                Send to revision
              </button>
            </div>
            {
              gate === 'project_identification' && (
                <button className="btn-rect btn-rect--small btn-red" onClick={(e) => {
                    setDisabled(true);
                    handleCreate(e, 'reject')
                  }}
                  disabled={disabled}
                >
                  Reject this project
                </button>
              )
            }

          </div>
        </div>
      </Form>
    </>
  );
};

export default Assessment;
