import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Form, Grid, Header } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import 'react-circular-progressbar/dist/styles.css';
import { useUrlSearchParams } from 'use-url-search-params';

import DepartmentService from '../../services/Department';
import MinistryService from '../../services/Ministry';
import { auth } from '../../helpers';

import Can from '../../components/Can/can';

import PageNotFound from '../PageNotFound';

import { localization } from '../../localization';

const AddDepartment = ({ history }) => {
    const language = useSelector(auth.getSelectedLanguage);
    const lang = localization[language].department;

    const [departmentDetail, setDepartmentDetail] = useState({
        name: '',
        ministry_id: 0,
        code: null,
    });

    const [errObj, setValidationError] = useState({
        name: false,
        ministry: false,
    });

    const [params] = useUrlSearchParams();

    const [ministries, setMinistries] = useState([]);

    const user = useSelector(auth.getCurrentUser);

    useEffect(() => {
        if (params && params.departmentId) {
            getDepartmentDetail();
        }

        if (user.role === 'npcAdmin') {
            listMinistries();
        } else {
            setDepartmentDetail({ ...departmentDetail, ministry_id: user.ministry.id });
        }
    }, [params, user]);

    const getDepartmentDetail = () => {
        DepartmentService.getDepartment(params)
            .then((response) => {
                if (response.success) {
                    const d = response.data.department;
                    setDepartmentDetail({
                        ...departmentDetail,
                        name: d.name,
                        ministry_id: d.ministry_id,
                        code: d.code,
                    });
                } else {
                    throw new Error(response.error.message);
                }
            })
            .catch((err) => {
                history.push('/departments');
                toastr.error('', `${err.message}`);
            });
    };

    const listMinistries = () => {
        MinistryService.listAllMinistries(`?isDashboard=true&`)
            .then((response) => {
                const ministriesArr = [];
                response.data.ministries.lists.forEach((m) => {
                    ministriesArr.push({ key: m.id, text: m.name, value: m.id });
                });
                setMinistries(ministriesArr);
            })
            .catch((err) => {
                toastr.error('', `${err.message}`);
            });
    };

    const departmentAction = () => {
        let sendUser = true;
        const obj = { ...errObj };

        if (departmentDetail.name.trim() === '') {
            obj.name = true;
            sendUser = false;
        }

        if (departmentDetail.ministry_id <= 0) {
            obj.ministry = true;
            sendUser = false;
        }

        setValidationError(obj);

        if (sendUser) {
            if (params && params.departmentId) {
                DepartmentService.updateDepartment(
                    { departmentId: params.departmentId },
                    departmentDetail
                )
                    .then((response) => {
                        if (response.success) {
                            toastr.success('', `${response.message}`);
                            history.push('/departments');
                        } else {
                            throw new Error(response.error.message);
                        }
                    })
                    .catch((err) => {
                        toastr.error('', `${err.message}`);
                    });
            } else {
                DepartmentService.createDepartment(departmentDetail)
                    .then((response) => {
                        if (response.success) {
                            toastr.success('', `${response.message}`);
                            history.push('/departments');
                        } else {
                            throw new Error(response.error.message);
                        }
                    })
                    .catch((err) => {
                        toastr.error('', `${err.message}`);
                    });
            }
        }
    };

    return (
        <Can
            role={user.role}
            perform="department:create-edit-delete"
            yes={() => (
                <>
                    <Grid columns="equal">
                        {/* <Grid.Column width={3} className="sidebar">
            <SideBar/>
          </Grid.Column> */}

                        <Grid.Column className="form-container">
                            <Container text className="add-project-form">
                                {params && params.departmentId ? (
                                    <Header size="medium">{lang.edit_department}</Header>
                                ) : (
                                    <Header size="medium">{lang.add_department}</Header>
                                )}
                                <Form>
                                    <Form.Group>
                                        <Form.Field required width={10}>
                                            <label>{lang.department_name} </label>
                                            <Form.Input
                                                value={departmentDetail.name}
                                                onChange={(e) => {
                                                    setDepartmentDetail({
                                                        ...departmentDetail,
                                                        name: e.target.value,
                                                    });
                                                    setValidationError({ ...errObj, name: false });
                                                }}
                                                error={
                                                    errObj.name
                                                        ? {
                                                              content:
                                                                  lang.department_name_required,
                                                              pointing: 'below',
                                                          }
                                                        : null
                                                }
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Field width={10}>
                                            <label>{lang.department_code} </label>
                                            <Form.Input
                                                value={departmentDetail.code}
                                                onChange={(e) => {
                                                    setDepartmentDetail({
                                                        ...departmentDetail,
                                                        code: e.target.value,
                                                    });
                                                }}
                                            />
                                        </Form.Field>
                                    </Form.Group>

                                    <Form.Group>
                                        {user.role !== 'npcAdmin' ? (
                                            <Form.Field width={8} required>
                                                <label>{lang.ministry}</label>
                                                <input
                                                    value={
                                                        user.ministry
                                                            ? user.ministry.name
                                                            : lang.no_ministry_associated
                                                    }
                                                    disabled
                                                />
                                            </Form.Field>
                                        ) : (
                                            <Form.Field width={8} required>
                                                <label>{lang.ministry}</label>
                                                <Form.Field>
                                                    <Form.Select
                                                        options={ministries}
                                                        value={departmentDetail.ministry_id}
                                                        onChange={(e, data) => {
                                                            setDepartmentDetail({
                                                                ...departmentDetail,
                                                                ministry_id: data.value,
                                                            });
                                                            setValidationError({
                                                                ...errObj,
                                                                ministry: false,
                                                            });
                                                        }}
                                                        error={
                                                            errObj.ministry
                                                                ? {
                                                                      content:
                                                                          lang.ministry_required,
                                                                      pointing: 'below',
                                                                  }
                                                                : null
                                                        }
                                                        search
                                                    />
                                                </Form.Field>
                                            </Form.Field>
                                        )}
                                    </Form.Group>

                                    <Button primary onClick={departmentAction}>
                                        Save
                                    </Button>

                                    <Button basic onClick={() => history.push('/departments')}>
                                        Cancel
                                    </Button>
                                </Form>
                            </Container>
                        </Grid.Column>
                    </Grid>
                </>
            )}
            no={() => <PageNotFound message={`You can't access this interface`} />}
        />
    );
};

export default withRouter(AddDepartment);
