import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';

import { localization } from '../../../../localization';

import {
  handleInputChange,
  handleSaveAndExit,
  handleSelectChange,
  addRepeaterField,
  handleRepeaterChange,
  removeRepeaterField,
} from '../../../../actions';
import { fetched, fetchedNullData } from '../../../../reducers/form/Modality/action';
import * as ProjectAction from '../../../../reducers/form/action';
import LoaderNP from '../../../../components/Loader/Loader';

const Modality = ({ urlParams }) => {
  const language = useSelector((state) => state.localization.selectedLanguage);
  const projectSaveToDbLoader = useSelector((state)=>state.project.projectSaveToDbLoader);
  const lang = localization[language]['modality'];
  const { modality } = useSelector((state) => state);

  const [errors, setError] = useState({
    projectImplementationModalType_error: false,
  });

  const dispatch = useDispatch();

  const { projectInfo } = useSelector((state) => state.projectBasicInfo);

  useEffect(() => {
    if (projectInfo.project_implementation_modality) {
      dispatch(
        fetched({
          ...projectInfo.project_implementation_modality,
        })
      );
    }
    if (!projectInfo.project_implementation_modality) {
      dispatch(fetchedNullData());
    }
  }, [projectInfo]);

  const handleSaveAndContinue = (type) => {
    let canSubmit = true;
    const errors = [];

    const data = cloneDeep(modality);
    delete data['step'];

    const requiredFields = ['projectImplementationModalType'];

    for (let d in data) {
      if (requiredFields.includes(d) && (data[d] === '' || data[d].length === 0)) {
        canSubmit = false;
        errors[`${d}_error`] = true;
      }
    }
    setError(errors);
    if (canSubmit) {
      handleSaveAndExit({ project_implementation_modality: data }, type, dispatch, 8, urlParams);
    }
  };

  const modalityList = [
    { text: lang.option_public_gon, value: 'publicGON', key: 'publicGON' },
    { text: lang.option_ppp, value: 'ppp', key: 'ppp' },
    { text: lang.option_private, value: 'private', key: 'private' },
    { text: lang.option_other, value: 'other', key: 'other' },
  ];

  const privateType = [
    { text: lang.option_domestic, value: 'domestic', key: 'domestic' },
    { text: lang.option_foreign, value: 'foreign', key: 'foreign' },
    { text: lang.option_both, value: 'both', key: 'both' },
  ];

  const typePPP = [
    { text: 'BT', value: 'bt', key: 'bt' },
    { text: 'BOOT', value: 'boot', key: 'boot' },
    { text: 'BOT', value: 'bot', key: 'bot' },
    { text: 'EPC & F', value: 'epc', key: 'epc' },
    { text: 'Other', value: 'other', key: 'other' },
  ];

  //## Using self-invocation function inline because the input would lose focus when typing.

  // const ConditionalModality = () => {
  //   switch (modality.projectImplementationModalType) {
  //     case 'publicGON':
  //       return <></>;
  //     case 'ppp':
  //       return (
  //         <>
  //           <Form.Group>
  //             <Form.Field
  //               control={Select}
  //               label={lang.select_ppp_type}
  //               options={typePPP}
  //               name="PPPType"
  //               value={modality.PPPType}
  //               onChange={(event, { value, name }) => {
  //                 dispatch(
  //                   handleSelectChange(
  //                     {
  //                       value,
  //                       name,
  //                       info: modality,
  //                     },
  //                     fetched
  //                   )
  //                 );
  //               }}
  //               width={8}
  //               placeholder="Select PPP type"
  //             />
  //           </Form.Group>
  //           {modality.PPPType === 'other' ? (
  //             <Form.Group>
  //               <Form.Field
  //                 label={lang.if_the_select_ppp_type_is_other}
  //                 control={Input}
  //                 type="text"
  //                 value={modality.otherPPPType}
  //                 name="otherPPPType"
  //                 onChange={(event) => {
  //                   dispatch(handleInputChange({ event, info: modality }, fetched));
  //                 }}
  //                 width={8}
  //               />
  //             </Form.Group>
  //           ) : (
  //             <></>
  //           )}
  //           {urlParams.stage !== 'identification' && (
  //             <div>
  //               {modality.PPPCompanyName.map((value, index) => {
  //                 return (
  //                   <div
  //                     className={index === 0 ? 'field-repeat--first' : 'field-repeat'}
  //                     key={`land${index}`}
  //                   >
  //                     <Form.Group>
  //                       <Form.Field
  //                         control={Input}
  //                         label={lang.company_name}
  //                         width={4}
  //                         name="companyName"
  //                         onChange={(event) => {
  //                           dispatch(
  //                             handleRepeaterChange(
  //                               {
  //                                 event,
  //                                 info: modality,
  //                                 field: 'PPPCompanyName',
  //                                 index: index,
  //                               },
  //                               fetched
  //                             )
  //                           );
  //                         }}
  //                         value={value.companyName || ''}
  //                       />
  //                     </Form.Group>
  //                     {index > 0 && (
  //                       <div
  //                         className=" btn-remove btn-outline btn-outline--small btn-outline--red"
  //                         onClick={(event) => {
  //                           dispatch(
  //                             removeRepeaterField(
  //                               {
  //                                 event,
  //                                 info: modality,
  //                                 field: 'PPPCompanyName',
  //                                 index: index,
  //                               },
  //                               fetched
  //                             )
  //                           );
  //                         }}
  //                       >
  //                         <i className="material-icons md-12 mr-2 material-delete">
  //                           delete_outline
  //                         </i>
  //                         Delete
  //                       </div>
  //                     )}
  //                   </div>
  //                 );
  //               })}
  //               <Form.Group className="field-repeat-btn">
  //                 <Form.Field>
  //                   <button
  //                     className="btn-text"
  //                     onClick={(event) => {
  //                       dispatch(
  //                         addRepeaterField(
  //                           {
  //                             event,
  //                             info: modality,
  //                             field: 'PPPCompanyName',
  //                           },
  //                           fetched
  //                         )
  //                       );
  //                     }}
  //                   >
  //                     + Add more
  //                   </button>
  //                 </Form.Field>
  //               </Form.Group>
  //             </div>
  //           )}
  //         </>
  //       );
  //     case 'private':
  //       return (
  //         <>
  //           <Form.Group>
  //             <Form.Field
  //               label={lang.select_private_type}
  //               control={Select}
  //               options={privateType}
  //               value={modality.privateType}
  //               name="privateType"
  //               onChange={(event, { value, name }) => {
  //                 dispatch(
  //                   handleSelectChange(
  //                     {
  //                       value,
  //                       name,
  //                       info: modality,
  //                     },
  //                     fetched
  //                   )
  //                 );
  //               }}
  //               width={8}
  //               placeholder="Select private type"
  //             />
  //           </Form.Group>
  //           {urlParams.stage !== 'identification' && (
  //             <div>
  //               {modality.privateCompanyName.map((value, index) => {
  //                 console.log(index)
  //                 return (
  //                   <div
  //                     className={index === 0 ? 'field-repeat--first' : 'field-repeat'}
  //                     key={`land${index}`}
  //                   >
  //                     <Form.Group>
  //                       <Form.Field
  //                         control={Input}
  //                         label={lang.company_name}
  //                         width={4}
  //                         name="companyName"
  //                         onChange={(event) => {
  //                           dispatch(
  //                             handleRepeaterChange(
  //                               {
  //                                 event,
  //                                 info: modality,
  //                                 field: 'privateCompanyName',
  //                                 index: index,
  //                               },
  //                               fetched
  //                             )
  //                           );
  //                         }}
  //                         value={value.companyName || ''}
  //                       />
  //                     </Form.Group>
  //                     {index > 0 && (
  //                       <div
  //                         className=" btn-remove btn-outline btn-outline--small btn-outline--red"
  //                         onClick={(event) => {
  //                           dispatch(
  //                             removeRepeaterField(
  //                               {
  //                                 event,
  //                                 info: modality,
  //                                 field: 'privateCompanyName',
  //                                 index: index,
  //                               },
  //                               fetched
  //                             )
  //                           );
  //                         }}
  //                       >
  //                         <i className="material-icons md-12 mr-2 material-delete">
  //                           delete_outline
  //                         </i>
  //                         Delete
  //                       </div>
  //                     )}
  //                   </div>
  //                 );
  //               })}
  //               <Form.Group className="field-repeat-btn">
  //                 <Form.Field>
  //                   <button
  //                     className="btn-text"
  //                     onClick={(event) => {
  //                       dispatch(
  //                         addRepeaterField(
  //                           {
  //                             event,
  //                             info: modality,
  //                             field: 'privateCompanyName',
  //                           },
  //                           fetched
  //                         )
  //                       );
  //                     }}
  //                   >
  //                     + Add more
  //                   </button>
  //                 </Form.Field>
  //               </Form.Group>
  //             </div>
  //           )}
  //         </>
  //       );
  //     case 'other':
  //       return (
  //         <Form.Group>
  //           <Form.Field
  //             control={Input}
  //             label={lang.if_the_project_implementation_modality_is_other}
  //             width={8}
  //             name="otherImplementationModal"
  //             id="otherImplementationModal"
  //             value={modality.otherImplementationModal}
  //             onChange={(event) => {
  //               dispatch(handleInputChange({ event, info: modality }, fetched));
  //             }}
  //           />
  //         </Form.Group>
  //       );
  //     default:
  //       return <></>;
  //   }
  // };

  return projectSaveToDbLoader ? <LoaderNP text={true} /> : (
    <Form className="form-new">
      <div className="form-group-container">
        <p className="form-info">{lang.form_description}</p>

        <Form.Group>
          <Form.Field
            required
            control={Select}
            label={lang.form_name}
            options={modalityList}
            className={errors.projectImplementationModalType_error ? 'error' : ''}
            name="projectImplementationModalType"
            width={8}
            value={modality.projectImplementationModalType}
            onChange={(event, { value, name }) => {
              dispatch(
                handleSelectChange(
                  {
                    value,
                    name,
                    info: modality,
                  },
                  fetched
                )
              );
              setError({ ...errors, projectImplementationModalType_error: false });
            }}
            placeholder="Select project implementation modality"
          />
        </Form.Group>

        {/* <ConditionalModality /> */}
        {(() => {
          switch (modality.projectImplementationModalType) {
            case 'publicGON':
              return <></>;
            case 'ppp':
              return (
                <>
                  <Form.Group>
                    <Form.Field
                      control={Select}
                      label={lang.select_ppp_type}
                      options={typePPP}
                      name="PPPType"
                      value={modality.PPPType}
                      onChange={(event, { value, name }) => {
                        dispatch(
                          handleSelectChange(
                            {
                              value,
                              name,
                              info: modality,
                            },
                            fetched
                          )
                        );
                      }}
                      width={8}
                      placeholder="Select PPP type"
                    />
                  </Form.Group>
                  {modality.PPPType === 'other' ? (
                    <Form.Group>
                      <Form.Field
                        label={lang.if_the_select_ppp_type_is_other}
                        control={Input}
                        type="text"
                        value={modality.otherPPPType}
                        name="otherPPPType"
                        onChange={(event) => {
                          dispatch(handleInputChange({ event, info: modality }, fetched));
                        }}
                        width={8}
                      />
                    </Form.Group>
                  ) : (
                    <></>
                  )}
                  {urlParams.stage !== 'identification' && (
                    <div>
                      {modality.PPPCompanyName.map((value, index) => {
                        return (
                          <div
                            className={index === 0 ? 'field-repeat--first' : 'field-repeat'}
                            key={`land${index}`}
                          >
                            <Form.Group>
                              <Form.Field
                                control={Input}
                                label={lang.company_name}
                                width={4}
                                name="companyName"
                                onChange={(event) => {
                                  dispatch(
                                    handleRepeaterChange(
                                      {
                                        event,
                                        info: modality,
                                        field: 'PPPCompanyName',
                                        index: index,
                                      },
                                      fetched
                                    )
                                  );
                                }}
                                value={value.companyName || ''}
                              />
                            </Form.Group>
                            {index > 0 && (
                              <div
                                className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                                onClick={(event) => {
                                  dispatch(
                                    removeRepeaterField(
                                      {
                                        event,
                                        info: modality,
                                        field: 'PPPCompanyName',
                                        index: index,
                                      },
                                      fetched
                                    )
                                  );
                                }}
                              >
                                <i className="material-icons md-12 mr-2 material-delete">
                                  delete_outline
                                </i>
                                Delete
                              </div>
                            )}
                          </div>
                        );
                      })}
                      <Form.Group className="field-repeat-btn">
                        <Form.Field>
                          <button
                            className="btn-text"
                            onClick={(event) => {
                              dispatch(
                                addRepeaterField(
                                  {
                                    event,
                                    info: modality,
                                    field: 'PPPCompanyName',
                                  },
                                  fetched
                                )
                              );
                            }}
                          >
                            + Add more
                          </button>
                        </Form.Field>
                      </Form.Group>
                    </div>
                  )}
                </>
              );
            case 'private':
              return (
                <>
                  <Form.Group>
                    <Form.Field
                      label={lang.select_private_type}
                      control={Select}
                      options={privateType}
                      value={modality.privateType}
                      name="privateType"
                      onChange={(event, { value, name }) => {
                        dispatch(
                          handleSelectChange(
                            {
                              value,
                              name,
                              info: modality,
                            },
                            fetched
                          )
                        );
                      }}
                      width={8}
                      placeholder="Select private type"
                    />
                  </Form.Group>
                  {urlParams.stage !== 'identification' && (
                    <div>
                      {modality.privateCompanyName.map((value, index) => {
                        console.log(index);
                        return (
                          <div
                            className={index === 0 ? 'field-repeat--first' : 'field-repeat'}
                            key={`land${index}`}
                          >
                            <Form.Group>
                              <Form.Field
                                control={Input}
                                label={lang.company_name}
                                width={4}
                                name="companyName"
                                onChange={(event) => {
                                  dispatch(
                                    handleRepeaterChange(
                                      {
                                        event,
                                        info: modality,
                                        field: 'privateCompanyName',
                                        index: index,
                                      },
                                      fetched
                                    )
                                  );
                                }}
                                value={value.companyName || ''}
                              />
                            </Form.Group>
                            {index > 0 && (
                              <div
                                className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                                onClick={(event) => {
                                  dispatch(
                                    removeRepeaterField(
                                      {
                                        event,
                                        info: modality,
                                        field: 'privateCompanyName',
                                        index: index,
                                      },
                                      fetched
                                    )
                                  );
                                }}
                              >
                                <i className="material-icons md-12 mr-2 material-delete">
                                  delete_outline
                                </i>
                                Delete
                              </div>
                            )}
                          </div>
                        );
                      })}
                      <Form.Group className="field-repeat-btn">
                        <Form.Field>
                          <button
                            className="btn-text"
                            onClick={(event) => {
                              dispatch(
                                addRepeaterField(
                                  {
                                    event,
                                    info: modality,
                                    field: 'privateCompanyName',
                                  },
                                  fetched
                                )
                              );
                            }}
                          >
                            + Add more
                          </button>
                        </Form.Field>
                      </Form.Group>
                    </div>
                  )}
                </>
              );
            case 'other':
              return (
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label={lang.if_the_project_implementation_modality_is_other}
                    width={8}
                    name="otherImplementationModal"
                    id="otherImplementationModal"
                    value={modality.otherImplementationModal}
                    onChange={(event) => {
                      dispatch(handleInputChange({ event, info: modality }, fetched));
                    }}
                  />
                </Form.Group>
              );
            default:
              return <></>;
          }
        })()}
      </div>
      <div className="form-actions flex">
        <button
          type="submit"
          onClick={() => handleSaveAndContinue('continue')}
          className="btn-rect btn-rect--small btn-blue"
        >
          Save and continue
        </button>
        <button
          className="btn-rect btn-rect--small btn-dark"
          onClick={() => handleSaveAndContinue('exit')}
        >
          Save and exit
        </button>
        <button
          type="button"
          className="btn-link"
          onClick={() => dispatch(ProjectAction.projectStep({ step: 6 }))}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default Modality;
