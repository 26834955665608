import { ad2bs, bs2ad } from 'ad-bs-converter';
import moment from 'moment';

function DateConvert({ englishDate, nepaliDate }) {
    const date = englishDate || nepaliDate;
    let formattedDate = moment(date).format('YYYY/MM/DD');
    if (!moment(date).isValid()) {
        const month = date.toString().slice(-2);
        if (month === '32') {
            formattedDate = date;
        }
    }
    const displayDate = englishDate ? ad2bs(formattedDate) : bs2ad(formattedDate);

    // const displayNepaliDate = bs2ad(formattedDate);
    // return `${displayEnglishDate.en.year}/${displayEnglishDate.en.month}/${displayEnglishDate.en.day}`;
    // return <></>;
    return displayDate;
}

export default DateConvert;
