import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';
import { fetched, fetchedNullData } from '../../../../reducers/form/ProjectReadiness/action';
import { localization } from '../../../../localization';
import { auth } from '../../../../helpers';

import Editor from '../CkEditor';
import FileUpload from '../FileUpload/index';
import { handleInputChange, handleEditorChange, handleSaveAndExit } from '../../../../actions';
import ConditionalRadioButton from './ConditionalRadioButton';
import * as ProjectAction from '../../../../reducers/form/action';
import LoaderNP from '../../../../components/Loader/Loader';

const ProjectReadiness = ({ urlParams }) => {
  const dispatch = useDispatch();
  const user = useSelector(auth.getCurrentUser);
  const { projectInfo } = useSelector((state) => state.projectBasicInfo);
  const projectSaveToDbLoader = useSelector((state)=>state.project.projectSaveToDbLoader);
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language].readiness;
  const { projectReadiness } = useSelector((state) => state);
  const [projectError, setProjectError] = useState({
    feasibilityStudyError: false,
    feasibilityStudyFileError: false,
    dprError: false,
    dprFileError: false,
    eiaError: false,
    eiaFileError: false,
    ieeError: false,
    ieeFileError: false,
    logFrameError: false,
    logFrameFileError: false,
    implementationPlanError: false,
    implementationPlanFileError: false,
    monitorEvaluationError: false,
    monitorEvaluationFileError: false,
    landAcquisitionError: false,
    landAcquisitionFileError: false,
    projectProposalError: false,
    projectProposalFileError: false,
  });

  useEffect(() => {
    if (user && user.ministry && user.ministry.id) {
      if (projectInfo.project_readiness) {
        dispatch(fetched({ ...projectInfo.project_readiness }));
      }
      if (!projectInfo.project_readiness) {
        dispatch(fetchedNullData());
      }
    }
  }, [projectInfo]);

  const handleSaveAndContinue = (type) => {
    let data = cloneDeep(projectReadiness);

    delete data['step'];
    let canSubmit = true;
    const requiredFields = [
      'feasibilityStudy',
      'dpr',
      'eia',
      'iee',
      'logFrame',
      'implementationPlan',
      'monitorEvaluation',
      'landAcquisition',
      'projectProposal',
    ];
    const requiredFieldsIdentification = ['implementationPlan', 'logFrame'];
    const errors = [];

    let loopFields =
      urlParams.stage === 'identification'
        ? [...requiredFieldsIdentification]
        : [...requiredFields];

    for (let field of loopFields) {
      if (projectReadiness[field] === '') {
        canSubmit = false;
        errors[`${field}Error`] = true;
      } else if (
        projectReadiness[field] === 'Yes' &&
        projectReadiness[`${field}File`][0]?.fileName === ''
      ) {
        canSubmit = false;
        errors[`${field}FileError`] = true;
      }
    }

    setProjectError(errors);
    if (canSubmit) {
      data.stage = urlParams.stage;
      handleSaveAndExit({ project_readiness: data }, type, dispatch, 5, urlParams);
    }
  };

  return projectSaveToDbLoader ? <LoaderNP text={true} /> : (
    <Form className="form-new">
      <div className="form-group-container">
        <ConditionalRadioButton
          lang={lang}
          label="desk_study"
          name="deskStudy"
          uploadLabel="upload_desk_study"
          uploadName="deskStudyFile"
          projectId={urlParams.projectId}
        />
      </div>
      <div className="form-group-container">
        <ConditionalRadioButton
          lang={lang}
          label="pre_feasibility_study"
          uploadLabel="upload_pre_feasibility_study"
          uploadName="preFeasibilityFile"
          name="preFeasibility"
          projectId={urlParams.projectId}
        />
      </div>
      <div className="form-group-container">
        <ConditionalRadioButton
          lang={lang}
          label="project_concept_note"
          uploadLabel="upload_project_concept_note"
          uploadName="projectConceptFile"
          name="projectConcept"
          projectId={urlParams.projectId}
        />
      </div>
      {urlParams.stage !== 'identification' && (
        <>
          <div className="form-group-container">
            <ConditionalRadioButton
              lang={lang}
              label="feasibility_study"
              uploadLabel="upload_project_feasibility_study"
              uploadName="feasibilityStudyFile"
              dateLabel="feasibility_study_date"
              name="feasibilityStudy"
              projectId={urlParams.projectId}
              required={true}
              error={projectError}
            />
          </div>
          <div className="form-group-container">
            <ConditionalRadioButton
              lang={lang}
              label="detailed_project_report"
              uploadLabel="upload_detailed_project_report"
              uploadName="dprFile"
              dateLabel="dpr_date"
              name="dpr"
              projectId={urlParams.projectId}
              required={true}
              error={projectError}
            />
          </div>
          <div className="form-group-container">
            <ConditionalRadioButton
              lang={lang}
              label="project_proposal"
              uploadLabel="upload_project_proposal"
              uploadName="projectProposalFile"
              name="projectProposal"
              projectId={urlParams.projectId}
              error={projectError}
              required={true}
            />
          </div>
        </>
      )}
      {urlParams.stage !== 'identification' && (
        <>
          <div className="form-group-container">
            <ConditionalRadioButton
              lang={lang}
              label="eia"
              uploadLabel="upload_eia"
              uploadName="eiaFile"
              dateLabel="eia_date"
              name="eia"
              projectId={urlParams.projectId}
              required={true}
              error={projectError}
            />
          </div>
          <div className="form-group-container">
            <ConditionalRadioButton
              lang={lang}
              label="iee"
              uploadLabel="upload_iee"
              uploadName="ieeFile"
              dateLabel="iee_date"
              name="iee"
              projectId={urlParams.projectId}
              required={true}
              error={projectError}
            />
          </div>
          <div className="form-group-container">
            <ConditionalRadioButton
              lang={lang}
              label="master_procurement_plan"
              uploadLabel="upload_master_procurement_plan"
              uploadName="masterProcurementFile"
              dateLabel="mpp_date"
              name="masterProcurement"
              projectId={urlParams.projectId}
            />
          </div>
          <div className="form-group-container">
            <ConditionalRadioButton
              lang={lang}
              label="annual_procurement_plan_for_this_FY"
              uploadLabel="upload_annual_procurement_plan_for_this_FY"
              uploadName="annualProcurementFile"
              dateLabel="app_for_coming_fy_date"
              name="annualProcurement"
              projectId={urlParams.projectId}
            />
          </div>
          <div className="form-group-container">
            <ConditionalRadioButton
              lang={lang}
              label="annual_procurement_plan_for_this_coming_FY"
              uploadLabel="upload_annual_procurement_plan_for_this_coming_FY"
              uploadName="annualProcurementComingFile"
              name="annualProcurementComing"
              projectId={urlParams.projectId}
            />
          </div>
        </>
      )}
      <div className="form-group-container">
        <ConditionalRadioButton
          lang={lang}
          label="project_implementation_plan_alternate"
          uploadLabel="upload_project_implementation_plan"
          uploadName="implementationPlanFile"
          dateLabel="pip_date"
          name="implementationPlan"
          projectId={urlParams.projectId}
          required={true}
          error={projectError}
        />
      </div>
      {urlParams.stage !== 'identification' && (
        <>
          <div className="form-group-container">
            <ConditionalRadioButton
              lang={lang}
              label="procurement_and_contract_award"
              uploadLabel="upload_procurement_and_contract_award"
              uploadName="procurementAwardFile"
              dateLabel="procurement_award_date"
              name="procurementAward"
              projectId={urlParams.projectId}
            />
          </div>
          <div className="form-group-container">
            <ConditionalRadioButton
              lang={lang}
              label="risk_management_and_mitigation_plan"
              uploadLabel="upload_risk_management_and_mitigation_plan"
              uploadName="riskManagementFile"
              dateLabel="risk_mitigation_date"
              name="riskManagement"
              projectId={urlParams.projectId}
            />
          </div>
        </>
      )}
      <div className="form-group-container">
        <ConditionalRadioButton
          lang={lang}
          label="development_of_logical_frameWork"
          uploadLabel="upload_development_of_logical_frameWork"
          uploadName="logFrameFile"
          dateLabel="dev_logical_date"
          name="logFrame"
          projectId={urlParams.projectId}
          required={true}
          error={projectError}
        />
      </div>
      {urlParams.stage !== 'identification' && (
        <>
          <div className="form-group-container">
            <ConditionalRadioButton
              lang={lang}
              label="monitor_evaluation_plan"
              uploadLabel="upload_monitor_evaluation_plan"
              uploadName="monitorEvaluationFile"
              dateLabel="monitoring_date"
              name="monitorEvaluation"
              projectId={urlParams.projectId}
              required={true}
              error={projectError}
            />
          </div>
          <div className="form-group-container">
            <ConditionalRadioButton
              lang={lang}
              label="completion_of_the_legal_process_of_land_acquisition"
              uploadLabel="upload_completion_of_the_legal_process_of_land_acquisition"
              uploadName="landAcquisitionFile"
              name="landAcquisition"
              projectId={urlParams.projectId}
              required={true}
              error={projectError}
            />
          </div>
          <div className="form-group-container">
            <Form.Group>
              <Form.Field
                control={Input}
                className="field-align-bottom"
                label={lang.land_acquisition_completed}
                placeholder={lang.land_acquisition_completed}
                width={8}
                name="landAcquisitionComplete"
                value={projectReadiness.landAcquisitionComplete}
                onChange={(event) => {
                  dispatch(handleInputChange({ event, info: projectReadiness }, fetched));
                }}
              />
            </Form.Group>
          </div>
        </>
      )}
      {urlParams.stage == 'identification' ? (
        ''
      ) : (
        <div className="form-group-container">
          <FileUpload
            formName="readiness"
            label="upload_other_document"
            fileData={projectReadiness.otherDocumentFile}
            name="otherDocumentFile"
            form={projectReadiness}
            fetched={fetched}
            projectId={urlParams.projectId}
          />
        </div>
      )}
      <div className="form-group-container">
        <FileUpload
          formName="readiness"
          label="upload_other_document_readiness"
          fileData={projectReadiness.anyOtherDocumentFile}
          name="anyOtherDocumentFile"
          form={projectReadiness}
          fetched={fetched}
          projectId={urlParams.projectId}
        />
      </div>
      <div className="form-group-container">
        <Form.Field>
          <label>{lang.risk_with_the_project}</label>
          <Editor
            formData={projectReadiness.riskAndIssue}
            formInfo={projectReadiness}
            field={false}
            name="riskAndIssue"
            handleEditorChange={handleEditorChange}
            fetched={fetched}
          />
        </Form.Field>

        <FileUpload
          formName="readiness"
          label="upload_risk_with_the_project"
          fileData={projectReadiness.riskAndIssueFile}
          name="riskAndIssueFile"
          form={projectReadiness}
          fetched={fetched}
          projectId={urlParams.projectId}
        />
      </div>
      <div className="form-actions flex">
        <button
          type="submit"
          className="btn-rect btn-rect--small btn-blue"
          onClick={() => handleSaveAndContinue('continue')}
        >
          Save and continue
        </button>
        <button
          type="submit"
          className="btn-rect btn-rect--small btn-dark"
          onClick={() => handleSaveAndContinue('exit')}
        >
          Save and exit
        </button>
        <button
          type="button"
          className="btn-link"
          onClick={() => dispatch(ProjectAction.projectStep({ step: 3 }))}
        >
          Back to previous form
        </button>
      </div>
    </Form>
  );
};

export default ProjectReadiness;
