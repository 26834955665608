import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Grid, Divider, Table } from 'semantic-ui-react';
import { get } from 'lodash';

import { auth } from '../../../helpers';

import { localization } from '../../../localization';
import { getFileFromCloud } from '../../../actions/index';

const ProjectProgressSection = ({ gate }) => {
    const language = useSelector(auth.getSelectedLanguage);
    const lang = localization[language]['progress_view'];
    const projectInfo = useSelector((state) => state.project.projectInfo);
    const [projectData, setProjectData] = useState();
    const [isDownloading, setIsDownloading] = useState({});

    useEffect(() => {
        setProjectData(projectInfo[gate]);
    }, [gate, projectInfo]);

    const downloadFile = async (fileName) => {
        setIsDownloading({ ...isDownloading, [fileName]: true });
        await getFileFromCloud(projectData.gate_id, fileName);
        setIsDownloading({ ...isDownloading, [fileName]: false });
    };

    const downloadUI = (fileName) => {
        return (
            <label className="btn-outline btn-outline--blue">
                {isDownloading[fileName] ? (
                    <i className="hourglass outline icon loading"></i>
                ) : (
                    <i className="material-icons md-12 mr-2 icon-image-preview">cloud_download</i>
                )}
                (Download)
            </label>
        );
    };

    return (
        <div name="project-progress">
            <div className="project-section" id="project-progress">
                <div className="header medium">
                    {projectInfo.project_status === 'new' && gate === 'project_identification'
                        ? lang.form_name_alternate
                        : lang.section_name}
                </div>
                <Tab.Pane>
                    <Grid columns={2}>
                        {gate === 'project_appraisal' ? (
                            <>
                                <Grid.Row>
                                    <Grid.Column>
                                        <div className="header small">
                                            {lang.financial_progress}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <p>{lang.financial_progress_status} :</p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <p className="project-info">
                                            {get(projectData, 'progress.financialProgress')}
                                        </p>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <p>{lang.financial_progress_in_amount} :</p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <p className="project-info">
                                            {get(projectData, 'progress.financialProgressAmt')}
                                        </p>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <p>{lang.supporting_document} :</p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div className="project-info file-download">
                                            {projectData &&
                                                projectData.progress &&
                                                projectData.progress.financialProgressFile.map(
                                                    (item, index) => {
                                                        return (
                                                            Object.keys(item).length !== 0 &&
                                                            !item.isDeleted && (
                                                                <p key={index}>
                                                                    {item.originalName
                                                                        ? item.originalName
                                                                        : item.fileName}
                                                                    {item.fileName.length > 0 && (
                                                                        <>
                                                                            {item.fileSize ? (
                                                                                <span>
                                                                                    ({item.fileSize}
                                                                                    )
                                                                                </span>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                            <button
                                                                                className="btn-download"
                                                                                type="button"
                                                                                onClick={() =>
                                                                                    downloadFile(
                                                                                        item.cloudFileName
                                                                                    )
                                                                                }
                                                                            >
                                                                                {downloadUI(
                                                                                    item.cloudFileName
                                                                                )}
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </p>
                                                            )
                                                        );
                                                    }
                                                )}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <p>{lang.file_description} :</p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div className="project-info file-download">
                                            {projectData &&
                                                projectData.progress &&
                                                projectData.progress.financialProgressFile.map(
                                                    (item, index) => {
                                                        return (
                                                            Object.keys(item).length !== 0 &&
                                                            !item.isDeleted && (
                                                                <p key={index}>
                                                                    {item.fileDescription}
                                                                </p>
                                                            )
                                                        );
                                                    }
                                                )}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>

                                {/* Expenditure of last 2 fiscal year,expenditureTillDate */}
                                <Grid.Row>
                                    <Grid.Column>
                                        <div className="header small">
                                            {lang.expenditure_of_last_2_fiscal_year}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        {get(projectData, 'progress.expenditureTillDate') ? (
                                            <Table celled>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>
                                                            {lang.fiscal_year}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            {lang.allocated_budget}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            {lang.cost_word}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            {lang.currency}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            {lang.usd_exchange_rate}
                                                        </Table.HeaderCell>
                                                        {/* <Table.HeaderCell>{lang.expenditure_budget}</Table.HeaderCell> */}
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {projectData &&
                                                        projectData.progress.expenditureTillDate &&
                                                        projectData.progress.expenditureTillDate.map(
                                                            (expenditureItem, index) => {
                                                                return (
                                                                    <Table.Row key={index}>
                                                                        <Table.Cell>
                                                                            {
                                                                                expenditureItem.fiscalYear
                                                                            }
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            {
                                                                                expenditureItem.allocatedBudget
                                                                            }
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            {
                                                                                expenditureItem.allocatedBudgetWord
                                                                            }
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            {(expenditureItem.allocatedBudget &&
                                                                                expenditureItem.allocatedBudget !==
                                                                                    '') ||
                                                                            expenditureItem.allocatedBudget !==
                                                                                ''
                                                                                ? expenditureItem.allocatedBudgetCurrency &&
                                                                                  expenditureItem.allocatedBudgetCurrency ===
                                                                                      'usd'
                                                                                    ? `${expenditureItem.allocatedBudgetCurrency.toUpperCase()}`
                                                                                    : 'npr'.toUpperCase()
                                                                                : ''}
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            {
                                                                                expenditureItem.allocatedBudgetExchangeRate
                                                                            }
                                                                        </Table.Cell>
                                                                        {/* <Table.Cell>{expenditureItem.expenditureBudget}</Table.Cell> */}
                                                                    </Table.Row>
                                                                );
                                                            }
                                                        )}
                                                </Table.Body>
                                            </Table>
                                        ) : (
                                            ''
                                        )}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        {get(projectData, 'progress.expenditureTillDate') ? (
                                            <Table celled>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>
                                                            {lang.fiscal_year}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            {lang.expenditure_budget}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            {lang.cost_word}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            {lang.currency}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            {lang.usd_exchange_rate}
                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {projectData &&
                                                        projectData.progress.expenditureTillDate &&
                                                        projectData.progress.expenditureTillDate.map(
                                                            (expenditureItem, index) => {
                                                                return (
                                                                    <Table.Row key={index}>
                                                                        <Table.Cell>
                                                                            {
                                                                                expenditureItem.fiscalYear
                                                                            }
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            {
                                                                                expenditureItem.expenditureBudget
                                                                            }
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            {
                                                                                expenditureItem.expenditureBudgetWord
                                                                            }
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            {(expenditureItem.expenditureBudget &&
                                                                                expenditureItem.expenditureBudget !==
                                                                                    '') ||
                                                                            expenditureItem.expenditureBudget !==
                                                                                ''
                                                                                ? expenditureItem.expenditureBudgetCurrency &&
                                                                                  expenditureItem.expenditureBudgetCurrency ===
                                                                                      'usd'
                                                                                    ? `${expenditureItem.expenditureBudgetCurrency.toUpperCase()}`
                                                                                    : 'npr'.toUpperCase()
                                                                                : ''}
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            {
                                                                                expenditureItem.expenditureBudgetExchangeRate
                                                                            }
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }
                                                        )}
                                                </Table.Body>
                                            </Table>
                                        ) : (
                                            ''
                                        )}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <p>{lang.supporting_document} :</p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div className="project-info file-download">
                                            {projectData &&
                                                projectData.progress &&
                                                projectData.progress.expenditureTillDateFile.map(
                                                    (item, index) => {
                                                        return (
                                                            Object.keys(item).length !== 0 &&
                                                            !item.isDeleted && (
                                                                <p key={index}>
                                                                    {item.originalName
                                                                        ? item.originalName
                                                                        : item.fileName}
                                                                    {item.fileName.length > 0 && (
                                                                        <>
                                                                            {item.fileSize ? (
                                                                                <span>
                                                                                    ({item.fileSize}
                                                                                    )
                                                                                </span>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                            <button
                                                                                className="btn-download"
                                                                                type="button"
                                                                                onClick={() =>
                                                                                    downloadFile(
                                                                                        item.cloudFileName
                                                                                    )
                                                                                }
                                                                            >
                                                                                {downloadUI(
                                                                                    item.cloudFileName
                                                                                )}
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </p>
                                                            )
                                                        );
                                                    }
                                                )}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <p>{lang.file_description} :</p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div className="project-info file-download">
                                            {projectData &&
                                                projectData.progress &&
                                                projectData.progress.expenditureTillDateFile.map(
                                                    (item, index) => {
                                                        return (
                                                            Object.keys(item).length !== 0 &&
                                                            !item.isDeleted && (
                                                                <p key={index}>
                                                                    {item.fileDescription}
                                                                </p>
                                                            )
                                                        );
                                                    }
                                                )}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Divider />
                            </>
                        ) : (
                            ''
                        )}

                        {/* Expenditure of current fiscal year,expenditureCurrentFYear */}
                        <Grid.Row>
                            <Grid.Column>
                                <div className="header small">
                                    {lang.expenditure_of_current_fiscal_year}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                {get(projectData, 'progress.expenditureCurrentFYear') ? (
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    {lang.fiscal_year}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {lang.allocated_budget}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {lang.cost_word}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>{lang.currency}</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {lang.usd_exchange_rate}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>
                                                    {get(
                                                        projectData,
                                                        'progress.expenditureCurrentFYear.fiscalYear'
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {get(
                                                        projectData,
                                                        'progress.expenditureCurrentFYear.currentAllocatedBudget'
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {get(
                                                        projectData,
                                                        'progress.expenditureCurrentFYear.currentAllocatedBudgetWord'
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {get(
                                                        projectData,
                                                        'progress.expenditureCurrentFYear.currentAllocatedBudget'
                                                    ) !== ''
                                                        ? get(
                                                              projectData,
                                                              'progress.expenditureCurrentFYear.currentAllocatedBudgetCurrency'
                                                          ) === 'usd'
                                                            ? `${get(
                                                                  projectData,
                                                                  'progress.expenditureCurrentFYear.currentAllocatedBudgetCurrency'
                                                              ).toUpperCase()}`
                                                            : 'npr'.toUpperCase()
                                                        : ''}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {get(
                                                        projectData,
                                                        'progress.expenditureCurrentFYear.currentAllocatedBudgetExchangeRate'
                                                    )}
                                                </Table.Cell>
                                                {/* <Table.Cell>
                          {get(
                            projectData,
                            'progress.expenditureCurrentFYear.currentExpenditureBudget'
                          )}
                        </Table.Cell> */}
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                ) : (
                                    ''
                                )}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                {get(projectData, 'progress.expenditureCurrentFYear') ? (
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    {lang.fiscal_year}
                                                </Table.HeaderCell>
                                                {/* <Table.HeaderCell>{lang.allocated_budget}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.cost_word_allocated_budget}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.currency_allocated_budget}</Table.HeaderCell>
                        <Table.HeaderCell>
                          {lang.usd_exchange_rate_allocated_budget}
                        </Table.HeaderCell> */}
                                                <Table.HeaderCell>
                                                    {lang.total_expenditure}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {lang.cost_word}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>{lang.currency}</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {lang.usd_exchange_rate}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>
                                                    {get(
                                                        projectData,
                                                        'progress.expenditureCurrentFYear.fiscalYear'
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {get(
                                                        projectData,
                                                        'progress.expenditureCurrentFYear.currentExpenditureBudget'
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {get(
                                                        projectData,
                                                        'progress.expenditureCurrentFYear.currentExpenditureBudgetWord'
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {get(
                                                        projectData,
                                                        'progress.expenditureCurrentFYear.currentExpenditureBudget'
                                                    ) !== ''
                                                        ? get(
                                                              projectData,
                                                              'progress.expenditureCurrentFYear.currentExpenditureBudgetCurrency'
                                                          ) === 'usd'
                                                            ? `${get(
                                                                  projectData,
                                                                  'progress.expenditureCurrentFYear.currentExpenditureBudgetCurrency'
                                                              ).toUpperCase()}`
                                                            : 'npr'.toUpperCase()
                                                        : ''}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {get(
                                                        projectData,
                                                        'progress.expenditureCurrentFYear.currentExpenditureBudgetExchangeRate'
                                                    )}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                ) : (
                                    ''
                                )}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <p>{lang.supporting_document} :</p>
                            </Grid.Column>
                            <Grid.Column>
                                <div className="project-info file-download">
                                    {projectData &&
                                        projectData.progress &&
                                        projectData.progress.expenditureCurrentFYearFile.map(
                                            (item, index) => {
                                                return (
                                                    Object.keys(item).length !== 0 &&
                                                    !item.isDeleted && (
                                                        <p key={index}>
                                                            {item.originalName
                                                                ? item.originalName
                                                                : item.fileName}
                                                            {item.fileName.length > 0 && (
                                                                <>
                                                                    {item.fileSize ? (
                                                                        <span>
                                                                            ({item.fileSize})
                                                                        </span>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    <button
                                                                        className="btn-download"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            downloadFile(
                                                                                item.cloudFileName
                                                                            )
                                                                        }
                                                                    >
                                                                        {downloadUI(
                                                                            item.cloudFileName
                                                                        )}
                                                                    </button>
                                                                </>
                                                            )}
                                                        </p>
                                                    )
                                                );
                                            }
                                        )}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <p>{lang.file_description} :</p>
                            </Grid.Column>
                            <Grid.Column>
                                <div className="project-info file-download">
                                    {projectData &&
                                        projectData.progress &&
                                        projectData.progress.expenditureCurrentFYearFile.map(
                                            (item, index) => {
                                                return (
                                                    Object.keys(item).length !== 0 &&
                                                    !item.isDeleted && (
                                                        <p key={index}>{item.fileDescription}</p>
                                                    )
                                                );
                                            }
                                        )}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Divider />

                        {/* Expenditure budget requirement for coming fiscal year,expenditureComingFYear */}
                        <Grid.Row>
                            <Grid.Column>
                                <div className="header small">
                                    {lang.expenditure_budget_requirement_for_coming_fiscal_year}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                {get(projectData, 'progress.expenditureComingFYear') ? (
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    {lang.coming_fiscal_year}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {lang.estimated_annual_budget}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {lang.cost_word}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>{lang.currency}</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {lang.usd_exchange_rate}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>
                                                    {get(
                                                        projectData,
                                                        'progress.expenditureComingFYear.fiscalYear'
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {get(
                                                        projectData,
                                                        'progress.expenditureComingFYear.comingEstimatedAnnualBudget'
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {get(
                                                        projectData,
                                                        'progress.expenditureComingFYear.comingEstimatedAnnualBudgetWord'
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {get(
                                                        projectData,
                                                        'progress.expenditureComingFYear.comingEstimatedAnnualBudget'
                                                    ) !== ''
                                                        ? get(
                                                              projectData,
                                                              'progress.expenditureComingFYear.comingEstimatedAnnualBudgetCurrency'
                                                          ) === 'usd'
                                                            ? `${get(
                                                                  projectData,
                                                                  'progress.expenditureComingFYear.comingEstimatedAnnualBudgetCurrency'
                                                              ).toUpperCase()}`
                                                            : 'npr'.toUpperCase()
                                                        : ''}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {get(
                                                        projectData,
                                                        'progress.expenditureComingFYear.comingEstimatedAnnualBudgetExchangeRate'
                                                    )}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                ) : (
                                    ''
                                )}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <p>{lang.supporting_document} :</p>
                            </Grid.Column>
                            <Grid.Column>
                                <div className="project-info file-download">
                                    {projectData &&
                                        projectData.progress &&
                                        projectData.progress.expenditureComingFYearFile.map(
                                            (item, index) => {
                                                return (
                                                    Object.keys(item).length !== 0 &&
                                                    !item.isDeleted && (
                                                        <p key={index}>
                                                            {item.originalName
                                                                ? item.originalName
                                                                : item.fileName}
                                                            {item.fileName.length > 0 && (
                                                                <>
                                                                    {item.fileSize ? (
                                                                        <span>
                                                                            ({item.fileSize})
                                                                        </span>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    <button
                                                                        className="btn-download"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            downloadFile(
                                                                                item.cloudFileName
                                                                            )
                                                                        }
                                                                    >
                                                                        {downloadUI(
                                                                            item.cloudFileName
                                                                        )}
                                                                    </button>
                                                                </>
                                                            )}
                                                        </p>
                                                    )
                                                );
                                            }
                                        )}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <p>{lang.file_description} :</p>
                            </Grid.Column>
                            <Grid.Column>
                                <div className="project-info file-download">
                                    {projectData &&
                                        projectData.progress &&
                                        projectData.progress.expenditureComingFYearFile.map(
                                            (item, index) => {
                                                return (
                                                    Object.keys(item).length !== 0 &&
                                                    !item.isDeleted && (
                                                        <p key={index}>{item.fileDescription}</p>
                                                    )
                                                );
                                            }
                                        )}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Divider />

                        {/* Projected budget requirement,projectedBudget */}
                        <Grid.Row>
                            <Grid.Column>
                                <div className="header small">
                                    {lang.projected_budget_requirement}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                {get(projectData, 'progress.projectedBudget') ? (
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    {lang.fiscal_year}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {lang.projected_budget_requirement}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {lang.cost_word}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>{lang.currency}</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {lang.usd_exchange_rate}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {projectData &&
                                                projectData.progress.projectedBudget &&
                                                projectData.progress.projectedBudget.map(
                                                    (budgetStatusItem, index) => {
                                                        return (
                                                            <Table.Row key={index}>
                                                                <Table.Cell>
                                                                    {budgetStatusItem.fiscalYear}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {
                                                                        budgetStatusItem.projectedBudgetRequirement
                                                                    }
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {
                                                                        budgetStatusItem.projectedBudgetRequirementWord
                                                                    }
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {(budgetStatusItem.projectedBudgetRequirement &&
                                                                        budgetStatusItem.projectedBudgetRequirement !==
                                                                            '') ||
                                                                    budgetStatusItem.projectedBudgetRequirement !==
                                                                        ''
                                                                        ? budgetStatusItem.projectedBudgetRequirementCurrency &&
                                                                          budgetStatusItem.projectedBudgetRequirementCurrency ===
                                                                              'usd'
                                                                            ? `${budgetStatusItem.projectedBudgetRequirementCurrency.toUpperCase()}`
                                                                            : 'npr'.toUpperCase()
                                                                        : ''}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {
                                                                        budgetStatusItem.projectedBudgetRequirementExchangeRate
                                                                    }
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        );
                                                    }
                                                )}
                                        </Table.Body>
                                    </Table>
                                ) : (
                                    ''
                                )}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <p>{lang.supporting_document} :</p>
                            </Grid.Column>
                            <Grid.Column>
                                <div className="project-info file-download">
                                    {projectData &&
                                        projectData.progress &&
                                        projectData.progress.projectedBudgetFile.map(
                                            (item, index) => {
                                                return (
                                                    Object.keys(item).length !== 0 &&
                                                    !item.isDeleted && (
                                                        <p key={index}>
                                                            {item.originalName
                                                                ? item.originalName
                                                                : item.fileName}
                                                            {item.fileName.length > 0 && (
                                                                <>
                                                                    {item.fileSize ? (
                                                                        <span>
                                                                            ({item.fileSize})
                                                                        </span>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    <button
                                                                        className="btn-download"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            downloadFile(
                                                                                item.cloudFileName
                                                                            )
                                                                        }
                                                                    >
                                                                        {downloadUI(
                                                                            item.cloudFileName
                                                                        )}
                                                                    </button>
                                                                </>
                                                            )}
                                                        </p>
                                                    )
                                                );
                                            }
                                        )}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <p>{lang.file_description} :</p>
                            </Grid.Column>
                            <Grid.Column>
                                <div className="project-info file-download">
                                    {projectData &&
                                        projectData.progress &&
                                        projectData.progress.projectedBudgetFile.map(
                                            (item, index) => {
                                                return (
                                                    Object.keys(item).length !== 0 &&
                                                    !item.isDeleted && (
                                                        <p key={index}>{item.fileDescription}</p>
                                                    )
                                                );
                                            }
                                        )}
                                </div>
                            </Grid.Column>
                        </Grid.Row>

                        {gate === 'project_appraisal' ? (
                            <>
                                <Divider />
                                <Grid.Row>
                                    <Grid.Column>
                                        <div className="header small">{lang.physical_progress}</div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <p>{lang.physical_progress_status} :</p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <p className="project-info">
                                            {get(
                                                projectData,
                                                'progress.pProgress.physicalProgress'
                                            )}
                                        </p>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <p>{lang.supporting_document} :</p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div className="project-info file-download">
                                            {projectData &&
                                                projectData.progress &&
                                                projectData.progress.physicalProgressFile.map(
                                                    (item, index) => {
                                                        return (
                                                            Object.keys(item).length !== 0 &&
                                                            !item.isDeleted && (
                                                                <p key={index}>
                                                                    {item.originalName
                                                                        ? item.originalName
                                                                        : item.fileName}
                                                                    {item.fileName.length > 0 && (
                                                                        <>
                                                                            {item.fileSize ? (
                                                                                <span>
                                                                                    ({item.fileSize}
                                                                                    )
                                                                                </span>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                            <button
                                                                                className="btn-download"
                                                                                type="button"
                                                                                onClick={() =>
                                                                                    downloadFile(
                                                                                        item.cloudFileName
                                                                                    )
                                                                                }
                                                                            >
                                                                                {downloadUI(
                                                                                    item.cloudFileName
                                                                                )}
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </p>
                                                            )
                                                        );
                                                    }
                                                )}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <p>{lang.file_description} :</p>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div className="project-info file-download">
                                            {projectData &&
                                                projectData.progress &&
                                                projectData.progress.physicalProgressFile.map(
                                                    (item, index) => {
                                                        return (
                                                            Object.keys(item).length !== 0 &&
                                                            !item.isDeleted && (
                                                                <p key={index}>
                                                                    {item.fileDescription}
                                                                </p>
                                                            )
                                                        );
                                                    }
                                                )}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </>
                        ) : (
                            ''
                        )}
                    </Grid>
                </Tab.Pane>
            </div>
        </div>
    );
};

export default ProjectProgressSection;
