import React, { useState, useEffect } from 'react';
import ScrollArea from 'react-scrollbar';

const FilterSection = ({
  title,
  toggleFilterOption,
  showOption,
  placeholder,
  data,
  selectedVal,
  handleSelect,
  filterLabel,
  label,
  showSearch,
}) => {
  const [filterResult, setSFilterResult] = useState(data);

  useEffect(() => setSFilterResult(data), [data]);

  const handleSearchText = (e) => {
    let searchText = e.target.value.toLowerCase();

    let result = data.filter((d) => d.label.toLowerCase().includes(searchText));

    setSFilterResult(result);
  };

  return (
    <div className="filter-section">
      <div className="header" onClick={toggleFilterOption}>
        <p className="title">{title}</p>
        <div className="collapse">
          <i className="material-icons md-bold md-blue">{`${showOption ? 'remove' : 'add'}`}</i>
        </div>
      </div>
      <div className={`contents ${showOption ? '' : 'hide'}`}>
        {showSearch && <input type="text" placeholder={placeholder} onChange={handleSearchText} />}
        <div className="options">
          <ScrollArea speed={0.8} horizontal={false}>
            {filterResult &&
              filterResult.length > 0 &&
              filterResult.map((d) => (
                <label
                  className="radio-btn"
                  key={label && label.includes('Sectors') ? d.label : d.value}
                >
                  <input
                    type="radio"
                    name={title}
                    onChange={(event) => {
                      handleSelect(filterLabel, d);
                    }}
                    checked={
                      label && label.includes('Sectors')
                        ? selectedVal.label === d.label
                        : selectedVal.value === d.value
                    }
                  />
                  {label && label.includes('Sectors') ? d.displayLabel : d.label}
                  <span className="checkmark"></span>
                </label>
              ))}
          </ScrollArea>
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
