import React, { useState, useEffect } from 'react';
import { Table, Modal, Button, Form, Pagination, Input } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import GateApi from '../../../services/Gate/Project';
import MinistryService from '../../../services/Ministry';
import { auth } from '../../../helpers';
import Can from '../../../components/Can/can';
import { localization } from '../../../localization';
import LoaderNP from '../../../components/Loader/Loader';
import Filter from '../Filter';
import Select from 'react-select';

function ProjectList() {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['parent_project'];
  const [isLoading, setIsLoading] = useState(true);
  const [projectList, setProjectList] = useState([]);
  const [projectId, setProjectId] = useState('');
  const [ministryId, setMinistryId] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [action, setAction] = useState('');
  const user = useSelector(auth.getCurrentUser);
  const [ministries, setMinistries] = useState([]);
  const [project, setProject] = useState({
    project_status: 'parent',
    sectorial_area: 'parent',
    project_stage: 'parent',
    part_of_program: 'No',
    is_sub_project: 'No',
    ministry_id: null,
    project_name_in_english: '',
    project_name_in_nepali: '',
    project_budget_code: '',
    is_parent: true,
  });
  const [activePage, setActivePage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [totalProjects, setTotalProject] = useState();

  const [queryString, setQueryString] = useState({ page: 0 });
  const [errObj, setValidationError] = useState({
    project_status_error: false,
    sectorial_area_error: false,
    project_stage_error: false,
    part_of_program_error: false,
    project_name_in_english_error: false,
    project_budget_code_error: false,
    ministry_id_error: false,
  });
  const [search, setSearch] = useState('');
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [filterBy, setFilterBy] = useState({
    ministry_id: { value: 0 },
    department_id: { value: 0 },
  });
  const [isExporting,setIsExporting] = useState(false);

  useEffect(() => {
    allParentProject();
    if (user.role === 'npcAdmin' || user.role === 'dataViewer') {
      listMinistries();
    } else {
      setProject({ ...project, ministry_id: { value: user.ministry.id } });
    }
  }, [queryString]);

  const listMinistries = () => {
    MinistryService.listAllMinistries(`?isDashboard=true&`)
      .then((response) => {
        let ministries = [];
        response.data.ministries.lists.forEach((m) => {
          ministries.push({ key: m.id, label: m.name, value: m.id });
        });
        setMinistries(ministries);
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const allParentProject = () => {
    GateApi.listAllParentProjects(queryString)
      .then((response) => {
        let project = response.data.projects;
        setProjectList(project.lists);
        setTotalPage(project.totalPage);
        setTotalSize(project.totalSize);
        setTotalProject(project.count);
        setIsLoading(false);
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const deleteProject = () => {
    setModalOpen(!modalOpen);
    GateApi.deleteProject({
      id: projectId,
    })
      .then((response) => {
        toastr.success('', `${response.message}`);
        setQueryString({ ...queryString, page: activePage - 1 });
      })
      .catch((err) => {
        toastr.error('', `${err.message}`);
      });
  };

  const editProject = (e) => {
    e.preventDefault();
    let canSubmit = true;
    let errors = {};
    const requiredField = ['project_name_in_english', 'project_budget_code'];

    for (let key in project) {
      if (requiredField.includes(key) && project[key].trim() === '') {
        canSubmit = false;
        errors[`${key}_error`] = true;
      }
    }

    setValidationError(errors);

    if (canSubmit) {
      const data = {
        ...project,
        project_name_in_english: project.project_name_in_english.trim().replace(/\s{2,}/g, ' '),
        project_name_in_nepali: project.project_name_in_nepali.trim().replace(/\s{2,}/g, ' '),
        project_budget_code: project.project_budget_code.trim().replace(/\s{2,}/g, ' '),
        ministry_id: project.ministry_id ? project.ministry_id : ministryId,
      };

      GateApi.updateParentProject({ id: projectId }, data)
        .then((response) => {
          if (response.success) {
            setQueryString({ ...queryString, page: activePage - 1 });
            setModalOpen(!modalOpen);
          } else {
            toastr.error('', response.error.message);
            setValidationError({
              project_name_in_english_error: false,
              project_budget_code_error: false,
              ministry_id_error: false,
            });
            setProject({ ...project });
            setModalOpen(!modalOpen);
          }
        })
        .catch((err) => {
          toastr.error('', `${err.error.message}`);
        });
    }
  };

  const createProject = (e) => {
    e.preventDefault();
    let canSubmit = true;
    let errors = {};
    const requiredField = ['project_name_in_english', 'project_budget_code'];

    for (let key in project) {
      if (requiredField.includes(key) && project[key].trim() === '') {
        canSubmit = false;
        errors[`${key}_error`] = true;
      }
    }
    if (user.role === 'npcAdmin' && !project.ministry_id) {
      canSubmit = false;
      errors[`ministry_id_error`] = true;
    }
    setValidationError(errors);

    if (canSubmit) {
      const data = {
        ...project,
        project_name_in_english: project.project_name_in_english.trim().replace(/\s{2,}/g, ' '),
        project_name_in_nepali: project.project_name_in_nepali.trim().replace(/\s{2,}/g, ' '),
        project_budget_code: project.project_budget_code.trim().replace(/\s{2,}/g, ' '),
        ministry_id: project.ministry_id
          ? project.ministry_id && project.ministry_id.value
          : ministryId,
      };

      GateApi.createProject(data)
        .then((response) => {
          if (response.success) {
            toastr.success('', `${response.message}`);
            setQueryString({ ...queryString, page: activePage - 1 });
            setModalOpen(!modalOpen);
          } else {
            toastr.error('', response.error.message);
            setValidationError({
              project_name_in_english_error: false,
              project_budget_code_error: false,
              ministry_id_error: false,
            });
            setProject({ ...project });
            setModalOpen(!modalOpen);
          }
        })
        .catch((err) => {
          toastr.error('', `${err.error.message}`);
        });
    }
  };

  const handlePaginationChange = (e, { activePage }) => {
    setActivePage(activePage);
    setQueryString({ ...queryString, page: activePage - 1 });
  };

  const handleSearch = (label = null, e = null) => {
    if (e) e.preventDefault();
    let filter = {};
    let clonedQueryString = { ...queryString };

    if (label) {
      clonedQueryString = Object.keys(clonedQueryString).reduce((object, key) => {
        if (key !== label) {
          object[key] = clonedQueryString[key];
        }
        return object;
      }, {});
    }
    if (filterBy.ministry_id.value !== 0) {
      filter.ministry_id = filterBy.ministry_id.value;
    }
    if (filterBy.department_id.value !== 0) {
      filter.department_id = filterBy.department_id.value;
    }
    setQueryString({
      ...clonedQueryString,
      page: 0,
      search: search.trim(),
      ...filter,
    });
    setActivePage(1);
  };

  const handleReset = () => {
    setQueryString({ page: activePage - 1 });
    setSearch('');
    setFilterBy({ ministry_id: { value: 0 }, department_id: { value: 0 } });
  };

  const handleRemoveFilter = (filterLabel) => {
    filterBy[filterLabel] = { value: 0 };
    setFilterBy(filterBy);
    handleSearch(filterLabel);
  };

  const showFiltertag = (filter, label) => {
    return (
      <div className="tag">
        <span onClick={() => handleRemoveFilter(filter)}>
          <i className="material-icons close">close</i>
        </span>
        {label}
      </div>
    );
  };

  const handleFilterOptions = (name, selectedOption) => {
    setFilterBy((filterBy) => {
      return { ...filterBy, [name]: selectedOption };
    });
  };

  const handleApplyFilterStatus = () => {
    setShowAdvanceFilter(true);
  };

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value);
  };

  const exportParentData = async () => {
    setIsExporting(true);
    let string = '?';

    if (queryString.filterBy && queryString.filterBy.ministry_id !== 0) {
      string = `${string}ministry_id=${queryString.filterBy.ministry_id}&`;
    }
    if (queryString.filterBy && queryString.filterBy.department_id !== 0) {
      string = `${string}department_id=${queryString.filterBy.department_id}&`;
    }

    if (queryString.search && queryString.search !== '') {
      string = `${string}search=${search}&`;
    }

    const data = await GateApi.exportParentProject(string);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('type', 'hidden');
    link.setAttribute('download', 'gate_projects.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
    setIsExporting(false);
  };

  return (
    <>
      <div className="dashboard-header justify-between">
        <div className="flex">
          <h2 className="header-main">{lang.projects}</h2>
          <div className="project-stats flex">
            <div className="card">
              <p className="card-info">{totalProjects && totalProjects.toLocaleString()}</p>
              <p className="card-label">{lang.projects}</p>
            </div>
            <div className="card">
              <p className="card-info"></p>
              <p className="card-label"></p>
            </div>
            <div className="card">
              <p className="card-info"></p>
              <p className="card-label"></p>
            </div>
            <div className="card">
              <p className="card-info"></p>
              <p className="card-label"></p>
            </div>
            <div className="card">
              <p className="card-info"></p>
              <p className="card-label"></p>
            </div>
          </div>
        </div>
      </div>
      <div className="export-add-button">
      {projectList.length > 0 && (!isExporting ? <button
          className="btn-normal btn-dark btn-shadow--dark btn-transition export"
          onClick={exportParentData}
        >
          <i className="material-icons md-normal download mr-6">system_update_alt</i>
          {lang.export_data}
        </button> : <button
				  className='btn-normal btn-dark btn-shadow--dark btn-transition export'
				 >
				 <i className="ui secondary loading button" />
				 		{lang.export_data}
				 </button>)
			  }        
        {user.role !== 'dataViewer' ? (
          <button
            onClick={() => {
              setModalOpen(!modalOpen);
              setAction('add');
            }}
            className="btn-normal btn-blue btn-shadow--blue btn-transition"
          >
            <i className="material-icons download md-18 md-bold mr-6">add</i>
            {lang.add_new_project}
          </button>
        ) : (
          <></>
        )}
      </div>
      <Can
        role={user.role}
        perform="project-ministry:filter"
        yes={() => (
          <Filter
            ministries={ministries}
            language={lang}
            handleSelect={handleFilterOptions}
            filterValues={filterBy || {}}
            handleSearch={handleSearch}
            handleReset={handleReset}
            handleApplyFilterStatus={handleApplyFilterStatus}
            user={user}
          />
        )}
        no={() => <></>}
      />
      <div className="filter-container">
        <Form>
          {showAdvanceFilter && (
            <div className="filter-tags">
              {filterBy.ministry_id.value !== 0 ? <p>{lang.filter_applied}: </p> : ''}

              <div className="tags-list">
                {filterBy.ministry_id.value !== 0
                  ? showFiltertag('ministry_id', filterBy.ministry_id.label)
                  : ''}
                {filterBy.department_id.value !== 0
                  ? showFiltertag('department_id', filterBy.department_id.label)
                  : ''}
              </div>

              {filterBy.ministry_id.value !== 0 ? (
                <button className="btn-outline btn-outline--red" onClick={handleReset}>
                  {lang.clear_filter}
                </button>
              ) : (
                ''
              )}
            </div>
          )}
        </Form>
      </div>
      <div className="flex align-center chart-search-wrapper">
        <div>
          <form onSubmit={(e) => handleSearch(null, e)}>
            <div className="form-search">
              <Input
                iconPosition="left"
                type="text"
                placeholder="Search by typing name & budget code..."
                onChange={(e) => handleSearchInputChange(e)}
                value={search}
                action={lang.search}
                className="input-search"
              />
              <i className="material-icons md-24">search</i>
            </div>
          </form>
        </div>
      </div>
      <div className="table-container">
        {isLoading ? (
          <LoaderNP />
        ) : projectList.length > 0 ? (
          <>
            <Table padded className="project-table no-action">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}>{lang.project_id}</Table.HeaderCell>
                  <Table.HeaderCell width={6}>{lang.project_name}</Table.HeaderCell>
                  <Table.HeaderCell width={4}>{lang.ministry}</Table.HeaderCell>
                  <Table.HeaderCell width={2}>{lang.budget_code}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {projectList.map((project) => {
                  return (
                    <Table.Row key={project.id}>
                      <Table.Cell className="text-bold project-id">{project.id}</Table.Cell>
                      <Table.Cell>
                        <p className="project-title">{project.project_name_in_english}</p>
                      </Table.Cell>
                      <Table.Cell>{project.ministry && project.ministry.name}</Table.Cell>
                      <Table.Cell>{project.project_budget_code}</Table.Cell>
                      <Table.Cell className="table-bottom-spacing">
                        <Can
                          role={user.role}
                          perform="action:edit-delete"
                          data={project.isActionable}
                          yes={() => (
                            <div className="actions">
                              <span
                                onClick={() => {
                                  setModalOpen(!modalOpen);
                                  setProjectId(project.id);
                                  setMinistryId(project.ministry && project.ministry.id);
                                  setAction('edit');
                                  setProject((projectInfo) => {
                                    return {
                                      ...projectInfo,
                                      project_name_in_english: project.project_name_in_english,
                                      project_name_in_nepali: project.project_name_in_nepali,
                                      project_budget_code: project.project_budget_code,
                                      ministry_id: project.ministry && project.ministry.id,
                                    };
                                  });
                                }}
                                className="btn-outline btn-outline--small btn-outline--blue"
                              >
                                <i className="material-icons md-12 mr-2">edit</i>
                                Edit{' '}
                              </span>

                              {project.isDeleteAble ? (
                                <span
                                  className="btn-outline btn-outline--small btn-outline--red"
                                  onClick={() => {
                                    setModalOpen(!modalOpen);
                                    setProjectId(project.id);
                                    setMinistryId(project.ministry && project.ministry.id);
                                    setAction('delete');
                                  }}
                                >
                                  <i className="material-icons md-12 mr-2 material-delete">
                                    delete_outline
                                  </i>
                                  Delete{' '}
                                </span>
                              ) : (
                                <span
                                  className="btn-outline btn-outline--small btn-disabled-delete"
                                  data-tooltip={lang.project_associated_message}
                                >
                                  <i className="material-icons md-12 mr-2 material-delete">
                                    delete_outline
                                  </i>
                                  Delete
                                </span>
                              )}
                            </div>
                          )}
                          no={() => ''}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>

            <div className="justify-center">
              <Pagination
                defaultActivePage={activePage}
                onPageChange={handlePaginationChange}
                totalPages={totalPage}
                firstItem={{ 'aria-label': 'First item', content: 'First' }}
                lastItem={{ 'aria-label': 'Last item', content: 'Last' }}
                prevItem={{
                  'aria-label': 'Previous item',
                  content: 'Previous',
                }}
                nextItem={{ 'aria-label': 'First item', content: 'Next' }}
              />
            </div>
          </>
        ) : (
          <div className="empty">{lang.no_project_found}</div>
        )}
      </div>

      <Modal
        dimmer="blurring"
        open={modalOpen}
        onClose={() => setModalOpen(!modalOpen)}
        size="mini"
      >
        {action === 'delete' && (
          <>
            <Modal.Header>{lang.delete_project}</Modal.Header>
            <Modal.Content>
              <p>{lang.are_you_sure_delete_project}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={() => setModalOpen(!modalOpen)}>
                Cancel
              </Button>
              <Button
                positive
                content="Yes"
                onClick={(e) => {
                  deleteProject(e);
                }}
              />
            </Modal.Actions>
          </>
        )}

        {action === 'edit' && (
          <>
            <Modal.Header>{lang.edit_project}</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Group grouped>
                  <Form.Field required>
                    <label>{lang.project_name_in_english}</label>
                    <input
                      onChange={(e) => {
                        e.persist();
                        setProject((project) => {
                          return { ...project, project_name_in_english: e.target.value };
                        });
                        setValidationError({ ...errObj, project_name_in_english_error: false });
                      }}
                      value={project.project_name_in_english}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>{lang.project_name_in_nepali}</label>
                    <input
                      onChange={(e) => {
                        e.persist();
                        setProject((project) => {
                          return { ...project, project_name_in_nepali: e.target.value };
                        });
                      }}
                      value={project.project_name_in_nepali}
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label>{lang.budget_code}</label>
                    <input
                      onChange={(e) => {
                        e.persist();
                        setProject((project) => {
                          return { ...project, project_budget_code: e.target.value };
                        });
                      }}
                      value={project.project_budget_code}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="black"
                onClick={() => {
                  setModalOpen(!modalOpen);
                  setProject((project) => {
                    return {
                      ...project,
                      project_name_in_english: '',
                      project_name_in_nepali: '',
                      project_budget_code: '',
                      ministry_id: user.role !== 'npcAdmin' ? user.ministry.id : '',
                    };
                  });
                  setValidationError({
                    project_budget_code_error: false,
                    project_name_in_english_error: false,
                    ministry_id_error: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                positive
                content="Yes"
                onClick={(e) => {
                  editProject(e);
                }}
              />
            </Modal.Actions>
          </>
        )}

        {action === 'add' && (
          <>
            <Modal.Header>{lang.add_new_project}</Modal.Header>
            <Modal.Content>
              <Form>
                <Form.Group grouped>
                  <Form.Field required>
                    <label>{lang.project_name_in_english}</label>
                    <input
                      onChange={(e) => {
                        e.persist();
                        setProject((project) => {
                          return { ...project, project_name_in_english: e.target.value };
                        });
                        setValidationError({ ...errObj, project_name_in_english_error: false });
                      }}
                      className={errObj.project_name_in_english_error ? 'error' : ''}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>{lang.project_name_in_nepali}</label>
                    <input
                      onChange={(e) => {
                        e.persist();
                        setProject((project) => {
                          return { ...project, project_name_in_nepali: e.target.value };
                        });
                      }}
                    />
                  </Form.Field>
                  <Form.Field required>
                    <label>{lang.budget_code}</label>
                    <input
                      onChange={(e) => {
                        e.persist();
                        setProject((project) => {
                          return { ...project, project_budget_code: e.target.value };
                        });
                        setValidationError({ ...errObj, project_budget_code_error: false });
                      }}
                      className={errObj.project_budget_code_error ? 'error' : ''}
                    />
                  </Form.Field>

                  {user.role === 'npcAdmin' && (
                    <Form.Field required>
                      <label>{lang.ministry}</label>
                      <Form.Field>
                        <Select
                          options={ministries}
                          value={project.ministry_id}
                          onChange={(selectedOption) => {
                            setProject((project) => {
                              return {
                                ...project,
                                ministry_id: selectedOption,
                              };
                            });
                            setValidationError({
                              ...errObj,
                              ministry_id_error: false,
                            });
                          }}
                          placeholder={lang.select_ministry}
                          className={errObj.ministry_id_error ? 'error' : ''}
                        />
                      </Form.Field>
                    </Form.Field>
                  )}
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="black"
                onClick={() => {
                  setModalOpen(!modalOpen);
                  setProject((project) => {
                    return {
                      ...project,
                      project_name_in_english: '',
                      project_name_in_nepali: '',
                      project_budget_code: '',
                      ministry_id: user.role !== 'npcAdmin' ? {value:user.ministry.id} : '',
                    };
                  });
                  setValidationError({
                    project_budget_code_error: false,
                    project_name_in_english_error: false,
                    ministry_id_error: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                positive
                content="Yes"
                onClick={(e) => {
                  createProject(e);
                }}
              />
            </Modal.Actions>
          </>
        )}
      </Modal>
    </>
  );
}

export default ProjectList;
