import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Icon } from 'semantic-ui-react';

import { localization } from '../../../../localization';
import {
  handleRepeaterChange,
  handleFileUpload,
  handleRemoveFileUpload,
} from '../../../../actions';

import word from '../../../../assets/images/fileIcons/word.svg';
import image from '../../../../assets/images/fileIcons/image.svg';
import excel from '../../../../assets/images/fileIcons/excel.svg';
import powerpoint from '../../../../assets/images/fileIcons/powerpoint.svg';
import common from '../../../../assets/images/fileIcons/common.svg';
import pdf from '../../../../assets/images/pdf.svg';

let wordFile = ['doc', 'docx', 'DOC', 'DOCX'];
let imageFile = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG'];
let excelFile = ['xls', 'xlsx', 'XLS', 'XLSX', 'csv'];
let powerpointFile = ['ppt', 'pptx', 'PPT', 'PPTX'];

function FileUpload({
  formName,
  label,
  field = false,
  fileData = [{}],
  form,
  name,
  fetched,
  projectId,
  required,
  error,
}) {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.localization.selectedLanguage);
  const lang = localization[selectedLanguage][formName];
  const [index, setIndex] = useState(0);
  const { imageLoadingError } = useSelector((state) => state.project);
  const { imageButtonDisabled } = useSelector((state) => state.project);

  useEffect(() => {
    if (fileData[0].fileName !== '') {
      setIndex(fileData.length);
    }
    if (fileData[0].fileName === '') {
      setIndex(0);
    }
  }, [fileData]);

  const fileFormat = (fileInfo) => {
    let fileFormat = fileInfo.fileName && fileInfo.fileName.split('.')[fileInfo.fileName.split('.').length - 1]

    return (
      <p className="file-name">
        {fileFormat &&
        wordFile.includes(fileFormat) ? (
          <span>
            <img src={word} alt="word" />
          </span>
        ) : imageFile.includes(
            fileFormat
          ) ? (
          <span>
            <img src={image} alt="image" />
          </span>
        ) : excelFile.includes(
            fileFormat
          ) ? (
          <span>
            <img src={excel} alt="excel" />
          </span>
        ) : fileFormat === 'pdf' ? (
          <span>
            <img src={pdf} alt="pdf" />
          </span>
        ) : powerpointFile.includes(
            fileFormat
          ) ? (
          <span>
            <img src={powerpoint} alt="powerpoint" />
          </span>
        ) : (
          <span>
            <img src={common} alt="file" />
          </span>
        )}
        { fileInfo.originalName ? fileInfo.originalName : fileInfo.fileName }
      </p>
    );
  };

  return (
    <Form.Field width={16}>
      <Form.Field
        label={lang[label]}
        required={required}
        width={16}
        className={error ? 'error' : ''}
      />

      <div className="form-field__upload-files">
        {/* Uploaded file detail */}
        {fileData[0].fileName !== '' &&
          fileData.map((fileInfo, index) => {
            return !fileInfo.isDeleted && (
              <Form.Group grouped className="file-item" key={`file${index}`}>
                <div>
                  <Form.Field>
                    {fileFormat(fileInfo)}
                  </Form.Field>
                  <Form.Field
                    label="File description"
                    placeholder="Add file description"
                    control={Input}
                    name="fileDescription"
                    value={fileInfo.fileDescription}
                    onChange={(event) => {
                      dispatch(
                        handleRepeaterChange(
                          {
                            event,
                            info: form,
                            field: name,
                            mainField: field,
                            index: index,
                          },
                          fetched
                        )
                      );
                    }}
                  />
                  <button
                    type="button"
                    onClick={() =>
                      dispatch(
                        handleRemoveFileUpload(
                          { info: form, index, name, projectId: projectId,field },
                          fetched
                        )
                      )
                    }
                    className=" btn-remove btn-outline btn-outline--small btn-outline--red"
                  >
                    <i className="material-icons md-12 mr-2 material-delete">delete_outline</i>
                    Delete
                  </button>
                </div>
              </Form.Group>
            );
          })}
      </div>

      <Form.Group>
        <Form.Field width={8}>
          <div className="form-field__upload-btn">
            <input
              type="file"
              className="input-file"
              id={`file-input-${name}`}
              name={name}
              onChange={(e) =>
                dispatch(
                  handleFileUpload(
                    {
                      e,
                      projectId,
                      field,
                      info: form,
                      name,
                      index,
                    },
                    fetched
                  )
                )
              }
              disabled={imageButtonDisabled}
            />
            <label className="btn-outline btn-outline--blue" htmlFor={`file-input-${name}`}>
              {imageLoadingError[name] && imageLoadingError[name][index] ? (
                <i className="hourglass outline icon loading"></i>
              ) : (
                <i className="material-icons md-12 mr-2 icon-image-preview">cloud_upload</i>
              )}
              Upload file
            </label>
            {/*  */}
            
            {imageLoadingError[name] && imageLoadingError[name][index] ? (
              <div className="progress">
                <div className="progress--bar" 
                  // aria-valuenow={progress}
                  // aria-valuemin="0"
                  // aria-valuemax="100"
                  // style="width: 100%;"
                  // style={{ width: progress + "%" }}
                  >
                  Uploading....
                </div>
              </div>
              ) : (
                <div></div>
              )}

            {/*  */}
          </div>
        </Form.Field>
      </Form.Group>
    </Form.Field>
  );
}

export default FileUpload;
