import React from 'react';
import { useSelector } from 'react-redux';
import { localization } from '../localization';
import { auth } from '../helpers';

const Footer = () => {
    const language = useSelector(auth.getSelectedLanguage);
    const lang = localization[language];
    let footerStr = lang.footer.disclaimer.replace(
        '%province',
        lang.header[`logo_title_${process.env.REACT_APP_PROVINCE_NO}`]
    );
    footerStr = footerStr.replace(
        '%npbmis',
        `<a href="https://npbmis.npc.gov.np">${lang.footer.npbmis}</a>`
    );
    footerStr = footerStr.replace('%npc', `<a href="https://npc.gov.np">${lang.footer.npc}</a>`);

    return (
        <footer className="footer">
            <div dangerouslySetInnerHTML={{ __html: footerStr }} />
        </footer>
    );
};
export default Footer;
