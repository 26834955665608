import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Grid, Header } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import 'react-circular-progressbar/dist/styles.css';

import UserService from '../../services/User';
import { auth } from '../../helpers';

import { localization } from '../../localization';

const UserSetting = ({ history }) => {
    const language = useSelector(auth.getSelectedLanguage);
    const lang = localization[language].users;

    const [userDetail, setUserDetail] = useState({
        current_password: '',
        password: '',
        confirm_password: '',
    });

    const [errObj, setValidationError] = useState({
        current_password: false,
        password: false,
        confirm_password: false,
        password_match: false,
        password_not_match: false,
    });

    const user = useSelector(auth.getCurrentUser);

    const changePassword = () => {
        let sendUser = true;
        const obj = { ...errObj };

        if (userDetail.current_password.trim() === '') {
            obj.current_password = true;
            sendUser = false;
        }

        if (userDetail.password.trim() === '') {
            obj.password = true;
            sendUser = false;
        }

        if (userDetail.confirm_password.trim() === '') {
            obj.confirm_password = true;
            sendUser = false;
        }

        if (userDetail.confirm_password.trim() !== userDetail.password.trim()) {
            obj.password_match = false;
            // obj.password_not_match = true;
            sendUser = false;
        }

        setValidationError(obj);
        if (sendUser) {
            UserService.updatePassword({ userId: user.id }, userDetail)
                .then((response) => {
                    if (response.success) {
                        toastr.success('', `${response.message}`);
                        history.push('/dashboard');
                    } else {
                        throw new Error(response.error.message);
                    }
                })
                .catch((err) => {
                    toastr.error('', `${err.message}`);
                });
        }
    };

    return (
        <>
            <div className="body-container">
                <Grid columns="equal">
                    <Grid.Column className="flex">
                        {/* <Container text className="add-project-form inner-details"> */}
                        <div className="inner-contents">
                            <Header size="medium">{lang.change_password}</Header>
                            <Form>
                                <Form.Group>
                                    <Form.Field required width={10}>
                                        <label>{lang.current_password}</label>
                                        <Form.Input
                                            type="password"
                                            value={userDetail.current_password}
                                            onChange={(e) => {
                                                setUserDetail({
                                                    ...userDetail,
                                                    current_password: e.target.value,
                                                });
                                                setValidationError({
                                                    ...errObj,
                                                    current_password: false,
                                                });
                                            }}
                                            error={
                                                errObj.current_password
                                                    ? {
                                                          content: lang.current_password_required,
                                                          pointing: 'below',
                                                      }
                                                    : null
                                            }
                                        />
                                    </Form.Field>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Field required width={10}>
                                        <label>{lang.new_password}</label>
                                        <Form.Input
                                            type="password"
                                            value={userDetail.password}
                                            onChange={(e) => {
                                                setUserDetail({
                                                    ...userDetail,
                                                    password: e.target.value,
                                                });
                                                setValidationError({ ...errObj, password: false });
                                                if (
                                                    e.target.value.trim() ===
                                                    userDetail.confirm_password.trim()
                                                ) {
                                                    setValidationError({
                                                        ...errObj,
                                                        password_match: true,
                                                    });
                                                } else {
                                                    setValidationError({
                                                        ...errObj,
                                                        password_match: false,
                                                    });
                                                }
                                            }}
                                            error={
                                                errObj.password
                                                    ? {
                                                          content: lang.new_password_required,
                                                          pointing: 'below',
                                                      }
                                                    : null
                                            }
                                            icon={errObj.password_match && 'checkmark'}
                                        />
                                    </Form.Field>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Field required width={10}>
                                        <label>{lang.confirm_password}</label>
                                        <Form.Input
                                            type="password"
                                            value={userDetail.confirm_password}
                                            onChange={(e) => {
                                                setUserDetail({
                                                    ...userDetail,
                                                    confirm_password: e.target.value,
                                                });
                                                setValidationError({
                                                    ...errObj,
                                                    confirm_password: false,
                                                });
                                                if (
                                                    userDetail.password.trim() ===
                                                    e.target.value.trim()
                                                ) {
                                                    setValidationError({
                                                        ...errObj,
                                                        password_match: true,
                                                    });
                                                } else {
                                                    setValidationError({
                                                        ...errObj,
                                                        password_match: false,
                                                    });
                                                }
                                            }}
                                            error={
                                                errObj.confirm_password
                                                    ? {
                                                          content: lang.confirm_password_required,
                                                          pointing: 'below',
                                                      }
                                                    : null
                                            }
                                            icon={
                                                errObj.password_match
                                                    ? 'checkmark'
                                                    : userDetail.password.length !== 0 && 'cancel'
                                            }
                                        />
                                    </Form.Field>
                                </Form.Group>

                                <button
                                    className="btn-transition btn-normal btn-normal--medium btn-normal--br4 btn-blue btn-shadow--blue"
                                    onClick={changePassword}
                                    type="button"
                                >
                                    Save Changes
                                </button>

                                <button
                                    className="btn-transition btn-normal btn-normal--medium btn-text"
                                    onClick={() => history.push('/users')}
                                    type="button"
                                >
                                    Cancel
                                </button>
                            </Form>
                        </div>
                        {/* </Container> */}
                    </Grid.Column>
                </Grid>
            </div>
        </>
    );
};

export default withRouter(UserSetting);
