import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Header, Modal } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';

import BarChart from '../components/Chart/BarChart';
import ProjectList from '../components/ProjectList/ProjectList';
import Can from '../components/Can/can';
import LoaderNP from '../components/Loader/Loader';
import DashboardService from '../services/Dashboard';
import { localization } from '../localization';
import { auth } from '../helpers';
import * as DashboardAction from '../reducers/dashboard/action';

const Dashboard = ({ history }) => {
    const [dashboard, setDashboardValue] = useState({
        projectStats: [],
        projectSector: [],
        projectPriority: [],
        projectMinistry: [],
        projectYearOnYear: [],
        projectStatus: [],
    });

    const dispatch = useDispatch();

    const [ministryCount, setMinistryCount] = useState(false);
    const [queryParams] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    const user = useSelector(auth.getCurrentUser);
    const language = useSelector(auth.getSelectedLanguage);

    const isLoading = useSelector((state) => state.dashboard);

    const lang = localization[language];

    const { role } = user;

    const compare = (a, b) => b.count - a.count;

    const sortedProjectMinistry = useMemo(
        () => [...dashboard.projectMinistry].sort(compare),
        [dashboard.projectMinistry]
    );

    const topMinistry = () => {
        const list = cloneDeep([...sortedProjectMinistry.slice(0, 5)]);

        for (const m of list) {
            m.name = m.name.replace('Ministry of ', '');
            if (m.name.length > 23) {
                m.name = `${m.name.substring(0, 20).trim()}...`;
            }
        }
        return list;
    };

    const sortedProjectPriority = useMemo(
        () => [...dashboard.projectPriority].sort(compare),
        [dashboard.projectPriority]
    );

    const topProjectPriority = () => {
        const list = cloneDeep([...sortedProjectPriority.slice(0, 5)]);
        for (const m of list) {
            if (m.name.length > 23) {
                m.name = `${m.name.substring(0, 20).trim()}...`;
            }
        }
        return list;
    };

    const sortedProjectSector = useMemo(
        () => [...dashboard.projectSector].sort(compare),
        [dashboard.projectSector]
    );

    const topProjectSector = () => {
        const list = cloneDeep([...sortedProjectSector.slice(0, 5)]);

        for (const m of list) {
            if (m.name.length > 23) {
                m.name = `${m.name.substring(0, 20).trim()}...`;
            }
        }
        return list;
    };

    const projectStatusChartInfo = [];
    dashboard.projectStatus.forEach((stat) => {
        let label = '';
        const percentage = parseFloat(
            (stat.count / dashboard.projectStats.totalProject) * 100
        ).toFixed(2);
        if (stat.name === 'Save as Draft') {
            label = 'draft';
        }
        if (stat.name === 'Sent to Verification') {
            label = 'verification';
        }
        if (stat.name === 'Submit') {
            label = 'submitted';
        }
        projectStatusChartInfo.push({ label, percentage, count: stat.count });
    });

    let totalProjectYearOnYear = 0;
    dashboard.projectYearOnYear.forEach((stat) => {
        totalProjectYearOnYear += +stat.count;
    });

    let check = false;

    const handleParams = (query) => {
        if (query !== '') {
            // setQueryParams(query);
            check = true;
        }

        dispatch(DashboardAction.priorityLoading(true));
        dispatch(DashboardAction.sectorLoading(true));
        dispatch(DashboardAction.ministryLoading(true));
        dispatch(DashboardAction.statsLoading(true));
        dispatch(DashboardAction.yearOnYearLoading(true));
        dispatch(DashboardAction.statusLoading(true));

        dispatch(DashboardAction.fetchingStats());
        DashboardService.getProjectStats(query).then((stats) => {
            dispatch(DashboardAction.fetchStats(stats));

            DashboardService.getProjectSector(query).then((sector) => {
                DashboardService.getProjectMinistry(query).then((ministry) => {
                    dispatch(DashboardAction.fetchingPriority());
                    DashboardService.getProjectPriority(query).then((priority) => {
                        dispatch(DashboardAction.fetchPriority(priority));

                        DashboardService.getProjectYearOnYear(query).then((result) => {
                            DashboardService.getProjectStatus(query).then((status) => {
                                dispatch(DashboardAction.priorityLoading(false));
                                dispatch(DashboardAction.sectorLoading(false));
                                dispatch(DashboardAction.ministryLoading(false));
                                dispatch(DashboardAction.statsLoading(false));
                                dispatch(DashboardAction.yearOnYearLoading(false));
                                dispatch(DashboardAction.statusLoading(false));
                                setDashboardValue({
                                    projectStats: stats,
                                    projectSector: sector,
                                    projectMinistry: ministry,
                                    projectPriority: priority,
                                    projectYearOnYear: result,
                                    projectStatus: status,
                                });
                                try {
                                    for (const i of ministry) {
                                        if (i.count > 0) {
                                            setMinistryCount(true);
                                            break;
                                        } else {
                                            setMinistryCount(false);
                                            // break;
                                        }
                                    }
                                    return true;
                                } catch (err) {
                                    return true;
                                }
                            });
                        });
                    });
                });
            });
        });
    };

    useEffect(() => {
        if (!check) handleParams(queryParams);
        if (check && queryParams !== '') handleParams(queryParams);
    }, [queryParams]);

    const childRef = useRef();

    return (
        <>
            <div className="body-container no-print">
                <div className="dashboard-header justify-between">
                    <div className="flex">
                        <h2 className="header-main">{lang.dashboard.dashboard}</h2>
                        <div className="project-stats flex">
                            <div className="card">
                                {isLoading.statsLoading ? (
                                    <LoaderNP />
                                ) : (
                                    <>
                                        <p className="card-info">
                                            {dashboard.projectStats.totalProject &&
                                                dashboard.projectStats.totalProject.toLocaleString()}
                                        </p>
                                        <p className="card-label">{lang.dashboard.projects}</p>
                                    </>
                                )}
                            </div>
                            <div className="card">
                                {isLoading.statsLoading ? (
                                    <LoaderNP />
                                ) : (
                                    <>
                                        <p className="card-info">
                                            {dashboard.projectStats.onGoingProject &&
                                                dashboard.projectStats.onGoingProject.toLocaleString()}
                                        </p>
                                        <p className="card-label">{lang.dashboard.ongoing}</p>
                                    </>
                                )}
                            </div>
                            <div className="card">
                                {isLoading.statsLoading ? (
                                    <LoaderNP />
                                ) : (
                                    <>
                                        <p className="card-info">
                                            {dashboard.projectStats.futureProject &&
                                                dashboard.projectStats.futureProject.toLocaleString()}
                                        </p>
                                        <p className="card-label">{lang.dashboard.future}</p>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="project-cost flex">
                        <div className="card">
                            {isLoading.statsLoading ? (
                                <LoaderNP />
                            ) : (
                                <>
                                    <p className="card-info">
                                        <span className="currency">{lang.dashboard.npr}</span>
                                        {dashboard.projectStats.totalOriginalCost &&
                                            Math.round(
                                                dashboard.projectStats.totalOriginalCost
                                            ).toLocaleString()}
                                    </p>
                                    <p className="card-label">{lang.dashboard.projects_cost}</p>
                                </>
                            )}
                        </div>
                        <div className="card">
                            {isLoading.statsLoading ? (
                                <LoaderNP />
                            ) : (
                                <>
                                    <p className="card-info">
                                        <span className="currency">{lang.dashboard.npr}</span>
                                        {dashboard.projectStats.totalEstimateBudget &&
                                            Math.round(
                                                dashboard.projectStats.totalEstimateBudget
                                            ).toLocaleString()}
                                    </p>
                                    <p className="card-label">
                                        {lang.dashboard.next_fiscal_year_budget}
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div>
                    <div className="chart-container">
                        <div
                            className={`chart-wrapper bar-chart ${
                                dashboard.projectPriority && dashboard.projectPriority.length > 0
                                    ? ''
                                    : 'disable-action'
                            }`}
                            onClick={() => {
                                setModalOpen(!modalOpen);
                                setModalData({
                                    data: sortedProjectPriority,
                                    title: `${lang.dashboard.project_based_on_priority}`,
                                    useCan: false,
                                    maxWidth: 170,
                                });
                            }}
                        >
                            {isLoading.priorityLoading ? (
                                <LoaderNP />
                            ) : (
                                <>
                                    <Header size="tiny">
                                        {lang.dashboard.project_based_on_priority}
                                    </Header>
                                    {dashboard.projectPriority &&
                                    dashboard.projectPriority.length > 0 ? (
                                        <>
                                            <BarChart
                                                data={topProjectPriority()}
                                                truncateText={false}
                                            />
                                            <p className="text-center chart-info">
                                                Click to see chart in detail
                                                <i className="material-icons ml-2">unfold_more</i>
                                            </p>
                                        </>
                                    ) : (
                                        <div className="no-result">No Result Found</div>
                                    )}
                                </>
                            )}
                        </div>

                        <div
                            className={`chart-wrapper bar-chart ${
                                dashboard.projectSector && dashboard.projectSector.length > 0
                                    ? ''
                                    : 'disable-action'
                            }`}
                            onClick={() => {
                                setModalOpen(!modalOpen);
                                setModalData({
                                    data: sortedProjectSector,
                                    title: lang.dashboard.sectors_based_on_projects,
                                    useCan: false,
                                    maxWidth: 170,
                                });
                            }}
                        >
                            {isLoading.sectorLoading ? (
                                <LoaderNP />
                            ) : (
                                <>
                                    <Header size="tiny">
                                        {lang.dashboard.top_5_sectors}{' '}
                                        <span>{lang.dashboard.based_on_no_of_projects}</span>
                                    </Header>
                                    {dashboard.projectSector &&
                                    dashboard.projectSector.length > 0 ? (
                                        <>
                                            <BarChart
                                                data={topProjectSector()}
                                                truncateText={false}
                                            />
                                            <p className="text-center chart-info">
                                                Click to see chart in detail
                                                <i className="material-icons ml-2">unfold_more</i>
                                            </p>
                                        </>
                                    ) : (
                                        <div className="no-result">No Result Found</div>
                                    )}
                                </>
                            )}
                        </div>
                        <Can
                            role={role}
                            perform="project-ministry:filter"
                            yes={() => (
                                <div
                                    className={`chart-wrapper bar-chart ${
                                        ministryCount ? '' : 'disable-action'
                                    }`}
                                    onClick={() => {
                                        setModalOpen(!modalOpen);
                                        setModalData({
                                            data: sortedProjectMinistry,
                                            title: lang.dashboard.ministries_based_on_project,
                                            useCan: true,
                                            maxWidth: 150,
                                        });
                                    }}
                                >
                                    {isLoading.ministryLoading ? (
                                        <LoaderNP />
                                    ) : (
                                        <>
                                            <Header size="tiny">
                                                {lang.dashboard.top_5_ministries}{' '}
                                                <span>
                                                    {lang.dashboard.based_on_no_of_projects}
                                                </span>
                                            </Header>
                                            {ministryCount ? (
                                                <>
                                                    <BarChart
                                                        data={topMinistry()}
                                                        truncateText={false}
                                                    />
                                                    <p className="text-center chart-info">
                                                        Click to see chart in detail
                                                        <i className="material-icons ml-2">
                                                            unfold_more
                                                        </i>
                                                    </p>
                                                </>
                                            ) : (
                                                <div className="no-result">No Result Found</div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                            no={() => <></>}
                        />
                    </div>
                </div>

                <ProjectList
                    handleParams={handleParams}
                    projectYearOnYear={dashboard.projectYearOnYear}
                    projectsStatus={dashboard.projectStatus}
                    totalProjectYearOnYear={totalProjectYearOnYear}
                    projectStatusChartInfo={projectStatusChartInfo}
                    pageSize={20}
                    history={history}
                />

                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(!modalOpen)}
                    closeIcon
                    className="chart-modal"
                >
                    {modalData.useCan ? (
                        <Can
                            role={role}
                            perform="project-ministry:filter"
                            yes={() => (
                                <div className="chart-wrapper bar-chart">
                                    <div className="bar-chart-header flex justify-between align-center">
                                        <Header size="tiny">{modalData.title}</Header>
                                        <div className="no-print">
                                            <button
                                                type="button"
                                                className="btn-outline btn-outline--blue"
                                                onClick={() => window.print()}
                                            >
                                                <i className="material-icons mr-6">print</i>
                                                Print
                                            </button>
                                            <button
                                                type="button"
                                                className="btn-outline btn-outline--blue"
                                                onClick={() => childRef.current.exportPdf()}
                                            >
                                                <i className="material-icons mr-6">
                                                    cloud_download
                                                </i>
                                                Download graph
                                            </button>
                                        </div>
                                    </div>
                                    {modalData.data && modalData.data.length > 0 ? (
                                        <BarChart
                                            data={modalData.data}
                                            truncateText={false}
                                            maxWidth={modalData.maxWidth}
                                            horizontal
                                            ref={childRef}
                                            title={modalData.title}
                                        />
                                    ) : (
                                        <div className="no-result">No Result Found</div>
                                    )}
                                </div>
                            )}
                            no={() => <></>}
                        />
                    ) : (
                        <div className="chart-wrapper bar-chart">
                            <div className="bar-chart-header flex justify-between align-center">
                                <Header size="tiny">{modalData.title}</Header>
                                <div className="no-print">
                                    <button
                                        type="button"
                                        className="btn-outline btn-outline--blue"
                                        onClick={() => window.print()}
                                    >
                                        <i className="material-icons mr-6">print</i>
                                        Print
                                    </button>
                                    <button
                                        type="button"
                                        className="btn-outline btn-outline--blue"
                                        onClick={() => childRef.current.exportPdf()}
                                    >
                                        <i className="material-icons mr-6">cloud_download</i>
                                        Download graph
                                    </button>
                                </div>
                            </div>
                            {modalData.data && modalData.data.length > 0 ? (
                                <BarChart
                                    data={modalData.data}
                                    truncateText={false}
                                    maxWidth={modalData.maxWidth}
                                    horizontal
                                    ref={childRef}
                                    title={modalData.title}
                                />
                            ) : (
                                <div className="no-result">No Result Found</div>
                            )}
                        </div>
                    )}
                </Modal>
            </div>
        </>
    );
};

export default Dashboard;
