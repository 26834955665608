import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { useSelector } from 'react-redux';
import { Button, Modal, Table, Pagination, Checkbox } from 'semantic-ui-react';
import moment from 'moment';

import TrashApi from '../../../services/Gate/Trash';
import LoaderNP from '../../../components/Loader/Loader';
import { auth } from '../../../helpers';
import { localization } from '../../../localization';
import Can from '../../../components/Can/can';

const Trash = ({ history }) => {
  const user = useSelector(auth.getCurrentUser);
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['trash'];
  const [loading, setLoading] = useState(true);
  const [projectList, setProjectList] = useState([]);
  const [queryString, setQueryString] = useState({ page: 0 });
  const [activePage, setActivePage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [projectId, setProjectId] = useState();
  const [selectedProject, setSelectedProject] = useState([]);
  const [action, setAction] = useState('');
  const [count, setCount] = useState();

  useEffect(() => {
    allProjects();
  }, [queryString]);

  const allProjects = () => {
    setLoading(true);
    TrashApi.listProjects(queryString)
      .then((response) => {
        if (response.success) {
          let p = response.data.projects;
          setProjectList(p.projects);
          setTotalPage(p.totalPage);
          setLoading(false);
          setCount(p.count);
        } else {
          throw new Error(response.error.message);
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const handlePaginationChange = (e, { activePage }) => {
    setQueryString({ ...queryString, page: activePage - 1 });
    setActivePage(activePage);
  };

  const restoreProject = (id) => {
    TrashApi.restoreProject({ id })
      .then((res) => {
        toastr.success('', `${res.message}`);
        setQueryString({ ...queryString, page: 0 });
      })
      .catch((err) => console.log(err));
  };

  const deleteProject = (e) => {
    setModalOpen(!modalOpen);
    TrashApi.deleteProject({ id: projectId })
      .then((res) => {
        console.log(res)
        toastr.success('', `${res.message}`);
        setQueryString({ ...queryString, page: 0 });
      })
      .catch((err) => console.log(err));
  };

  const handleAllCheck = () => {
    setSelectedProject([]);
    let checkedProject = [];
    if (!isAllChecked()) {
      for (let project of projectList) {
        checkedProject.push(project.id);
      }
      setSelectedProject(checkedProject);
    }
  };

  const isAllChecked = () => {
    let checkFlag = true;
    for (let project of projectList) {
      if (!selectedProject.includes(project.id)) {
        checkFlag = false;
        break;
      }
    }
    return checkFlag;
  };

  const isChecked = (id) => selectedProject.includes(id);

  const handleCheckBox = (id) => {
    let checkedProject = [...selectedProject];
    if (!isChecked(id)) {
      checkedProject.push(id);
    } else {
      checkedProject.splice(checkedProject.indexOf(id), 1);
    }
    setSelectedProject(checkedProject);
  };

  const bulkRestore = () => {
    TrashApi.bulkRestore(selectedProject)
      .then((res) => {
        if (res.success) {
          toastr.success('', `${res.message}`);
          setQueryString({ ...queryString, page: 0 });
          setSelectedProject([]);
        }
      })
      .catch((err) => console.log(err));
  };

  const bulkDelete = () => {
    setModalOpen(!modalOpen);
    TrashApi.bulkDelete(selectedProject)
      .then((res) => {
        if (res.success) {
          toastr.success('', `${res.message}`);
          setQueryString({ ...queryString, page: 0 });
          setSelectedProject([]);
        }
      })
      .catch((err) => console.log(err));
  };

  const emptyTrash = () => {
    setModalOpen(!modalOpen);
    TrashApi.bulkDelete(null)
      .then((res) => {
        if (res.success) {
          toastr.success('', `${res.message}`);
          setQueryString({ ...queryString, page: 0 });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="body-container no-print">
        <div className="dashboard-header justify-between">
          <div className="flex">
            <h2 className="header-main">{lang.trash}</h2>
            <div className="project-stats flex">
              <div className="card">
                <p className="card-info">{count}</p>
                <p className="card-label">Projects</p>
              </div>
            </div>
          </div>
          <Can
            role={user.role}
            perform="trash:restore:delete"
            yes={() => (
              <div className="trash-bulk-operation">
                <div
                  className={`bulk-operation ${
                    selectedProject.length > 0 ? 'visibility-visible' : 'visibility-hidden'
                  }`}
                >
                  <p>Select bulk operation</p>
                  <button className="btn-outline btn-outline--blue " onClick={bulkRestore}>
                    <i className="material-icons mr-6 ">restore</i>
                    Restore
                  </button>
                  <button
                    className="btn-outline btn-outline--red "
                    onClick={() => {
                      setModalOpen(!modalOpen);
                      setAction('bulkDelete');
                    }}
                  >
                    <i className="material-icons mr-6 material-delete">delete_outline</i>
                    Delete
                  </button>
                </div>
              </div>
            )}
            no={() => ''}
          />
        </div>

        {projectList.length > 0 && (
          <Can
            role={user.role}
            perform="trash:restore:delete"
            yes={() => (
              <div>
                <button
                  className="btn-normal btn-red btn-shadow--red btn-transition btn-empty"
                  onClick={() => {
                    setModalOpen(!modalOpen);
                    setAction('emptyTrash');
                  }}
                >
                  <i className="material-icons mr-6 material-delete">delete_outline</i>
                  Empty trash
                </button>
              </div>
            )}
            no={() => ''}
          />
        )}
        {loading ? (
          <LoaderNP />
        ) : projectList && projectList.length > 0 ? (
          <>
            <Table sortable className="project-table no-action">
              <Table.Header>
                <Table.Row>      
                  {user.role !== 'dataViewer' && (
                    <Table.HeaderCell className="id-header">
                      <Checkbox onClick={handleAllCheck} checked={isAllChecked()} />
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell width={6}>{lang.name}</Table.HeaderCell>
                  <Table.HeaderCell width={4}>{lang.ministry}</Table.HeaderCell>
                  <Table.HeaderCell width={4}>{lang.department}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {projectList.map((project) => {
                  return (
                    <>
                      <Table.Row
                        key={project.id}
                        className={selectedProject.includes(project.id) ? 'selected-row' : ''}
                      >
                        {user.role !== 'dataViewer' && (
                          <Table.Cell width={1}>
                            <Checkbox
                              checked={isChecked(project.id)}
                              onChange={() => handleCheckBox(project.id)}
                            />
                          </Table.Cell>
                        )}
                        <Table.Cell>
                          <p className="project-title">{project.project_name_in_english}</p>
                          <div className="project-metadata">
                            {project.project_status === 'new' ? (
                              <span className="gate-label project-status dark">
                                {project.project_stage}
                              </span>
                            ) : (
                              ''
                            )}
                            {project.detail && (
                              <span
                                className={`project-status
													${
                            project.detail &&
                            (project.detail.moderation_status === 'Send for verification'
                              ? 'verification'
                              : project.detail.moderation_status === 'Submitted'
                              ? 'submitted'
                              : 'draft')
                          }`}
                              >
                                {project.detail &&
                                  (project.detail.moderation_status === 'Send for verification'
                                    ? 'verification'
                                    : project.detail.moderation_status)}
                              </span>
                            )}
                            {project.is_parent && (
                              <span className="gate-label project-status dark">
                                PARENT PROJECT
                              </span>
                            )}

                            <span className="divider">|</span>
                            <span>
                              Last updated: {moment(project.updatedAt).format('MMM DD,YYYY')}
                            </span>
                          </div>
                        </Table.Cell>
                        <Table.Cell>{project.ministry.name}</Table.Cell>
                        <Table.Cell>
                          <span className="not-available">
                            {project.department
                              ? project.department.name
                              : 'Department not available'}
                          </span>

                          {selectedProject.length === 0 && (
                            <Can
                              role={user.role}
                              perform="trash:restore:delete"
                              yes={() => (
                                <div className="actions">
                                  <span
                                    className="btn-outline btn-outline--small btn-outline--blue"
                                    onClick={() => restoreProject(project.id)}
                                  >
                                    <i className="material-icons md-12 mr-2 edit">restore</i>
                                    Restore
                                  </span>
                                  <span
                                    className="btn-outline btn-outline--small btn-outline--red"
                                    onClick={() => {
                                      setModalOpen(!modalOpen);
                                      setProjectId(project.id);
                                      setAction('delete');
                                    }}
                                  >
                                    <i className="material-icons md-12 mr-2 material-delete delete">
                                      delete_outline
                                    </i>
                                    Delete
                                  </span>
                                </div>
                              )}
                              no={() => ''}
                            />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    </>
                  );
                })}
              </Table.Body>
            </Table>
            <div className="justify-center">
              <Pagination
                defaultActivePage={activePage}
                totalPages={totalPage}
                onPageChange={handlePaginationChange}
                firstItem={{
                  'aria-label': 'First item',
                  content: 'First',
                }}
                lastItem={{
                  'aria-label': 'Last item',
                  content: 'Last',
                }}
                prevItem={{
                  'aria-label': 'Previous item',
                  content: 'Previous',
                }}
                nextItem={{
                  'aria-label': 'First item',
                  content: 'Next',
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="empty">
              <p as="p" size="">
                Trash is empty
              </p>
            </div>
          </>
        )}
      </div>

      <Modal
        dimmer="blurring"
        open={modalOpen}
        onClose={() => setModalOpen(!modalOpen)}
        size="mini"
      >
        <Modal.Header>{lang.delete_trash}</Modal.Header>
        <Modal.Content>
          <p>{lang.are_you_sure_delete_trash_project}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setModalOpen(!modalOpen)}>
            Cancel
          </Button>
          <Button
            positive
            content="Yes"
            onClick={(e) => {
              return action === 'delete'
                ? deleteProject(e)
                : action === 'bulkDelete'
                ? bulkDelete()
                : action === 'emptyTrash'
                ? emptyTrash()
                : () => '';
            }}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default Trash;
