import React, { useLayoutEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

const BarChart = forwardRef(
    ({ data, truncateText = true, maxWidth = 60, horizontal, title }, ref) => {
        const barchartDiv = useRef(null);

        let chartData;

        useImperativeHandle(ref, () => ({
            exportPdf() {
                chartData.exporting.export('jpg');
            },
        }));

        useLayoutEffect(() => {
            am4core.useTheme(am4themesAnimated);

            let chart = am4core.create(barchartDiv.current, am4charts.XYChart);
            chartData = chart;
            let categoryAxis;
            let valueAxis;

            if (horizontal) {
                categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
            } else {
                categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
                valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            }
            categoryAxis.dataFields.category = 'name';
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 30;
            categoryAxis.renderer.grid.template.disabled = true;

            const label = categoryAxis.renderer.labels.template;
            label.wrap = true;
            label.maxWidth = maxWidth;
            label.truncate = truncateText;

            valueAxis.renderer.labels.template.disabled = true;
            valueAxis.ghostLabel.wrap = true;
            valueAxis.renderer.grid.template.disabled = true;
            if (horizontal) {
                valueAxis.renderer.opposite = true;
                categoryAxis.renderer.inversed = true;
                label.textAlign = 'end';
            } else {
                // label.tooltipText = "{name}"
            }

            // Create series
            const series = chart.series.push(new am4charts.ColumnSeries());
            if (horizontal) {
                series.dataFields.valueX = 'count';
                series.dataFields.categoryY = 'name';
                series.columns.template.tooltipText = '{categoryY}: [bold]{valueX}[/]';
                // series.columns.template.height = 40;
            } else {
                series.dataFields.valueY = 'count';
                series.dataFields.categoryX = 'name';
                series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
            }
            series.columns.template.fillOpacity = 1;
            series.columns.template.fill = am4core.color('#9AA2AF');
            series.tooltip.getFillFromObject = false;
            series.tooltip.background.fillOpacity = 1;
            series.tooltip.background.fill = am4core.color('#fff');
            series.tooltip.autoTextColor = false;
            series.tooltip.label.fill = am4core.color('#697588');

            const hoverState = series.columns.template.states.create('hover');
            hoverState.properties.fill = am4core.color('#697588');

            const bullet1 = series.bullets.push(new am4charts.LabelBullet());
            bullet1.interactionsEnabled = true;
            bullet1.label.text = '{count}';
            bullet1.label.fill = am4core.color('#697588');
            bullet1.label.truncate = false;
            bullet1.label.hideOversized = false;
            bullet1.label.fontSize = 10;

            const columnTemplate = series.columns.template;
            columnTemplate.strokeWidth = 0;
            columnTemplate.strokeOpacity = 1;

            if (horizontal) {
                const targetHeight = 50 * data.length;
                chart.svgContainer.htmlElement.style.height = `${targetHeight}px`;
                bullet1.label.horizontalCenter = 'left';
                bullet1.label.dx = 10;
                bullet1.label.dy = 'center';
                bullet1.interactionsEnabled = false;
            } else {
                bullet1.label.verticalCenter = 'bottom';
            }

            chart.data = data;
            chart.fontSize = 10;
            chart.maskBullets = false;
            // chart.exporting.getFormatOptions("pdf").addURL = false;
            chart.exporting.filePrefix = title;

            /* // Adding PDF title
		chart.exporting.adapter.add("pdfmakeDocument", function(pdf, target) {
			pdf.doc.content.unshift({
				text: "Regional revenue comparison",
				margin: [0, 10],
				style: {
					fontSize: 10,
					bold: false,
				}
			});
			
			return pdf;
		}); */

            return () => {
                chart.dispose();

                chart = null;
            };
        }, [data]);
        return (
            <>
                <div ref={barchartDiv} className="bar-chart-wrapper" />
            </>
        );
    }
);

export default BarChart;
