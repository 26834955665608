import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Http from 'axios';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
// import HeroImg from '../../assets/images/hero-img.svg';
import ProjectMap from '../ProjectMap';
// import DashboardService from '../../services/Dashboard';
import { API_URL, auth } from '../../helpers';
import * as ProjectAction from '../../reducers/form/action';

import * as HeroImgs from '../../assets/images/hero';

import { localization } from '../../localization';

const HomePage = () => {
    const [stats, setStats] = useState('');
    const dispatch = useDispatch();

    const language = useSelector(auth.getSelectedLanguage);
    const lang = localization[language];
    let exploreLang = lang.homepage.explore;
    let bodyData = lang.homepage.body_data;
    bodyData = bodyData.replace(
        '%province',
        lang.homepage[`province_title_${process.env.REACT_APP_PROVINCE_NO}`]
    );
    exploreLang = exploreLang.replace('%total', stats.totalProject);
    exploreLang = exploreLang.replace(
        '%province',
        lang.header[`logo_title_${process.env.REACT_APP_PROVINCE_NO}`]
    );

    useEffect(() => {
        projectStats();
    }, []);

    const projectStats = async () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        try {
            const res = await Http.get(`${API_URL}homepage`, config);
            setStats(res.data.stats);
            dispatch(ProjectAction.mapProjectList(res));
        } catch (error) {
            console.log('---eror', error);
        }
    };
    return (
        <ParallaxProvider>
            <div className="body-container">
                <h1 className="page-title">{lang.homepage.title}</h1>

                <div className="hero-container flex">
                    <div className="img-wrapper">
                        {/* <img src={HeroImg} alt="Hero img" /> */}
                        <Parallax y={['-20px', '20px']}>
                            <img src={HeroImgs.main} className="hero-main" alt="HeroImg Main" />
                        </Parallax>
                        <Parallax y={['80px', '-80px']} className="hero-chart-wrapper">
                            <img src={HeroImgs.chart} className="hero-chart" alt="HeroImg Chart" />
                        </Parallax>
                        <Parallax y={['10px', '-10px']}>
                            <img src={HeroImgs.grid} className="hero-grid" alt="HeroImg Grid" />
                        </Parallax>
                        <Parallax y={['30px', '-30px']}>
                            <img src={HeroImgs.bars} className="hero-bars" alt="HeroImg Bar" />
                        </Parallax>
                        <Parallax y={['50px', '-50px']}>
                            <img src={HeroImgs.table} className="hero-table" alt="HeroImg Table" />
                        </Parallax>
                    </div>

                    <div>
                        <Parallax y={['-30px', '30px']}>
                            <div className="hero-title">
                                <h1 className="heading-1 normal">{bodyData}</h1>
                                <p className="body-2">{lang.homepage.body_sub_data}</p>
                            </div>

                            <div className="hero-stats flex">
                                <div>
                                    <p className="heading-1 bold">{stats.totalProject}</p>
                                    <p className="label-2">Total projects submitted</p>
                                </div>
                                <div>
                                    <p className="heading-2 bold">{stats.onGoingProject}</p>
                                    <p className="label-2">Ongoing projects</p>
                                </div>
                                <div>
                                    <p className="heading-2 bold">{stats.futureProject}</p>
                                    <p className="label-2">Future projects</p>
                                </div>
                            </div>
                        </Parallax>
                    </div>
                </div>

                <div className="hero-video-container flex">
                    <>
                        <iframe
                            className="video"
                            // width="512"
                            // height="315"
                            title="Npbmis video"
                            src="https://www.youtube.com/embed/-qfQw2gisT0"
                        />
                    </>
                </div>

                <div>
                    {/* <h2 className="heading-2">Explore through {stats.totalProject} projects all over Nepal</h2> */}
                    <h2 className="heading-2">{exploreLang}</h2>
                    <div className="map-container">
                        <div className="map-wrapper">
                            <ProjectMap isHomePage />
                        </div>
                    </div>
                </div>
            </div>
        </ParallaxProvider>
    );
};

export default HomePage;
