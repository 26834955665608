import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Grid } from 'semantic-ui-react';
import { get } from 'lodash';

import { auth } from '../../../helpers';

import { localization } from '../../../localization';
import { getFileFromCloud } from '../../../actions/index';

const ProjectJustificationSection = ({ gate }) => {
    const language = useSelector(auth.getSelectedLanguage);
    const lang = localization[language]['justification_view'];
    const projectInfo = useSelector((state) => state.project.projectInfo);
    const [projectData, setProjectData] = useState();
    const [isDownloading, setIsDownloading] = useState({});

    const downloadUI = (fileName) => {
        return (
            <label className="btn-outline btn-outline--blue">
                {isDownloading[fileName] ? (
                    <i className="hourglass outline icon loading"></i>
                ) : (
                    <i className="material-icons md-12 mr-2 icon-image-preview">cloud_download</i>
                )}
                (Download)
            </label>
        );
    };

    useEffect(() => {
        setProjectData(projectInfo[gate]);
    }, [gate, projectInfo]);

    const downloadFile = async (fileName) => {
        setIsDownloading({ ...isDownloading, [fileName]: true });
        await getFileFromCloud(projectData.gate_id, fileName);
        setIsDownloading({ ...isDownloading, [fileName]: false });
    };

    const justificationTypes = [
        { key: 'extended', text: 'Extended', value: 'extended' },
        { key: 'merged', text: 'Merged', value: 'merged' },
        { key: 'phased_out', text: 'Phased Out', value: 'phased_out' },
    ];

    const getFormattedValue = (filteringArray, value) => {
        let filteredVal = filteringArray.filter((type) => value === type.value);
        if (filteredVal.length > 0) {
            return filteredVal[0].text;
        }
    };

    return (
        <div name="project-justification">
            <div className="project-section" id="project-justification">
                <div className="header medium">{lang.section_name}</div>
                <Tab.Pane>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <p>{lang.justification} :</p>
                            </Grid.Column>
                            <Grid.Column>
                                <p className="project-info">
                                    {getFormattedValue(
                                        justificationTypes,
                                        get(projectData, 'justification.justification')
                                    )}
                                </p>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={5}>
                                <p>{lang.supporting_document} :</p>
                            </Grid.Column>
                            <Grid.Column>
                                <div className="project-info file-download">
                                    {projectData &&
                                        projectData.justification &&
                                        projectData.justification.justificationFile.map(
                                            (item, index) => {
                                                return (
                                                    Object.keys(item).length !== 0 &&
                                                    !item.isDeleted && (
                                                        <p key={index}>
                                                            {item.originalName
                                                                ? item.originalName
                                                                : item.fileName}
                                                            {item.fileName.length > 0 && (
                                                                <>
                                                                    {item.fileSize ? (
                                                                        <span>
                                                                            ({item.fileSize})
                                                                        </span>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    <button
                                                                        className="btn-download"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            downloadFile(
                                                                                item.cloudFileName
                                                                            )
                                                                        }
                                                                    >
                                                                        {downloadUI(
                                                                            item.cloudFileName
                                                                        )}
                                                                    </button>
                                                                </>
                                                            )}
                                                        </p>
                                                    )
                                                );
                                            }
                                        )}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={5}>
                                <p>{lang.file_description} :</p>
                            </Grid.Column>
                            <Grid.Column>
                                <p className="project-info file-download">
                                    {projectData &&
                                        projectData.justification &&
                                        projectData.justification.justificationFile.map(
                                            (item, index) => {
                                                return (
                                                    Object.keys(item).length !== 0 &&
                                                    !item.isDeleted && (
                                                        <span key={index}>
                                                            {item.fileDescription}
                                                        </span>
                                                    )
                                                );
                                            }
                                        )}
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Tab.Pane>
            </div>
        </div>
    );
};

export default ProjectJustificationSection;
