import Api from '../api';
import ApiUrlConstant from '../../helpers/constant/GateApiURL';

class SectorApi {
    async listAllGateSectors(val) {
        try {
            const res = await Api.get(
                await ApiUrlConstant.GATE_SECTOR_LIST(),
                val.download ? 'arraybuffer' : null,
                val
            );
            return res.body;
        } catch (error) {
            return error;
        }
    }
}

export default new SectorApi();
