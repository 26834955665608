import { ad2bs } from 'ad-bs-converter';
import moment from 'moment';

function fiscalYear() {
    const formattedDate = moment().format('YYYY/MM/DD');

    let fiscalYearVal = '';

    const date = ad2bs(formattedDate);
    const { year } = date.en;
    const nextYear = year + 1;
    const previousYear = year - 1;
    const yearRunningMonth = date.en.month;
    const yearRunningDay = date.en.day;
    const runningFullYear = `${year}-${yearRunningMonth}-${yearRunningDay}`;

    if (runningFullYear > `${year}-3-31`) {
        fiscalYearVal = `${year}/${nextYear}`;
    } else {
        fiscalYearVal = `${previousYear}/${year}`;
    }

    return fiscalYearVal;
}

export default fiscalYear;
