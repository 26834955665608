import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Grid, Divider, Table } from 'semantic-ui-react';
import { get } from 'lodash';

import { auth } from '../../../helpers';

import { localization } from '../../../localization';

const ProjectFinancingSection = ({ gate }) => {
  const language = useSelector(auth.getSelectedLanguage);
  const lang = localization[language]['financing_view'];
  const projectInfo = useSelector((state) => state.project.projectInfo);
  const [projectData, setProjectData] = useState();

  useEffect(() => {
    setProjectData(projectInfo[gate]);
  }, [gate, projectInfo]);

  return (
    <div name="project-financing">
      <div className="project-section" id="project-financing">
        <div className="header medium">
          {projectInfo.project_status === 'new' && gate === 'project_identification'
            ? lang.form_name_alternate
            : lang.section_name}
        </div>
        <Tab.Pane>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.financing_structure}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.gon_share} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_financing_arrangement.governmentNepalShare')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.loan} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_financing_arrangement.loan')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.grant} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_financing_arrangement.grant')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.private_equity} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_financing_arrangement.privateEquity')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.other} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_financing_arrangement.others')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <p>{lang.is_the_financing_for_the_project} :</p>
              </Grid.Column>
              <Grid.Column>
                <p className="project-info">
                  {get(projectData, 'project_financing_arrangement.financingStatus')}
                </p>
              </Grid.Column>
            </Grid.Row>
            {get(projectData, 'project_financing_arrangement.financingStatus') === 'No' ? (
              <>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p>{lang.proposed_plan} :</p>
                  </Grid.Column>
                  <Grid.Column>
                    <p
                      className="project-info"
                      dangerouslySetInnerHTML={{
                        __html: get(projectData, 'project_financing_arrangement.proposedPlan'),
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : (
              ''
            )}

            <Divider />

            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.source_of_funding}</div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="header tiny">{lang.gon}</div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                {get(projectData, 'project_financing_arrangement.governmentProject') ? (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{lang.funding_agency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund_word}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.currency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.usd_exchange_rate}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {projectData &&
                        projectData.project_financing_arrangement.governmentProject.gon &&
                        projectData.project_financing_arrangement.governmentProject.gon.map(
                          (projectItem, index) => {
                            return (
                              Object.keys(projectItem).length > 0 && (
                                <Table.Row key={index}>
                                  <Table.Cell>{projectItem.fundingAgency}</Table.Cell>
                                  <Table.Cell>{projectItem.fund}</Table.Cell>
                                  <Table.Cell>{projectItem.fundInWord}</Table.Cell>
                                  <Table.Cell>
                                    {(projectItem.fund && projectItem.fund !== '') ||
                                    projectItem.fundingAgency !== ''
                                      ? projectItem.fundCurrency &&
                                        projectItem.fundCurrency === 'usd'
                                        ? `${projectItem.fundCurrency.toUpperCase()}`
                                        : 'npr'.toUpperCase()
                                      : ''}
                                  </Table.Cell>
                                  <Table.Cell>{projectItem.fundExchangeRate}</Table.Cell>
                                </Table.Row>
                              )
                            );
                          }
                        )}
                    </Table.Body>
                  </Table>
                ) : (
                  ''
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="header tiny">{lang.internal_loan}</div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                {get(projectData, 'project_financing_arrangement.governmentProject') ? (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{lang.funding_agency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund_word}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.currency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.usd_exchange_rate}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {projectData &&
                        projectData.project_financing_arrangement.governmentProject.internalLoan &&
                        projectData.project_financing_arrangement.governmentProject.internalLoan.map(
                          (projectItem, index) => {
                            return (
                              Object.keys(projectItem).length > 0 && (
                                <Table.Row key={index}>
                                  <Table.Cell>{projectItem.fundingAgency}</Table.Cell>
                                  <Table.Cell>{projectItem.fund}</Table.Cell>
                                  <Table.Cell>{projectItem.fundInWord}</Table.Cell>
                                  <Table.Cell>
                                    {(projectItem.fund && projectItem.fund !== '') ||
                                    projectItem.fundingAgency !== ''
                                      ? projectItem.fundCurrency &&
                                        projectItem.fundCurrency === 'usd'
                                        ? `${projectItem.fundCurrency.toUpperCase()}`
                                        : 'npr'.toUpperCase()
                                      : ''}
                                  </Table.Cell>
                                  <Table.Cell>{projectItem.fundExchangeRate}</Table.Cell>
                                </Table.Row>
                              )
                            );
                          }
                        )}
                    </Table.Body>
                  </Table>
                ) : (
                  ''
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="header tiny">{lang.external_loan}</div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                {get(
                  projectData,
                  'project_financing_arrangement.governmentProject.externalLoan'
                ) ? (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{lang.funding_agency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund_word}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.currency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.usd_exchange_rate}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {projectData &&
                        projectData.project_financing_arrangement.governmentProject.externalLoan &&
                        projectData.project_financing_arrangement.governmentProject.externalLoan.map(
                          (projectItem, index) => {
                            return (
                              Object.keys(projectItem).length > 0 && (
                                <Table.Row key={index}>
                                  <Table.Cell>{projectItem.fundingAgency}</Table.Cell>
                                  <Table.Cell>{projectItem.fund}</Table.Cell>
                                  <Table.Cell>{projectItem.fundInWord}</Table.Cell>
                                  <Table.Cell>
                                    {(projectItem.fund && projectItem.fund !== '') ||
                                    projectItem.fundingAgency !== ''
                                      ? projectItem.fundCurrency &&
                                        projectItem.fundCurrency === 'usd'
                                        ? `${projectItem.fundCurrency.toUpperCase()}`
                                        : 'npr'.toUpperCase()
                                      : ''}
                                  </Table.Cell>
                                  <Table.Cell>{projectItem.fundExchangeRate}</Table.Cell>
                                </Table.Row>
                              )
                            );
                          }
                        )}
                    </Table.Body>
                  </Table>
                ) : (
                  ''
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="header tiny">{lang.external_source}</div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                {get(
                  projectData,
                  'project_financing_arrangement.governmentProject.externalGrant'
                ) ? (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{lang.funding_agency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund_word}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.currency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.usd_exchange_rate}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {projectData &&
                        projectData.project_financing_arrangement.governmentProject.externalGrant &&
                        projectData.project_financing_arrangement.governmentProject.externalGrant.map(
                          (projectItem, index) => {
                            return (
                              Object.keys(projectItem).length > 0 && (
                                <Table.Row key={index}>
                                  <Table.Cell>{projectItem.fundingAgency}</Table.Cell>
                                  <Table.Cell>{projectItem.fund}</Table.Cell>
                                  <Table.Cell>{projectItem.fundInWord}</Table.Cell>
                                  <Table.Cell>
                                    {(projectItem.fund && projectItem.fund !== '') ||
                                    projectItem.fundingAgency !== ''
                                      ? projectItem.fundCurrency &&
                                        projectItem.fundCurrency === 'usd'
                                        ? `${projectItem.fundCurrency.toUpperCase()}`
                                        : 'npr'.toUpperCase()
                                      : ''}
                                  </Table.Cell>
                                  <Table.Cell>{projectItem.fundExchangeRate}</Table.Cell>
                                </Table.Row>
                              )
                            );
                          }
                        )}
                    </Table.Body>
                  </Table>
                ) : (
                  ''
                )}
              </Grid.Column>
            </Grid.Row>

            <Divider />

            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.source_of_ppp_private}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div className="header tiny">{lang.internal_loan_pp}</div>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                {get(projectData, 'project_financing_arrangement.privateProject') ? (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{lang.funding_agency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund_word}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.currency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.usd_exchange_rate}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {projectData &&
                        projectData.project_financing_arrangement.privateProject.internalLoan &&
                        projectData.project_financing_arrangement.privateProject.internalLoan.map(
                          (projectItem, index) => {
                            return (
                              Object.keys(projectItem).length > 0 && (
                                <Table.Row key={index}>
                                  <Table.Cell>{projectItem.fundingAgency}</Table.Cell>
                                  <Table.Cell>{projectItem.fund}</Table.Cell>
                                  <Table.Cell>{projectItem.fundInWord}</Table.Cell>
                                  <Table.Cell>
                                    {(projectItem.fund && projectItem.fund !== '') ||
                                    projectItem.fundingAgency !== ''
                                      ? projectItem.fundCurrency &&
                                        projectItem.fundCurrency === 'usd'
                                        ? `${projectItem.fundCurrency.toUpperCase()}`
                                        : 'npr'.toUpperCase()
                                      : ''}
                                  </Table.Cell>
                                  <Table.Cell>{projectItem.fundExchangeRate}</Table.Cell>
                                </Table.Row>
                              )
                            );
                          }
                        )}
                    </Table.Body>
                  </Table>
                ) : (
                  ''
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.external_loan_pp}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                {get(projectData, 'project_financing_arrangement.privateProject') ? (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{lang.funding_agency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund_word}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.currency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.usd_exchange_rate}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {projectData &&
                        projectData.project_financing_arrangement.privateProject.externalLoan &&
                        projectData.project_financing_arrangement.privateProject.externalLoan.map(
                          (projectItem, index) => {
                            return (
                              Object.keys(projectItem).length > 0 && (
                                <Table.Row key={index}>
                                  <Table.Cell>{projectItem.fundingAgency}</Table.Cell>
                                  <Table.Cell>{projectItem.fund}</Table.Cell>
                                  <Table.Cell>{projectItem.fundInWord}</Table.Cell>
                                  <Table.Cell>
                                    {(projectItem.fund && projectItem.fund !== '') ||
                                    projectItem.fundingAgency !== ''
                                      ? projectItem.fundCurrency &&
                                        projectItem.fundCurrency === 'usd'
                                        ? `${projectItem.fundCurrency.toUpperCase()}`
                                        : 'npr'.toUpperCase()
                                      : ''}
                                  </Table.Cell>
                                  <Table.Cell>{projectItem.fundExchangeRate}</Table.Cell>
                                </Table.Row>
                              )
                            );
                          }
                        )}
                    </Table.Body>
                  </Table>
                ) : (
                  ''
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="header small">{lang.private_equity_foreign}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                {get(projectData, 'project_financing_arrangement.privateProject') ? (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{lang.funding_agency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund_word}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.currency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.usd_exchange_rate}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {projectData &&
                        projectData.project_financing_arrangement.privateProject
                          .foreignPrivateEquity &&
                        projectData.project_financing_arrangement.privateProject.foreignPrivateEquity.map(
                          (projectItem, index) => {
                            return (
                              Object.keys(projectItem).length > 0 && (
                                <Table.Row key={index}>
                                  <Table.Cell>{projectItem.fundingAgency}</Table.Cell>
                                  <Table.Cell>{projectItem.fund}</Table.Cell>
                                  <Table.Cell>{projectItem.fundInWord}</Table.Cell>
                                  <Table.Cell>
                                    {(projectItem.fund && projectItem.fund !== '') ||
                                    projectItem.fundingAgency !== ''
                                      ? projectItem.fundCurrency &&
                                        projectItem.fundCurrency === 'usd'
                                        ? `${projectItem.fundCurrency.toUpperCase()}`
                                        : 'npr'.toUpperCase()
                                      : ''}
                                  </Table.Cell>
                                  <Table.Cell>{projectItem.fundExchangeRate}</Table.Cell>
                                </Table.Row>
                              )
                            );
                          }
                        )}
                    </Table.Body>
                  </Table>
                ) : (
                  ''
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="header tiny">{lang.private_equity_local}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                {get(projectData, 'project_financing_arrangement.privateProject') ? (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{lang.funding_agency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund_word}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.currency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.usd_exchange_rate}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {projectData &&
                        projectData.project_financing_arrangement.privateProject
                          .localPrivateEquity &&
                        projectData.project_financing_arrangement.privateProject.localPrivateEquity.map(
                          (projectItem, index) => {
                            return (
                              Object.keys(projectItem).length > 0 && (
                                <Table.Row key={index}>
                                  <Table.Cell>{projectItem.fundingAgency}</Table.Cell>
                                  <Table.Cell>{projectItem.fund}</Table.Cell>
                                  <Table.Cell>{projectItem.fundInWord}</Table.Cell>
                                  <Table.Cell>
                                    {(projectItem.fund && projectItem.fund !== '') ||
                                    projectItem.fundingAgency !== ''
                                      ? projectItem.fundCurrency &&
                                        projectItem.fundCurrency === 'usd'
                                        ? `${projectItem.fundCurrency.toUpperCase()}`
                                        : 'npr'.toUpperCase()
                                      : ''}
                                  </Table.Cell>
                                  <Table.Cell>{projectItem.fundExchangeRate}</Table.Cell>
                                </Table.Row>
                              )
                            );
                          }
                        )}
                    </Table.Body>
                  </Table>
                ) : (
                  ''
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="header tiny">{lang.gon}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                {get(projectData, 'project_financing_arrangement.privateProject') ? (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{lang.funding_agency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund_word}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.currency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.usd_exchange_rate}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {projectData &&
                        projectData.project_financing_arrangement.privateProject.gon &&
                        projectData.project_financing_arrangement.privateProject.gon.map(
                          (projectItem, index) => {
                            return (
                              Object.keys(projectItem).length > 0 && (
                                <Table.Row key={index}>
                                  <Table.Cell>{projectItem.fundingAgency}</Table.Cell>
                                  <Table.Cell>{projectItem.fund}</Table.Cell>
                                  <Table.Cell>{projectItem.fundInWord}</Table.Cell>
                                  <Table.Cell>
                                    {(projectItem.fund && projectItem.fund !== '') ||
                                    projectItem.fundingAgency !== ''
                                      ? projectItem.fundCurrency &&
                                        projectItem.fundCurrency === 'usd'
                                        ? `${projectItem.fundCurrency.toUpperCase()}`
                                        : 'npr'.toUpperCase()
                                      : ''}
                                  </Table.Cell>
                                  <Table.Cell>{projectItem.fundExchangeRate}</Table.Cell>
                                </Table.Row>
                              )
                            );
                          }
                        )}
                    </Table.Body>
                  </Table>
                ) : (
                  ''
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <div className="header tiny">{lang.others}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                {get(projectData, 'project_financing_arrangement.privateProject') ? (
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{lang.funding_agency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.fund_word}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.currency}</Table.HeaderCell>
                        <Table.HeaderCell>{lang.usd_exchange_rate}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {projectData &&
                        projectData.project_financing_arrangement.privateProject.others &&
                        projectData.project_financing_arrangement.privateProject.others.map(
                          (projectItem, index) => {
                            return (
                              Object.keys(projectItem).length > 0 && (
                                <Table.Row key={index}>
                                  <Table.Cell>{projectItem.fundingAgency}</Table.Cell>
                                  <Table.Cell>{projectItem.fund}</Table.Cell>
                                  <Table.Cell>{projectItem.fundInWord}</Table.Cell>
                                  <Table.Cell>
                                    {(projectItem.fund && projectItem.fund !== '') ||
                                    projectItem.fundingAgency !== ''
                                      ? projectItem.fundCurrency &&
                                        projectItem.fundCurrency === 'usd'
                                        ? `${projectItem.fundCurrency.toUpperCase()}`
                                        : 'npr'.toUpperCase()
                                      : ''}
                                  </Table.Cell>
                                  <Table.Cell>{projectItem.fundExchangeRate}</Table.Cell>
                                </Table.Row>
                              )
                            );
                          }
                        )}
                    </Table.Body>
                  </Table>
                ) : (
                  ''
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      </div>
    </div>
  );
};

export default ProjectFinancingSection;
