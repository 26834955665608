export function fetchingUserManual() {
    return { type: 'USER_MANUAL_FETCHING' };
}

export function fetchUserManual(payload) {
    return { type: 'USER_MANUAL_FETCHED', payload };
}

export function errorUserManual(payload) {
    return { type: 'USER_MANUAL_ERROR', payload };
}

export function fetchGuideline(payload) {
    return { type: 'GUIDELINE_FETCHED', payload };
}
