import React from 'react';
// import { Container, Grid } from 'semantic-ui-react';
import ProgramList from './ProgramList';

function Program({ history }) {
    return (
        <>
            <div className="body-container">
                <ProgramList history={history} />
            </div>
        </>
    );
}

export default Program;
