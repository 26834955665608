const initialState = {
    statsFetching: true,
    statsFetched: false,
    priorityFetching: true,
    priorityFetched: false,
    stats: [],
    projectPriority: [],
    priorityLoading: false,
    sectorLoading: false,
    ministryLoading: false,
    statsLoading: false,
    yearOnYearLoading: false,
    statusLoading: false,
};

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case 'STATS_FETCHING':
            return {
                ...state,
                statsFetching: true,
                statsFetched: false,
            };

        case 'STATS_FETCHED':
            return {
                ...state,
                stats: payload,
                statsFetching: false,
                statsFetched: true,
            };

        case 'PRIORITY_FETCHING':
            return {
                ...state,
                priorityFetching: true,
                priorityFetched: false,
            };

        case 'PRIORITY_FETCHED':
            return {
                ...state,
                projectPriority: payload,
                priorityFetching: false,
                priorityFetched: true,
            };

        case 'PRIORITY_LOADING':
            return {
                ...state,
                priorityLoading: !state.priorityLoading,
            };

        case 'SECTOR_LOADING':
            return {
                ...state,
                sectorLoading: !state.sectorLoading,
            };

        case 'MINISTRY_LOADING':
            return {
                ...state,
                ministryLoading: !state.ministryLoading,
            };

        case 'STATS_LOADING':
            return {
                ...state,
                statsLoading: !state.statsLoading,
            };

        case 'YEAR_ON_YEAR_LOADING':
            return {
                ...state,
                yearOnYearLoading: !state.yearOnYearLoading,
            };

        case 'STATUS_LOADING':
            return {
                ...state,
                statusLoading: !state.statusLoading,
            };

        default:
            break;
    }
    return state;
};

export default reducer;
