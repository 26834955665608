import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import L from 'leaflet';
import L1 from 'leaflet.markercluster';
import markerIcon from '../assets/images/marker.svg';

L.Mask = L.Polygon.extend({
    options: {
        stroke: true,
        color: '#7ca7f8',
        width: 1,
        opacity: 0.2,
        fillColor: '#2a2f38',
        fillOpacity: 0.3,
        clickable: true,
        outerBounds: new L.LatLngBounds([-90, -360], [90, 360]),
    },

    initialize(latLngs, options) {
        const outerBoundsLatLngs = [
            this.options.outerBounds.getSouthWest(),
            this.options.outerBounds.getNorthWest(),
            this.options.outerBounds.getNorthEast(),
            this.options.outerBounds.getSouthEast(),
        ];

        L.Polygon.prototype.initialize.call(this, [outerBoundsLatLngs, latLngs], options);
    },
});

L.mask = function (latLngs, options) {
    return new L.Mask(latLngs, options);
};

const ProjectMap = ({ isHomePage }) => {
    const [nepal, setNepal] = useState({});
    const [provinces, setProvinces] = useState({});
    const [districts, setDistricts] = useState({});
    const mapRef = useRef(null);
    const layerRef = useRef(null);
    const markerRef = useRef(null);
    const list = useSelector((state) => state.project.mapProjectList);

    useEffect(() => {
        mapRef.current = L.map('map', {
            center: [28.3949, 84.124],
            zoom: 7,
            // minZoom:7,
            attributionControl: true,
            trackResize: false,
            scrollWheelZoom: false,
            tileSize: 512,
            layers: [
                L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
                    attribution:
                        '&copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                }),
            ],
        });
    }, []);

    useEffect(() => {
        fetch('/data/Nepal.json')
            .then((response) => response.json())
            .then((data) => setNepal(data));
    }, []);

    useEffect(() => {
        fetch('/data/NewNepalProvince.json')
            .then((response) => response.json())
            .then((data) => setProvinces(data));
    }, []);

    useEffect(() => {
        fetch('/data/NewNepalDistrict.json')
            .then((response) => response.json())
            .then((data) => setDistricts(data));
    }, []);

    useEffect(() => {
        if (
            Object.keys(nepal).length &&
            Object.keys(provinces).length &&
            Object.keys(districts).length
        )
            mapPlot(list);
    }, [list, nepal, provinces, districts]);

    const mapPlot = (data) => {
        // const filteredData = data.filter(
        //     (mapData) => mapData.locationInfo.province === process.env.REACT_APP_PROVINCE_NO
        // );
        const myIcon = L.icon({
            iconUrl: markerIcon,
            iconAnchor: [11, 41],
            popupAnchor: [0, -41],
        });
        const latLngs = [];
        const coordinates = nepal.features[0].geometry.coordinates[0][0];

        for (let i = 0; i < coordinates.length; i++) {
            latLngs.push(new L.LatLng(coordinates[i][1], coordinates[i][0]));
        }

        if (layerRef.current === null && latLngs.length) {
            // L.mask(latLngs).addTo(mapRef.current);
            layerRef.current = L.mask(latLngs).addTo(mapRef.current);

            const options = {
                style: {
                    color: '#2564de',
                    weight: 2,
                    opacity: 0.1,
                    fillColor: 'transparent',
                },
            };

            layerRef.current = L.geoJSON(nepal.features, options).addTo(mapRef.current);
            layerRef.current.addData(districts);
            layerRef.current = L.geoJSON(provinces.features, {
                style: (feature) => {
                    return {
                        ...options.style,
                        opacity: 0.3,
                        fillColor: getProvinceColor(feature.properties.Province),
                    };
                },
                onEachFeature: zoomToProvince,
            }).addTo(mapRef.current);
        }
        if (markerRef.current) {
            markerRef.current.clearLayers();
        }
        markerRef.current = new L1.MarkerClusterGroup();
        const provinceMap = [];
        data.map((state) =>
            provinceMap.push(
                markerRef.current.addLayer(
                    L.marker(state.coord, { icon: myIcon }).bindPopup(
                        `<div class="map-popup">
              <div class="title">
                ${
                    isHomePage
                        ? `${state.project_name_in_english}`
                        : `<a href="/project/${state.id}"> ${state.project_name_in_english}</a>`
                }
              </div>
              <div class="location-info">
                <label class="info-label">Ministry: </label>
                <label class="info-text">${state.ministry.name}</label>
              </div>
              ${
                  Object.keys(state.locationInfo).length > 0 &&
                  Object.keys(state.locationInfo).includes('municipality')
                      ? `<div class="location-info"><label class="info-label">Municipality: </label><label class="info-text">${state.locationInfo.municipality}</label></div>`
                      : ''
              }
               <div class="location-info">
                <label class="info-label">District: </label>
                <label class="info-text">${state.locationInfo.district}</label>
              </div>
              <div class="location-info">
                <label class="info-label">State: </label>
                <label class="info-text">${state.locationInfo.province}</label>
              </div>
             
            </div>`
                    )
                )
            )
        );

        const layerGroup = L.layerGroup(provinceMap);
        layerGroup.addTo(mapRef.current);

        mapRef.current.addLayer(markerRef.current);
    };

    const getProvinceColor = (province) => {
        switch (province.trim()) {
            case '1':
                return 'red';
            case '2':
                return 'green';
            case 'Bagmati':
                return 'blue';
            case 'Gandaki':
                return 'purple';
            case '5':
                return 'pink';
            case 'Karnali':
                return 'yellow';
            case 'Sudur Pashchim':
                return 'black';
            default:
                return 'skyblue';
        }
    };

    const zoomToProvince = (layer) => {
        if (+layer.properties.STATE_CODE === +process.env.REACT_APP_PROVINCE_NO) {
            const polygon = L.polygon(layer.geometry.coordinates);
            const bounds = polygon.getBounds();
            const cSouthWest = L.latLng(bounds.getSouthWest().lng, bounds.getSouthWest().lat);
            const cNortEast = L.latLng(bounds.getNorthEast().lng, bounds.getNorthEast().lat);
            const flippedBounds = L.latLngBounds(cSouthWest, cNortEast);
            mapRef.current.fitBounds(flippedBounds);
        }
    };

    return (
        <>
            <div id="map" />
        </>
    );
};

export default ProjectMap;
