/* eslint-disable react/jsx-indent */
import React from 'react';
import { Provider } from 'react-redux';

import Routes from './route/routes';
import store from './store';
import './assets/scss/app.scss';

function App() {
    return (
        <>
            <Provider store={store}>
                <Routes />
            </Provider>
        </>
    );
}
export default App;
