import moment from 'moment';
import { cloneDeep } from 'lodash';
import { toastr } from 'react-redux-toastr';
import * as ProjectAction from '../reducers/form/action';
import ProjectApi from '../services/Gate/Project';
import DateConvert from '../components/DateConverter/DateConverter';
// import Project from '../services/Gate/Project';

export const handleInputChange = (data, fetched) => (dispatch) => {
    data.event.persist();
    data.field
        ? dispatch(
              fetched({
                  ...data.info,
                  [data.field]: {
                      ...data.info[data.field],
                      [data.event.target.name]: data.event.target.value,
                  },
              })
          )
        : dispatch(
              fetched({
                  ...data.info,
                  [data.event.target.name]: data.event.target.value,
              })
          );
};

export const handleSelectChange = (data, fetched) => (dispatch) => {
    if (data.index >= 0) {
        const repeatSelect = data.subField
            ? cloneDeep(data.info[data.field][data.subField])
            : cloneDeep(data.info[data.field]);

        const inputId = data.name;
        repeatSelect[data.index][inputId] = data.value.trim().length > 0 ? data.value : '';
        if (data.currencyField) {
            const { costName, exchangeRateName, costInWordsName } = data.currencyField;
            repeatSelect[data.index][costName] = '';
            repeatSelect[data.index][exchangeRateName] = '';
            repeatSelect[data.index][costInWordsName] = '';
        }
        data.subField
            ? dispatch(
                  fetched({
                      ...data.info,
                      [data.field]: { ...data.info[data.field], [data.subField]: repeatSelect },
                  })
              )
            : dispatch(
                  fetched({
                      ...data.info,
                      [data.field]: repeatSelect,
                  })
              );
    } else {
        const selectData = data.field ? cloneDeep(data.info[data.field]) : cloneDeep(data.info);
        selectData[data.name] = data.value;
        if (data.currencyField) {
            const { costName, exchangeRateName, costInWordsName } = data.currencyField;
            selectData[costName] = '';
            selectData[exchangeRateName] = '';
            selectData[costInWordsName] = '';
        }
        data.field
            ? dispatch(
                  fetched({
                      ...data.info,
                      [data.field]: selectData,
                  })
              )
            : dispatch(
                  fetched({
                      ...selectData,
                  })
              );
    }
};

export const handleCheckboxChange = (data, fetched) => (dispatch) => {
    const checkItem = data.info[data.field].filter((item) => item === data.event.target.name);
    if (checkItem.length > 0) {
        const unCheckedIndex = data.info[data.field].indexOf(checkItem[0]);
        const newCheckBox = [];
        data.info[data.field].forEach((item, index) => {
            if (index !== unCheckedIndex && item) {
                newCheckBox.push(item);
            }
        });
        dispatch(
            fetched({
                ...data.info,
                [data.field]: [...newCheckBox],
            })
        );
    } else {
        data.event.target.name &&
            dispatch(
                fetched({
                    ...data.info,
                    [data.field]: [...data.info[data.field], data.event.target.name],
                })
            );
    }
};

export const handleRadioChange = (data, fetched) => (dispatch) => {
    const { info, value, name } = data;
    if (name === 'is_sub_project' && value === 'No' && info.projectOnBoarding.parent_id) {
        info.projectOnBoarding.parent_id = null;
    }
    if (name === 'isMasterPlan' && value === 'No' && info.isMasterPlanApproved === 'Yes') {
        info.isMasterPlanApproved = 'No';
    }

    const adDate = name.split('BS');
    if (adDate.length > 1 && name !== 'projectCostImplementation') {
        const dateInAD = `${adDate[0]}AD`;
        info[dateInAD] = null;
    }

    data.field
        ? dispatch(
              fetched({
                  ...data.info,
                  [data.field]: {
                      ...data.info[data.field],
                      [data.name]: data.value,
                  },
              })
          )
        : dispatch(
              fetched({
                  ...data.info,
                  [data.name]: data.value,
              })
          );
};

export const handleEditorChange = (data, fetched) => (dispatch) => {
    data.field
        ? dispatch(
              fetched({
                  ...data.formInfo,
                  [data.field]: {
                      ...data.formInfo[data.field],
                      [data.name]: data.fieldData.trim().length > 0 ? data.fieldData : '',
                  },
              })
          )
        : dispatch(
              fetched({
                  ...data.formInfo,
                  [data.name]: data.fieldData.trim().length > 0 ? data.fieldData : '',
              })
          );
};

export const handleSaveAndExit = (data, type, dispatch, step, params) => {
    const dataToDb = formattedDataToDb(data);
    dispatch(ProjectAction.projectSaveToDbLoader(true));
    ProjectApi.updateProject(dataToDb, params)
        .then((response) => {
            if (response.success) {
                if (
                    data[Object.keys(data)[0]].deletedFilesName &&
                    data[Object.keys(data)[0]].deletedFilesName.length > 0
                ) {
                    removeFileFromCloud(data, params.projectId);
                }
                if (
                    data[Object.keys(data)[0]].uploadedFile &&
                    data[Object.keys(data)[0]].uploadedFile.length > 0
                ) {
                    const cloudData = handleFileToCloud(data, params.projectId);
                    cloudData
                        .then(async (cData) => {
                            await saveToDB(cData, params);
                            dispatch(ProjectAction.success(response));
                            toastr.success('', `${response.message}`);
                            setTimeout(() => {
                                if (step === 14 && data.moderation_status)
                                    dispatch(ProjectAction.stageUpdate(data));
                                if (type === 'continue') {
                                    dispatch(ProjectAction.projectStep({ step }));
                                    dispatch(ProjectAction.projectSaveToDbLoader(false));
                                } else window.location = '/dashboard';
                            }, 2000);
                        })
                        .catch((err) => {
                            saveToDB(data, params, true);
                            dispatch(ProjectAction.projectSaveToDbLoader(false));
                            dispatch(ProjectAction.error(err));
                            toastr.error('', `${err}`);
                        });
                } else {
                    dispatch(ProjectAction.success(response));
                    toastr.success('', `${response.message}`);
                    setTimeout(() => {
                        if (step === 14 && data.moderation_status)
                            dispatch(ProjectAction.stageUpdate(data));
                        if (type === 'continue') {
                            dispatch(ProjectAction.projectStep({ step }));
                            dispatch(ProjectAction.projectSaveToDbLoader(false));
                        } else window.location = '/dashboard';
                    }, 2000);
                }
            } else {
                dispatch(ProjectAction.projectSaveToDbLoader(false));
                dispatch(ProjectAction.error(response));
                toastr.error('', `${response.error.message}`);
            }
        })
        .catch((err) => {
            dispatch(ProjectAction.projectSaveToDbLoader(false));
            dispatch(ProjectAction.error(err));
        });
};

const formatDate = (convertedDate) => {
    let storingDate;
    storingDate = `${convertedDate.month}-${convertedDate.day}-${convertedDate.year}`;
    if (convertedDate.day < 10 && convertedDate.month < 10) {
        storingDate = `${convertedDate.year}-0${convertedDate.month}-0${convertedDate.day}`;
    } else if (convertedDate.month < 10) {
        storingDate = `${convertedDate.year}-0${convertedDate.month}-${convertedDate.day}`;
    } else if (convertedDate.day < 10) {
        storingDate = `${convertedDate.year}-${convertedDate.month}-0${convertedDate.day}`;
    }
    return storingDate;
};

export const handleNepaliDateChange = (data, fetched) => (dispatch) => {
    const convertedDate = DateConvert({ nepaliDate: data.selectedNepaliDate });
    const storingDate = new Date(formatDate(convertedDate));

    data.field
        ? dispatch(
              fetched({
                  ...data.info,
                  [data.field]: {
                      ...data.info[data.field],
                      [data.name]: data.value,
                      [data.changeField]: storingDate,
                  },
              })
          )
        : dispatch(
              fetched({
                  ...data.info,
                  [data.name]: data.value,
                  [data.changeField]: storingDate,
              })
          );
};

export const handleEnglishDateChange = (data, fetched) => (dispatch) => {
    const convertedDate =
        moment(data.value, 'YYYY-MM-DD', true).isValid() &&
        moment(data.value).isBetween('1922-12-30', '2035-12-30') &&
        DateConvert({ englishDate: data.value }).ne;
    const storingDate = convertedDate
        ? `${convertedDate.strShortDayOfWeek}, ${convertedDate.strMonth} ${convertedDate.day}, ${convertedDate.year}`
        : '';

    if (data.index >= 0) {
        const repeatDate = data.field ? cloneDeep(data.info[data.field]) : cloneDeep(data.info);
        const inputId = data.name;
        repeatDate[data.index][inputId] = data.value;
        data.field
            ? dispatch(
                  fetched({
                      ...data.info,
                      [data.field]: repeatDate,
                  })
              )
            : dispatch(
                  fetched({
                      ...data.info,
                      [data.name]: repeatDate,
                  })
              );
    } else {
        data.field
            ? dispatch(
                  fetched({
                      ...data.info,
                      [data.field]: {
                          ...data.info[data.field],
                          [data.name]: data.value,
                          [data.changeField]: storingDate,
                      },
                  })
              )
            : data.changeField
            ? dispatch(
                  fetched({
                      ...data.info,
                      [data.name]: data.value,
                      [data.changeField]: storingDate,
                  })
              )
            : dispatch(
                  fetched({
                      ...data.info,
                      [data.name]: data.value,
                  })
              );
    }
};

export const addRepeaterField = (data, fetched) => (dispatch) => {
    data.event.preventDefault();
    const repeatFieldClone = data.subField
        ? cloneDeep(data.info[data.field][data.subField])
        : cloneDeep(data.info[data.field]);
    const fieldType = repeatFieldClone.concat([{}]);

    data.subField
        ? dispatch(
              fetched({
                  ...data.info,
                  [data.field]: { ...data.info[data.field], [data.subField]: fieldType },
              })
          )
        : dispatch(
              fetched({
                  ...data.info,
                  [data.field]: fieldType,
              })
          );
};

export const handleRepeaterChange = (data, fetched) => (dispatch) => {
    data.event.preventDefault();
    const repeatFieldClone = data.subField
        ? cloneDeep(data.info[data.field][data.subField])
        : data.mainField
        ? cloneDeep(data.info[data.mainField][data.field])
        : cloneDeep(data.info[data.field]);

    const inputId = data.event.target.name;
    repeatFieldClone[data.index][inputId] =
        data.event.target.value.trim().length > 0 ? data.event.target.value : '';
    data.subField
        ? dispatch(
              fetched({
                  ...data.info,
                  [data.field]: { ...data.info[data.field], [data.subField]: repeatFieldClone },
              })
          )
        : data.mainField
        ? dispatch(
              fetched({
                  ...data.info,
                  [data.mainField]: {
                      ...data.info[data.mainField],
                      [data.field]: repeatFieldClone,
                  },
              })
          )
        : dispatch(
              fetched({
                  ...data.info,
                  [data.field]: repeatFieldClone,
              })
          );
};

export const removeRepeaterField = (data, fetched) => (dispatch) => {
    data.event.preventDefault();
    const removeFieldClone = data.subField
        ? cloneDeep(data.info[data.field][data.subField])
        : cloneDeep(data.info[data.field]);
    removeFieldClone.splice(data.index, 1);
    data.subField
        ? dispatch(
              fetched({
                  ...data.info,
                  [data.field]: { ...data.info[data.field], [data.subField]: removeFieldClone },
              })
          )
        : dispatch(
              fetched({
                  ...data.info,
                  [data.field]: removeFieldClone,
              })
          );
};

const handleImageLoading = (value, label, index, dispatch) => {
    dispatch(ProjectAction.imageLoading({ value, label, index }));
    dispatch(ProjectAction.disableImageButton({ disabled: value }));
};

export const handleFileUploadPrev = (data, fetched) => (dispatch) => {
    try {
        const { projectId, field, name, e, info, index } = data;
        if (projectId) {
            const formData = new FormData();
            if (e.target.files[0]) {
                formData.append('image', e.target.files[0]);
                handleImageLoading(true, name, index, dispatch);
                ProjectApi.uploadFile(formData, { projectId })
                    .then((response) => {
                        handleImageLoading(false, name, index, dispatch);
                        if (response.success) {
                            const fieldData = field
                                ? cloneDeep(info[field][name])
                                : cloneDeep(info[name]);

                            if (fieldData[0].fileName === '' && index === 0) {
                                fieldData[index].cloudFileName = response.data.cloudFileName;
                                fieldData[index].fileName = response.data.fileName;
                                fieldData[index].originalName = response.data.originalname;
                                fieldData[index].fileSize = formatBytes(response.data.size);
                            } else
                                fieldData.push({
                                    cloudFileName: response.data.cloudFileName,
                                    fileName: response.data.fileName,
                                    fileDescription: '',
                                    originalName: response.data.originalname,
                                    fileSize: formatBytes(response.data.size),
                                });

                            field
                                ? dispatch(
                                      fetched({
                                          ...info,
                                          [field]: {
                                              ...info[field],
                                              [name]: [...fieldData],
                                          },
                                      })
                                  )
                                : dispatch(
                                      fetched({
                                          ...info,
                                          [name]: [...fieldData],
                                      })
                                  );
                        } else {
                            dispatch(ProjectAction.error(response));
                            toastr.error('', `${response.error.message}`);
                        }
                    })
                    .catch((err) => {
                        handleImageLoading(false, name, index, dispatch);
                        dispatch(ProjectAction.error(err));
                        toastr.error('', `${err}`);
                    });
            }
        }
    } catch (err) {
        dispatch(ProjectAction.error(err));
    }
};

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const handleRemoveFileUpload = (data, fetched) => (dispatch) => {
    try {
        const { projectId, field, name, info, index } = data;
        if (projectId) {
            const fieldData = field ? cloneDeep(info[field][name]) : cloneDeep(info[name]);
            // const { fileName } = fieldData[index];
            const fileName = fieldData[index].cloudFileName;
            if (info.uploadedFile && info.uploadedFile.length > 0) {
                for (let i = 0; i < info.uploadedFile.length; i++) {
                    if (
                        info.uploadedFile[i].fileName === fieldData[index].fileName &&
                        info.uploadedFile[i].index === index
                    ) {
                        info.uploadedFile.splice(i, 1);
                    }
                }
            }
            // fieldData.splice(index, 1);
            if (!fieldData[index].cloudFileName) {
                fieldData.splice(index, 1);
            } else {
                fieldData[index].isDeleted = true;
            }
            info.deletedFilesName =
                info.deletedFilesName && info.deletedFilesName.length > 0
                    ? [...info.deletedFilesName, { key: field || name, index, fileName }]
                    : [{ key: field || name, fileName, index }];
            if (fieldData.length === 0) {
                fieldData.push({ fileName: '', fileDescription: '' });
            }

            if (info.uploadedFile && info.uploadedFile.length > 0) {
                for (let i = 0; i < info.uploadedFile.length; i++) {
                    if (info.uploadedFile[i].index > index) {
                        fieldData.forEach((newFile, indexOfFieldData) => {
                            if (newFile.fileName === info.uploadedFile[i].fileName) {
                                info.uploadedFile[i].index = indexOfFieldData;
                            }
                        });
                    }
                }
            }

            field
                ? // selectedFieldState[field][index].fileName = response.data.filename;
                  dispatch(
                      fetched({
                          ...info,
                          [field]: {
                              ...info[field],
                              [name]: [...fieldData],
                          },
                      })
                  )
                : dispatch(
                      fetched({
                          ...info,
                          [name]: [...fieldData],
                      })
                  );

            // ProjectApi.deleteUploadedFile({ projectId }, { fileName: fileName })
            //     .then((response) => {
            //         fieldData.splice(index, 1);
            //         if (fieldData.length === 0) {
            //             fieldData.push({ fileName: '', fileDescription: '' });
            //         }
            //         field
            //             ? // selectedFieldState[field][index].fileName = response.data.filename;
            //               dispatch(
            //                   fetched({
            //                       ...info,
            //                       [field]: {
            //                           ...info[field],
            //                           [name]: [...fieldData],
            //                       },
            //                   })
            //               )
            //             : dispatch(
            //                   fetched({
            //                       ...info,
            //                       [name]: [...fieldData],
            //                   })
            //               );
            //     })
            //     .catch((err) => {
            //         toastr.error('', `${err.message}`);
            //     });
        }
    } catch (err) {
        dispatch(ProjectAction.error(err));
    }
};

export const repeaterFieldOptimise = (data, mainField, emptyObject, subField) => {
    const filteringData = subField ? data[mainField][subField] : data[mainField];
    if (filteringData.length > 1) {
        let newSubField = filteringData.filter((item) => {
            const testForFilter = Object.keys(item).filter(
                (key) => item[key] || (item[key] && item[key].length > 0)
            );
            if (testForFilter.length > 0 && !testForFilter[0].includes('Currency')) {
                return testForFilter.length > 0 && Object.keys(item).length > 0;
            }
        });
        if (newSubField.length === 0) {
            newSubField = [...emptyObject];
        }
        return newSubField;
    }
    return filteringData;
};

export const getFileFromCloud = async (id, fileName) => {
    const data = await ProjectApi.getFileFromCloud({ projectId: id, fileName });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('type', 'hidden');
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const removeFileFromCloud = async (data, projectId) => {
    try {
        if (projectId) {
            const formName = Object.keys(data)[0];
            const fileInfo = data[formName].deletedFilesName;
            await ProjectApi.deleteUploadedFile({ projectId }, { fileInfo });
        }
    } catch (err) {
        toastr.error(
            '',
            `${err.message ? err.message : 'Something went wrong while deleting files from cloud'}`
        );
    }
};

const formattedDataToDb = (data) => {
    const updatedData = cloneDeep(data);
    const formName = Object.keys(updatedData)[0];

    if (updatedData[formName].deletedFilesName) {
        updatedData[formName].deletedFilesName.forEach((file) => {
            if (updatedData[formName][file.key].length === 1) {
                if (updatedData[formName][file.key][0].isDeleted) {
                    updatedData[formName][file.key][0].fileName = '';
                    updatedData[formName][file.key][0].originalName = '';
                    updatedData[formName][file.key][0].fileSize = '';
                    updatedData[formName][file.key][0].cloudFileName = '';
                    updatedData[formName][file.key][0].isDeleted = false;
                }
            } else {
                // updatedData[formName][file.key].splice(file.index, 1);
            }
        });

        delete updatedData[formName].deletedFilesName;
    }

    if (updatedData[formName].uploadedFile) {
        // updatedData[formName]['uploadedFile'].filter((file) => {
        //     updatedData[formName][file.key].splice(file.index, 1);
        // });

        delete updatedData[formName].uploadedFile;
    }
    return updatedData;
};

export const handleFileUpload = (data, fetched) => (dispatch) => {
    try {
        const { projectId, field, name, e, info, index } = data;
        // const newInfo = cloneDeep(info);
        if (projectId) {
            if (e.target.files[0]) {
                const acceptedExtension = [
                    'doc',
                    'docx',
                    'xls',
                    'xlsx',
                    'pdf',
                    'ppt',
                    'pptx',
                    'jpg',
                    'jpeg',
                    'png',
                    'PDF',
                    'DOC',
                    'DOCX',
                    'XLSX',
                    'XLS',
                    'PDF',
                    'PPT',
                    'PPTX',
                    'JPG',
                    'JPEG',
                    'PNG',
                ];
                const fieldData = field ? cloneDeep(info[field][name]) : cloneDeep(info[name]);
                const fileName = e.target.files[0].name;
                const fileFormat = fileName.split('.')[fileName.split('.').length - 1];
                if (acceptedExtension.includes(fileFormat)) {
                    handleImageLoading(true, name, index, dispatch);
                    info.uploadedFile =
                        info.uploadedFile && info.uploadedFile.length > 0
                            ? [
                                  ...info.uploadedFile,
                                  {
                                      key: field || name,
                                      index,
                                      fileName,
                                      fileInfo: e.target.files[0],
                                  },
                              ]
                            : [
                                  {
                                      key: field || name,
                                      fileName,
                                      index,
                                      fileInfo: e.target.files[0],
                                  },
                              ];

                    if (fieldData[0].fileName === '' && index === 0) {
                        // fieldData[index].cloudFileName = response.data.cloudFileName;
                        fieldData[index].fileName = e.target.files[0].name;
                        fieldData[index].originalName = e.target.files[0].name;
                        fieldData[index].fileSize = formatBytes(e.target.files[0].size);
                        fieldData[index].isUploaded = false;
                    } else
                        fieldData.push({
                            // cloudFileName: response.data.cloudFileName,
                            fileName: e.target.files[0].name,
                            fileDescription: '',
                            originalName: e.target.files[0].name,
                            fileSize: formatBytes(e.target.files[0].size),
                            isUploaded: false,
                        });

                    field
                        ? dispatch(
                              fetched({
                                  ...info,
                                  [field]: {
                                      ...info[field],
                                      [name]: [...fieldData],
                                  },
                              })
                          )
                        : dispatch(
                              fetched({
                                  ...info,
                                  [name]: [...fieldData],
                              })
                          );

                    handleImageLoading(false, name, index, dispatch);
                } else {
                    toastr.error(
                        '',
                        'Please upload the file in doc, docx,xls, xlsx,pdf,ppt,pptx,jpg,jpeg,png,PDF,DOC,DOCX,XLSX,XLS,PDF,PPT,PPTX,JPG,JPEG,PNG,'
                    );
                }
            }
        }
    } catch (err) {
        dispatch(ProjectAction.error(err));
    }
};

export const handleFileToCloud = async (data, projectId) => {
    const newData = cloneDeep(data);
    const formName = Object.keys(newData)[0];
    if (projectId && newData[formName].uploadedFile.length > 0) {
        const loopData = newData[formName].uploadedFile;
        for (let i = 0; i < loopData.length; i++) {
            const formData = new FormData();
            formData.append('image', loopData[i].fileInfo);
            if (!loopData[i].isDeleted) {
                const fileUpload = await ProjectApi.uploadFile(formData, { projectId });
                if (fileUpload?.success) {
                    const fieldName = loopData[i].key;
                    const { index } = loopData[i];
                    newData[formName][fieldName][index].cloudFileName =
                        fileUpload.data.cloudFileName;
                    newData[formName][fieldName][index].fileName = fileUpload.data.fileName;
                    newData[formName][fieldName][index].originalName = fileUpload.data.originalname;
                    newData[formName][fieldName][index].fileSize = formatBytes(
                        fileUpload.data.size
                    );
                    newData[formName][fieldName][index].isUploaded = true;
                } else {
                    throw new Error(`${fileUpload.error.message}`);
                }
            } else {
                const fieldName = loopData[i].key;
                // const { index } = loopData[i];
                if (newData[formName][fieldName].length === 1) {
                    newData[formName][fieldName][0].fileName = '';
                    newData[formName][fieldName][0].originalName = '';
                    newData[formName][fieldName][0].fileSize = '';
                } else {
                    // after changing index for deleted files.
                    // newData[formName][fieldName].splice(index, 1);
                }
            }
        }
        return newData;
    }
};

const saveToDB = async (data, params, err = false) => {
    const formName = Object.keys(data)[0];

    if (err) {
        if (data[formName].uploadedFile) {
            data[formName].uploadedFile.forEach((fileInfo) => {
                if (data[formName][fileInfo.key].length !== 1) {
                    if (!data[formName][fileInfo.key][fileInfo.index].isUploaded) {
                        data[formName][fileInfo.key].splice(fileInfo.index, 1);
                    }
                } else {
                    data[formName][fileInfo.key][0].fileName = '';
                    data[formName][fileInfo.key][0].originalName = '';
                    data[formName][fileInfo.key][0].fileSize = '';
                    data[formName][fileInfo.key][0].cloudFileName = '';
                    data[formName][fileInfo.key][0].isDeleted = false;
                    data[formName][fileInfo.key] = [{ fileName: '', fileDescription: '' }];
                }
            });
        }
    }

    if (data[formName].deletedFilesName) {
        data[formName].deletedFilesName.forEach((file) => {
            if (data[formName][file.key].length === 1) {
                if (data[formName][file.key][0].isDeleted) {
                    data[formName][file.key][0].fileName = '';
                    data[formName][file.key][0].originalName = '';
                    data[formName][file.key][0].fileSize = '';
                    data[formName][file.key][0].cloudFileName = '';
                    data[formName][file.key][0].isDeleted = false;
                }
            } else {
                // TODO if storing in db for isDeleted:true also need to be remove
                // data[formName][file.key].splice(file.index, 1);
            }
        });

        delete data[formName].deletedFilesName;
    }

    if (data[formName].uploadedFile) {
        delete data[formName].uploadedFile;
    }

    return await ProjectApi.updateProject(data, params);
};
