import Api from './api';
import ApiUrlConstant from '../helpers/constant/api-url';

class UserService {
    async createUser(data) {
        try {
            const res = await Api.post(await ApiUrlConstant.USER(), data);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async listUsers(query) {
        try {
            const res = await Api.get(await ApiUrlConstant.USER(), null, query);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async getUser(params) {
        try {
            const res = await Api.get(await ApiUrlConstant.USER_ACTION(params));

            return res.body;
        } catch (error) {
            return error;
        }
    }

    async deleteUser(params) {
        try {
            const res = await Api.destroy(await ApiUrlConstant.USER_ACTION(params));
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async updateUser(params, data) {
        try {
            const res = await Api.patch(await ApiUrlConstant.USER_ACTION(params), data);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async updateBulkUsers(data) {
        try {
            const res = await Api.patch(await ApiUrlConstant.BULK_USER_ACTION(), data);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async updatePassword(params, data) {
        try {
            const res = await Api.patch(await ApiUrlConstant.CHANGE_PASSWORD(params), data);
            return res.body;
        } catch (error) {
            return error;
        }
    }

    async listUsersByMinistry(params) {
        try {
            const res = await Api.get(await ApiUrlConstant.LIST_USER_BY_MINISTRY(params));
            return res.body;
        } catch (error) {
            return error;
        }
    }
}

export default new UserService();
