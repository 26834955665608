import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

import Api from './api';

import { call } from '../helpers';

import { GET_ERRORS, SET_CURRENT_USER } from '../helpers/constant/types';

export const login = (userData, onSuccess) => (dispatch) => {
    Api.post('login', userData)
        .then((user) => {
            if (user.body.success) {
                const { token } = user.body.data;
                Cookies.set('npbmisToken', token);
                const object = { token, timestamp: new Date().getTime() };
                localStorage.setItem('npbmisToken', JSON.stringify(object));
                const decoded = jwtDecode(token);
                dispatch(setCurrentUser(decoded));
                call(onSuccess);
            } else {
                throw new Error(JSON.stringify(user.body.error));
            }
        })
        .catch((error) => {
            const { message } = JSON.parse(error.message);
            dispatch({
                type: GET_ERRORS,
                payload: { message, isError: true },
            });
        });
};

export const setLoginError = () => (dispatch) => {
    dispatch({
        type: GET_ERRORS,
        payload: { isError: false },
    });
};

export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded,
    };
};

export const logout = (callback) => async (dispatch) => {
    await localStorage.removeItem('npbmisToken');
    await Cookies.remove('npbmisToken');

    dispatch(setCurrentUser({}));

    callback();
};
